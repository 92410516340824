import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL, USER_ID } from "../../config";
import {
  GET_WISHLIST,
  GET_WISHLIST_FAILURE,
  GET_WISHLIST_SUCCESS,
} from "./ActionTypes";

// Add Fav
export const addFav = (payload, setFav) => async () => {
  try {
    const res = await axios.post(`${BASE_URL}api/wishlist`, payload, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    });
    if (!res.data.error) {
      toast.success(res.data.message);
      setFav();
    }
  } catch (error) {}
};

// Get Fav
export const getWishList = () => async (dispatch) => {
  dispatch({ type: GET_WISHLIST });

  try {
    const res = await axios.get(`${BASE_URL}api/wishlist/user/${USER_ID}`, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    });
    const data = res.data;
    dispatch({
      type: GET_WISHLIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: GET_WISHLIST_FAILURE });
  }
};

// Remove Fav
export const removeFav = (payload) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${BASE_URL}api/wishlist`,
      {
        headers: {
          token: localStorage.getItem("userToken|VST"),
        },
      },
      payload
    );
    if (!res.data.error) {
      toast.success(res.data.message);
    }
    dispatch(getWishList(payload.user));
  } catch (error) {}
};

export const deleteUserWishList = (payload) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${BASE_URL}api/wishlist/user/${payload.course}`,
      {
        headers: {
          token: localStorage.getItem("userToken|VST"),
        },
      },
      payload
    );
    if (!res.data.error) {
      toast.success(res.data.message);
    }
    dispatch(getWishList(payload.user));
  } catch (error) {}
};
