import React, { useState } from "react";
import { TbPhone, TbDeviceMobile } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TfiUser, TfiEmail, TfiLock } from "react-icons/tfi";
import axios from "axios";
import { toast } from "react-hot-toast";
import { UserRegister } from "../Redux/Actions/AuthAction";
import ApiLoader from "../Components/ApiLoader";
const BASEURL = process.env.REACT_APP_BASE_URL;

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.AuthReducer);
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [otpAgain, setOtpAgain] = useState(false);
  const [lockPhone, setLockPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otploading, setOtpLoading] = useState(false);

  const [phoneVerifyProcess, setPhoneVerify] = useState(false);
  const [agree, setAgree] = useState(false);
  // handleChange
  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const checkboxHandler = (event) => {
    setAgree(event.target.checked);
  }

  // handleSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    if ( (!agree) ) {
      toast.error("Please go through the terms and conditions and select the checkbox");
      return;
    }    
    if (
      !formValues.name ||
      !formValues.email ||
      !formValues.phone ||
      !formValues.password

    ) {
      toast.error("Please fill all field");
    }
    else {
      if (phoneVerifyProcess) {
        dispatch(
          UserRegister(formValues, () => {
            setFormValues({});
            navigate("/login");
          })
        );
      } else {
        toast.error("Please verify phone");
      }
    }
  };

  const sendOtpAgain = () => {
    setTimeout(() => {
      setOtpAgain(false);
    }, 30000);
  };

  const phoneVerify = async () => {
    try {
      if (formValues?.phone?.length !== 10) {
        return;
      }
      if (otpAgain) {
        return;
      }
      setLoading(true);
      let url = `${BASEURL}api/auth/phone-verify`;
      await axios.post(url, {
        phone: formValues?.phone,
      });
      toast.success("Please enter otp sent to your mobile");

      setShowOtp(true);
      setOtpAgain(true);
      sendOtpAgain();
      setLoading(false);

    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const submitOtp = async (e) => {
    try {
      if (otp.length === 6) {
        setOtpLoading(true);
        let url = `${BASEURL}api/auth/otp-verify`;
        let response = await axios.post(url, {
          phone: formValues?.phone,
          otp,
        });
        if (response.data.error) {
          setOtpLoading(false);
          toast.error(response.data.message);
          return;
        }
        setLockPhone(true);
        setPhoneVerify(true);
        toast.success(response.data.message);
        setShowOtp(false);
        setOtpLoading(false);
      } else {
        toast.error("enter 6 digit otp");
      }
    } catch (error) {
      setOtpLoading(false);
      toast.error("Something went wrong!");
    }
  };
  return (
    <>
      <div className="flex lg:h-screen lg:flex-row flex-col  bg-white w-full lg:bg-gray-50 lg:gap-5 items-center">
        <figure className=" lg:h-full h-80 md:h-96 w-full  lg:w-1/3 flex ">
          <img
            src="Assets/login-bg.jpg"
            className="h-full object-cover w-full"
            alt="register"
          />
          
        </figure>
        <section className="lg:w-2/3 my-4  w-full lg:p-4 sm:w-4/5 md:w-3/4 mx-auto lg:px-20">
          <div className="lg:p-10 p-5 bg-white rounded lg:shadow">
            {/* <Logo/> */}
            <h3 className="text-lg mb-5 uppercase font-bold">
              Create your student account
            </h3>
            <form onSubmit={handleSubmit}>
              {/* Name */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="name">
                  <TfiUser className="text-gray-500 text-xl" />
                </label>
                <input
                  type="text"
                  name="name"
                  value={formValues?.name}
                  onChange={handleChange}
                  className="outline-none px-3 w-full text-sm"
                  placeholder="Your Name"
                />
              </div>
              {/* Phone */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="phone">
                  <TbPhone className="text-gray-500 text-xl" />
                </label>
                <input
                  type="tel"
                  name="phone"
                  disabled={lockPhone}
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  value={formValues?.phone}
                  onChange={handleChange}
                  maxLength={10}
                  className="outline-none bg-transparent px-3 w-full text-sm"
                  placeholder="Your Mobile"
                />
                {phoneVerifyProcess ? (
                  <div className="text-right text-xs text-green-500  tracking-widest font-semibold  ">
                    Verified
                  </div>
                ) : (
                  formValues?.phone?.length > 9 && (
                    <div
                      className="text-right text-xs  cursor-pointer tracking-widest font-semibold  "
                      onClick={(e) => phoneVerify(e)}
                    >
                      {loading ? <ApiLoader /> : otpAgain ? "Resend" : "Verify"}
                    </div>
                  )
                )}
              </div>

              {/* OTP */}
              {showOtp && (
                <div className="p-2 mb-4 border rounded flex items-center gap-3">
                  <label htmlFor="name">
                    <TbDeviceMobile className="text-gray-500 text-xl" />
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={otp}
                    maxLength={6}
                    disabled={otploading}
                    onChange={(e) => setOtp(e.target.value)}
                    className="outline-none bg-transparent px-3 w-full text-sm"
                    placeholder="One Time Password (OTP)"
                  />
                  {otp && (
                    <div
                      className="text-right cursor-pointer text-xs uppercase tracking-widest font-semibold "
                      onClick={(e) => submitOtp(e)}
                    >
                      {otploading ? <ApiLoader /> : "Submit"}
                    </div>
                  )}
                </div>
              )}

              {/* Email */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="email">
                  <TfiEmail className="text-gray-500 text-xl" />
                </label>
                <input
                  type="email"
                  name="email"
                  value={formValues?.email}
                  onChange={handleChange}
                  className="outline-none px-3 w-full text-sm"
                  placeholder="Your Email Address"
                />
              </div>

              {/* Password */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="password">
                  <TfiLock className="text-gray-500 text-xl" />
                </label>
                <input
                  type="password"
                  name="password"
                  value={formValues?.password}
                  onChange={handleChange}
                  className="outline-none px-3 w-full text-sm"
                  placeholder="Password"
                />
              </div>
              <div>
                <input type="checkbox" id="agree"
                  name="agree"
                  onChange={checkboxHandler}></input>
                <label htmlFor="agree"> I agree to <b><Link to="/terms" className="text-color"><u>Terms and Conditions</u></Link> and <Link to="/privacy-policy" className="text-color"><u>Privacy Policy</u></Link></b> </label>
              </div>
              {/* Submit */}
              <div
                className={` mb-5 border hover:bg-transparent hover:text-color ${data.loading ? "bg-transparent" : "bg-color"
                  }  text-white cursor-pointer border-color text-center hover:text-black rounded flex items-center gap-3`}
              >
                <button
                  type="submit"
                  disabled={data.loading}
                  className="w-full p-2 uppercase cursor-pointer text-center"
                >
                  {data.loading ? <ApiLoader /> : "Register"}
                </button>
              </div>
              {/* Login Text */}
              <div className=" flex items-center gap-1.5">
                <span className="text-gray-500">
                  Already have an account ?{" "}
                </span>
                <Link to="/login" className="text-color  ">
                  Login
                </Link>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default Signup;
