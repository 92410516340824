import React from "react";

const About = () => {
  return (
    <>
      {/* Vision and mission  */}
      <section className="container mx-auto  px-4 py-16 ">
        <main className="grid md:grid-cols-2 grid-cols-1   ">
          <div className="">
            <p className="text-justify md:text-base text-sm tracking-wider text-black">

            <p>
            <br></br>
            <ul>
            We value our investors who are providing capital for the growth of our business. Consistently we are focusing to improve the quality of e-learning and to provide best teaching
            and learning experience. All finacial assistance is being provided by StarDataInsights and our team
            express deep gratitude to StarDataInsights for believing and trusting us and of course, the contrubution
            strengthening our ability to continue innovations to provide a best e-learning platform.
            </ul>
            </p>
            <br></br>
            <p>
            <ol>
            </ol>
            </p>
            <br></br>
            <ul>
            </ul>

            <br></br>

            <ul>
            </ul>
            <br></br>          
            </p>
          </div>
          <figure className="p-6 rounded md:flex  items-center hidden relative">
            <img
              alt="about"
              src="Assets/SDI.jpg"
              className="w-full h-full rounded shadow object-cover"
            />
          </figure>
        </main>
      </section>
      {/* Founders and Co-founder */}
      <div className="space-y-16 container xl:max-w-7xl mx-auto px-4 py-10 lg:px-8">
        {/* Heading */}
        {/* END Heading */}

      </div>
    </>
  );
};

export default About;
