import {
  ADD_CART,
  ADD_CART_FAILURE,
  ADD_CART_SUCCESS,
  DELETE_CART_SUCCESS,
  GET_CART,
  GET_CART_FAILURE,
  GET_CART_SUCCESS
} from "../Actions/ActionTypes";

const initialState = {
  cart: {},
  loading: false,
  itemCount: 0
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get Cart
    case GET_CART:
      return { ...state, loading: true };

    case GET_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload,
        itemCount: action.payload.count,
        loading: true
      };

    case GET_CART_FAILURE:
      return { ...state, loading: false };

    //   Add Cart

    case ADD_CART:
      return { ...state, loading: true };

    case ADD_CART_SUCCESS:
      return { ...state, loading: true, itemCount: action.payload.count };

    case ADD_CART_FAILURE:
      return { ...state, loading: false };

    //   Item Count

    case DELETE_CART_SUCCESS:
      let temp = state.cart.course;
      let da = temp.filter((t) => t._id !== action.payload);
      return {
        ...state,
        cart: { ...state.cart, course: [...da] },
        itemCount: state.itemCount - 1
      };

    // case ITEM_COUT:
    //   return {
    //     ...state,
    //     itemCount: state.itemCount + action.payload
    //   };

    default:
      return state;
  }
};

export default CartReducer;
