import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ isInstruter }) => {
  // let isUserAuth = localStorage.getItem("userToken|VST");
  let isInstructorAuth = localStorage.getItem("instructorToken|VST");

  if (isInstruter) {
    return !isInstructorAuth? (
      <Outlet />
    ) : (
      <Navigate to={"/instructor"} />
    );
  } else {
    return <Outlet />;
  }
};

export default PublicRoute;
