import axios from "axios";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Link} from "react-router-dom";
import ApiLoader from "../../Components/ApiLoader";
import { InstructorRegister } from "../../Redux/Actions/AuthAction";

const BASEURL = process.env.REACT_APP_BASE_URL;

const Register = (props) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.AuthReducer.loading);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otploading, setOtpLoading] = useState(false);

  const [showOtp, setShowOtp] = useState(false);
  const [otpAgain, setOtpAgain] = useState(false);
  const [formInputs, setFormInputs] = useState({});
  const [lockPhone, setLockPhone] = useState(false);
  const [phoneVerifyProcess, setPhoneVerified] = useState(false);
  const[agree,setAgree]=useState(false);
  console.log(phoneVerifyProcess);

  // handleChange
  const handleChange = (e) => {
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  };

  const checkboxHandler = (event) => {
    setAgree(event.target.checked);
  }
  // handleSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    if ( (!agree) ) {
      toast.error("Please go through the terms and conditions and select the checkbox");
      return;
    }
    if (
      !formInputs.name ||
      !formInputs.email ||
      !formInputs.phone ||
      !formInputs.password
    ) {
      toast.error("Please fill all field");
    } else {
      if (phoneVerifyProcess) {
        dispatch(
          InstructorRegister(formInputs, () => {
            setFormInputs({});
            props.handleStep();
          })
        );
      } else {
        toast.error("Please verify phone");
      }
    }
  };

  const sendOtpAgain = () => {
    setTimeout(() => {
      setOtpAgain(false);
    }, 30000);
  };

  const phoneVerify = async () => {
    try {
      if (formInputs?.phone?.length !== 10) {
        return;
      }
      if (otpAgain) {
        return;
      }
      setLoading(true);
      let url = `${BASEURL}api/instructor/phone-verify`;
      await axios.post(url, {
        phone: formInputs?.phone,
      });
      toast.success("Please enter otp sent to your mobile");
      setShowOtp(true);
      setOtpAgain(true);
      sendOtpAgain();
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  const submitOtp = async (e) => {
    setOtpLoading(true);
    try {
      if (otp.length === 6) {
        let url = `${BASEURL}api/instructor/otp-verify`;
        let response = await axios.post(url, {
          phone: formInputs?.phone,
          otp,
        });
        if (response.data.error) {
          setOtpLoading(false);
          toast.error(response.data.message);
          return;
        } else {
          setLockPhone(true);
          setPhoneVerified(true);
          toast.success(response.data.message);
          setShowOtp(false);
          setOtpLoading(false);
        }
      } else {
        setOtpLoading(false);
        toast.error("enter 6 digit otp");
      }
    } catch (error) {
      setOtpLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="flex justify-between border-b pb-2.5 items-center">
        <h1 className="md:text-xl text-lg font-semibold  text-gray-800 uppercase lg:text-2xl ">
          Create your Instructor account
        </h1>

        <button
          title="Back"
          onClick={props.event}
          className="text-sm text-white cursor-pointer  w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center shadow rounded-full tracking-widest bg-color  "
        >
          <AiOutlineClose />
        </button>
      </div>

      <section className="py-5">
        <form onSubmit={handleSubmit} className=" grid ">
          {/* name */}
          <div className="p-2 mb-4 border rounded flex items-center gap-3">
            <input
              type="text"
              name="name"
              value={formInputs?.name}
              onChange={handleChange}
              placeholder="Name"
              className="outline-none text-sm  placeholder:text-sm tracking-wide  w-full"
            />
          </div>
          {/* phone */}
          <div className="p-2 mb-4 border rounded flex items-center gap-3">
            <input
              type="tel"
              name="phone"
              disabled={lockPhone}
              value={formInputs?.phone}
              onChange={handleChange}
              placeholder="Phone (must be UPI enabled))"
              maxLength={10}
              className="outline-none text-sm bg-transparent placeholder:text-sm tracking-wide  w-full"
            />
            {phoneVerifyProcess ? (
              <div className="text-right text-xs text-green-500  tracking-widest font-semibold  ">
                Verified
              </div>
            ) : (
              formInputs?.phone?.length > 9 && (
                <button
                  disabled={loading}
                  className="text-right text-xs  cursor-pointer tracking-widest font-semibold  "
                  onClick={(e) => phoneVerify(e)}
                >
                  {loading ? <ApiLoader /> : otpAgain ? "Resend" : "Verify"}
                </button>
              )
            )}
          </div>
          {/* OTP */}
          {showOtp && (
            <div className="p-2 mb-4 border rounded flex items-center gap-3">
              <input
                type="text"
                name="name"
                maxLength={6}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="outline-none w-full text-sm"
                placeholder="One Time Password (OTP)"
              />
              {otp && (
                <button
                  disabled={otploading}
                  className="text-right cursor-pointer text-xs  tracking-widest font-semibold "
                  onClick={(e) => submitOtp(e)}
                >
                  {otploading ? <ApiLoader /> : "Submit"}
                </button>
              )}
            </div>
          )}

          {/* email */}
          <div className="p-2 mb-4 border rounded flex items-center gap-3">
            <input
              type="email"
              name="email"
              value={formInputs?.email}
              onChange={handleChange}
              placeholder="Email"
              className="outline-none text-sm  placeholder:text-sm tracking-wide  w-full"
            />
          </div>
          {/* passowrd */}
          <div className="p-2 mb-4 border rounded flex items-center gap-3">
            <input
              type="password"
              name="password"
              value={formInputs?.password}
              onChange={handleChange}
              placeholder="Password"
              className="outline-none text-sm  placeholder:text-sm tracking-wide w-full"
            />
          </div>

          <div>
                <input type="checkbox" id="agree"
                  name="agree"
                  onChange={checkboxHandler}></input>
                <label htmlFor="agree"> I agree to <b><Link to="/terms" className="text-color"><u>Terms and Conditions</u></Link> and <Link to="/privacy-policy" className="text-color"><u>Privacy Policy</u></Link></b> </label>
          </div>
          <button
            type="Submit"
            disabled={loader}
            className={` px-4 py-2 text-sm sm:text-base font-medium text-white uppercase transition-colors duration-300 transform ${
              loader ? "bg-transparent" : "bg-color"
            }  rounded lg:w-auto cursor-pointer border-color focus:outline-none `}
          >
            {loader ? <ApiLoader /> : "Sign Up"}
          </button>
        </form>
      </section>

      <div className="flex justify-center border-t pt-2.5 items-center">
        <h1 className="font-medium  tracking-wide  md:text-base text-sm  text-gray-800 capitalize  ">
          Already have an account ?
          <span
            onClick={props.handleStep}
            className="  cursor-pointer pl-1  text-color  "
          >
            Login
          </span>
        </h1>
      </div>
    </>
  );
};

export default Register;
