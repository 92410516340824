import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import moment from 'moment';
import {
  addCourse,
  deleteCourse,
  getCourseByInstructor,
  updateCourse,
  getInstructorDemoMeetingSession,
} from "../../Redux/Actions/CourseAction";
import { instructorProfile } from "../../Redux/Actions/AuthAction";
import Button from "../../Components/Button";
import Heading from "../../Components/Heading";
import ApiLoader from "../../Components/ApiLoader";
import { INSTRUCTOR_ID, BASE_URL } from "../../config";
import DeleteModal from "../../Components/DeleteModal";
import { GetCategory, GetLanguages } from "../../Redux/Actions/CategoryAction";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [showPop, setShowPop] = useState(false);
  const courses = useSelector(
    (state) => state.CourseReducer.courseByInstructor
  );
  const loading = useSelector((state) => state.CourseReducer.loading);
  const [edit, setEdit] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [courseId, setCourseId] = useState();
  const [instID, setInstId] = useState();
  const category = useSelector((state) => state.CategoryReducer.categories);
  const languages = useSelector((state) => state.CategoryReducer.languages);
  const [formData, setFormData] = useState({});
  const INSTRUCTOR_ID = localStorage.getItem("instructorID|VST");
  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("name", formData.name);
    fd.append("image", formData.image);
    fd.append("instructor", INSTRUCTOR_ID);
    fd.append(
      "category",
      typeof formData.category === "object"
        ? formData.category?._id
        : formData.category
    );
    fd.append("description", formData.description);
    fd.append("language", formData.language);
    fd.append("preview", formData.preview);
    fd.append("demosessiontime", formData.demosessiontime);

    dispatch(
      edit
        ? updateCourse(fd, formData?._id, () => {
          setFormData({});
          dispatch(getCourseByInstructor(INSTRUCTOR_ID));
          setShowPop(false);
        })
        : addCourse(fd, formData?._id, () => {
          setFormData({});
          dispatch(getCourseByInstructor(INSTRUCTOR_ID));
          setShowPop(false);
        })
    );
  };

  const handleDelete = () => {
    dispatch(deleteCourse(courseId, instID, () => setShowPop(false)));
  };

  const handleModale = (item, data) => {
    setCourseId(item);
    setInstId(data);
    setShowModal(true);
  };
  const input =
    "bg-white w-auto border-color tracking-widest  text-xs rounded shadow-md outline-none py-1.5  px-2 border";

  useEffect(() => {
    dispatch(getCourseByInstructor(INSTRUCTOR_ID));
    dispatch(GetCategory());
    dispatch(GetLanguages());
    dispatch(instructorProfile(INSTRUCTOR_ID));
  }, [INSTRUCTOR_ID]);
  return (
    <>
      <div className=" container pb-16 min-h-screen mx-auto px-4">
        <div className="flex border-b py-5 pb-3  mb-14 items-center justify-between ">
          <Heading title={"Dashboard"} />
          <Link
            to="/Assets/Templates.zip" target="_blank"
            className="mt-1 text-sm cursor-pointer font-medium text-color"
            >
            Download_Templates
          </Link>
          <Button title={"Add Course"} event={() => setShowPop(true)} />
        </div>

        <div className="flex gap-y-5 flex-wrap -m-4">
          {courses?.map((item) => {
            return (
              <>
                <div
                  key={item._id}
                  className=" xl:w-1/4 lg:w-1/3 h-full sm:w-1/2 w-full"
                >
                  <div className=" px-4 sm:px-3  transition ease-out duration-1000 rounded-md">
                    <Link
                      to={`/instructor/course?id=${item._id}`}
                      className="block group mb-2 relative transition ease-out active:opacity-75 overflow-hidden "
                    >
                      <img
                        src={`${BASE_URL}${item.image}`}
                        //src="https://images.unsplash.com/photo-1526379095098-d400fd0bf935?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80"
                        alt={`${item.name.slice(0, 4)}...`}
                        // style={{height:'200px',width:'360px'}}
                        className="transform transition ease-out group-hover:scale-110"
                        style={{ height: '150px', width: '100%', margin: '0px' }}
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-25 transition ease-out group-hover:bg-opacity-0"></div>
                      <div className="p-4 flex items-center justify-center absolute inset-0">
                        {/* <div className="py-3 px-4 bg-white bg-opacity-95 rounded-3xl text-sm font-semibold uppercase tracking-wide transition ease-out group-hover:text-white group-hover:bg-blue-600">
                Computers
              </div> */}
                      </div>
                    </Link>

                    <div className="mt-2 py-2 tracking-wide">
                      <div className="flex justify-between">
                        <h3 className="text-gray-500 uppercase text-xs  title-font mb-1">
                          {item.category.name}
                        </h3>
                        <h3 className="text-gray-500 text-xs  title-font mb-1">
                          {item.createdAt.slice(0, 10)}
                        </h3>
                      </div>
                      <h2 className=" title-font text-lg font-medium">
                        {item.name.slice(0, 50)}
                        {item.name.length > 50 && "..."}
                      </h2>
                      {/* Description */}
                      <p className="text-slate-800 text-[11px]">
                        <td className="text-red-500 underline justify-left">
                          <button
                            onClick={() => dispatch(getInstructorDemoMeetingSession(item._id))}
                            className="cursor-pointer"
                          >
                            Orientation class scheduled for {item.demosessiontime ? moment(new Date(item.demosessiontime)).subtract(5.5, 'hours').format("DD/MMM/YYYY hh:mm A") : ""} IST.
                          </button>
                        </td>
                      </p>

                      <div className="flex justify-between items-center">
                        <Link
                          to={`/instructor/course?id=${item._id}`}
                          className="mt-1 text-sm cursor-pointer font-medium text-color"
                        >
                          Add_Subcourses
                        </Link>
                        {/* <Link
                          to="/Assets/Templates.zip" target="_blank"
                          className="mt-1 text-sm cursor-pointer font-medium text-color"
                          download>
                          Download_Templates
                        </Link> */}
                        <div className="text-center text-color text-xl flex gap-3 px-4 py-3">
                          {/* <FiTrash2
                            onClick={() =>{
                              handleModale(item._id, INSTRUCTOR_ID);
                              
                            }}
                            className="cursor-pointer"
                          /> */}
                          {/* <FiTrash2
                            onClick={() => dispatch(deleteCourse(item._id, INSTRUCTOR_ID))}
                            className="cursor-pointer text-red-500"
                          /> */}
                          <FiEdit
                            onClick={() => {
                              setShowPop(true);
                              setFormData(item);
                              setEdit(true);
                            }}
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      {/* To Delete */}
      {showModal && (
        <DeleteModal
          handleDelete={handleDelete}
          setDelete={() => {
            setShowModal(false)

          }}


        />
      )}

      {/* Form */}
      {showPop && (
        <div className="h-screen fixed top-0 left-0 z-40 w-full flex justify-center items-center bg-[rgb(0,0,0,0.5)]">
          <div className="bg-white md:w-2/3 sm:w-3/4 w-5/6 rounded p-4">
            {/* Top */}
            <div className="flex justify-between border-b pb-1.5 items-center">
              <h1 className="md:text-xl text-lg font-semibold  text-gray-800 capitalize lg:text-2xl ">
                {edit ? "Update" : "Add"} Course
              </h1>
              <button
                onClick={() => {

                  setShowPop(false);
                  setEdit(false);
                  setFormData({});
                }}
                className="text-sm text-white cursor-pointer  w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center shadow rounded-full tracking-widest bg-color "
              >
                <AiOutlineClose />
              </button>
            </div>
            {/* Form */}
            <form
              onSubmit={handleSubmit}
              className="mt-4 grid flex-col md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6"
            >
              {/* courseName  */}
              <div className="flex flex-col gap-1.5">
                <label htmlFor="courseName" className="text-xs">
                  Course Name
                </label>
                <input
                  id="courseName"
                  name="courseName"
                  type="text"
                  required={!edit}
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  className={input}
                />
              </div>
              {/*Image */}
              <div className="flex flex-col  gap-1.5">
                <label htmlFor="Image" className="text-xs">
                  Image
                </label>
                <input
                  id="Image"
                  name="Image"
                  type="file"
                  required={!edit}
                  accept="image/*"
                  onChange={(e) =>
                    setFormData({ ...formData, image: e.target.files[0] })
                  }
                  className={input}
                />
              </div>
              {/*Category */}
              <div className="flex flex-col  gap-1.5">
                <label htmlFor="Category" className="text-xs">
                  Category
                </label>
                <select
                  id="Category"
                  name="Category"
                  required={!edit}
                  value={edit ? formData.category?._id : formData.category}
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                  className={input}
                >
                  <option value="">Select Category</option>
                  {category?.map((data, index) => {
                    return (
                      <option key={index} value={data._id}>
                        {data.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/*Preview 
              <div className="flex flex-col  gap-1.5">
                <label htmlFor="Preview" className="text-xs">
                  Preview
                </label>
                <input
                  id="Preview"
                  type="file"
                  required={!edit}
                  accept="image/*"
                  onChange={(e) =>
                    setFormData({ ...formData, preview: e.target.files[0] })
                  }
                  className="bg-white border-indigo-400 tracking-widest  text-xs rounded-sm shadow-md outline-none py-1.5 px-2 border"
                />
              </div>*/}
              {/* language  */}
              <div className="flex flex-col gap-1.5">
                <label htmlFor="Language" className="text-xs">
                  Language
                </label>

                <select
                  id="Language"
                  name="language"
                  required={!edit}
                  value={formData.language}
                  onChange={(e) =>
                    setFormData({ ...formData, language: e.target.value })
                  }
                  className={input}
                >
                  <option value="">Select Language</option>
                  {languages?.map((data, index) => {
                    return (
                      <option key={index} value={data._id}>
                        {data.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/*Preview*/}
              <div className="flex flex-col  gap-1.5">
                <label htmlFor="demosessiontime" className="text-xs">
                  Orientation/Demo Session Time
                </label>
                <input
                  id="demosessiontime"
                  required={!edit}
                  type="datetime-local"
                  defaultValue={edit ? formData.demosessiontime : ""}
                  onChange={(e) =>
                    setFormData({ ...formData, demosessiontime: e.target.value })
                  }
                  className="bg-white border-indigo-400 tracking-widest  text-xs rounded-sm shadow-md outline-none py-1.5 px-2 border"
                />
              </div>
              {/*Description */}
              <div className="flex flex-col md:col-span-2 lg:col-span-3  gap-1.5">
                <label htmlFor="Description" className="text-xs">
                  Description
                </label>
                <textarea
                  id="Description"
                  name="Description"
                  required={!edit}
                  rows={5}
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  className={input}
                ></textarea>
              </div>
              <div className="flex items-center gap-4">
                <button
                  className={` px-4 py-2 text-xs tracking-widest font-medium text-white uppercase transition-colors duration-300 transform ${loading ? "bg-white border-color" : "bg-color"
                    } rounded lg:w-auto  focus:outline-none `}
                  type="submit"
                >
                  {loading ? <ApiLoader /> : edit ? "Update" : "Save"}
                </button>
                <button
                  onClick={() => {
                    setShowPop(false);
                    setEdit(false);
                    setFormData({});
                  }}
                  className=" px-4 py-2 text-xs tracking-widest font-medium text-black uppercase transition-colors duration-300 transform bg-gray-100 rounded lg:w-auto focus:outline-none"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
