import React from "react";
import { useState } from "react";
import { bcrypt } from "bcryptjs-react";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Components/Button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { INSTRUCTOR_ID } from "../../config";
import { INSTRUCTOR_TOKEN } from "../../config";
import {
  editInstructorProfileData,
  editInstructorProfileCertsData,
  instructorChangePass,
  instructorProfile,
  editInstructorProfilePicture,
} from "../../Redux/Actions/AuthAction";
import { getCourseByInstructor } from "../../Redux/Actions/CourseAction";
import InstructorComponent from "../../Components/InstructorComponent";
import { BsWindowSidebar } from "react-icons/bs";
const BASEURL = process.env.REACT_APP_BASE_URL;
const Profile = () => {
  const [img, setImg] = useState("");
  const [edit, setEdit] = useState();
  const [show, setShow] = useState();
  const [formValues, setFormValues] = useState({
    // pancard:"",
    // pincode:"",
    // city:"",
    // highestedu:""
  });
  const [formData, setFormData] = useState({
    
    certs: "",
  });
  const [formData1, setFormData1] = useState({
    profpic: "",
  });
  const [show1, setShow1] = useState();
  const [btnloading, setBTNLoading] = useState();
  const [multipleValue, setMultipleValue] = useState();
  const insId = localStorage.getItem("instructorData|VST");
  const instructorToken = localStorage.getItem("instructorToken|VST");
  const [image, setImage] = useState(null);

  

  // handleChange
  const handleInstructorProfileChange = (event) => {
    const { name, value } = event.target;
    const inputValue = name === 'yearsofexp' ? Math.max(0, parseInt(value, 10)) : value;
    setFormValues({
      ...formValues, [event.target.name]: event.target.value,
      [name]: inputValue
    });
  };
  const multipleData = (data) => {
    let file = Object.values(data);
    setMultipleValue(file);
  };

  const handleInstructorProfileCertsChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files
        ? e.target.files.length > 1
          ? multipleData(e.target.files)
          : e.target.files[0]
        : e.target.value,
    });
  };

  const handleInstructorProfilePicturehange = (e) => {
    setFormData1({
      ...formData1,
      [e.target.name]: e.target.files
        ? e.target.files.length > 1
          ? multipleData(e.target.files)
          : e.target.files[0]
        : e.target.value,
    });
  };

  const dispatch = useDispatch();
  let instructorData = useSelector((state) => state.AuthReducer.instructorData);
  const courses = useSelector(
    (state) => state.CourseReducer.courseByInstructor
  );

  const updateInstructorPassword = (event) => {
    event.preventDefault();

    if (formValues.password && formValues.prevpassword) {
      const payload = {
        password: formValues.password,
        prevpassword: formValues.prevpassword,
        instructorToken: localStorage.getItem("instructorToken|VST"),
        id: localStorage.getItem("instructorID|VST"),
      };
      setBTNLoading(true);
      dispatch(
        instructorChangePass(
          payload,
          () => setFormValues({ password: "", prevpassword: "" }),
          () => setBTNLoading(false)
        ));
    }
  };

  const updateInstructorProfileData = (event) => {
    event.preventDefault();
    // const fd = new FormData();
    // fd.append("pancard", formValues.pancard);
    const payload = {
      instructorToken: localStorage.getItem("instructorToken|VST"),
      id: localStorage.getItem("instructorID|VST"),
      data: formValues, formData
    };
  //   if(formValues.pancard==="" || formValues.city==="" || formValues.highestedu==="" )
  //   {return toast.error("Please enter required fields");}
    
  //  if(formValues.pincode.length>6 || formValues.pincode.length<6 )
  //  {return toast.error("Please enter 6 digit pincode only ")}
   
    dispatch(
      editInstructorProfileData(payload, localStorage.getItem("instructorID|VST"),
        () => setFormValues({ password: "", prevpassword: "" }),
        () => setBTNLoading(false))
    );
  };

  const updateProfile = () => {
    const fd = new FormData();
    fd.append("certs", formData.certs);
    
    const payload = {
      certs: formData.certs,
      instructorToken: localStorage.getItem("instructorToken|VST"),
      id: localStorage.getItem("instructorID|VST"),
    };
   
    if (formData.certs==="" ) {
      return toast.error("Please select documents to upload");
    }
   
    if(formData.certs.type==="application/zip" || formData.certs.name.toLowerCase().endsWith(".zip")){
      
      if(formData.certs.size < 1024000)
      {
        
        return dispatch(
          editInstructorProfileCertsData(fd, localStorage.getItem("instructorID|VST"))
        );
      
      }
      else{
        return toast.error("zip file size should be less than 1000KB")
      }
    }
    else{
      return toast.error("file should be in zip format")
    }
 
  }
  
  const updateProfilePicture = () => {
    const fd = new FormData();
    fd.append("profile", formData1.profile);
    const payload = {
      profile: formData1.profile,
      instructorToken: localStorage.getItem("instructorToken|VST"),
      id: localStorage.getItem("instructorID|VST"),
    };
    dispatch(
      editInstructorProfilePicture(fd, localStorage.getItem("instructorID|VST"))
    );
  }
  const updateProPicture = () => {
    const fd = new FormData();
    fd.append("profpic", formData1.profpic);
    const payload = {
      profpic: formData1.profpic,
      instructorToken: localStorage.getItem("instructorToken|VST"),
      id: localStorage.getItem("instructorID|VST"),
    };

    if(formData1.profpic===""){return toast.error("Please select profile picture");}
    if(formData1.profpic.size<2048000)
    {
      return  dispatch(
        editInstructorProfilePicture(fd, localStorage.getItem("instructorID|VST"))
      )
      
    }
    else{
      return toast.error("file should be less than 2000KB");
    }
    
  }

  const handleImageUpload = (event) => {
    const fd = new FormData();
    var reader = new FileReader();
    fd.append("profile", img);
    dispatch(editInstructorProfilePicture(fd, localStorage.getItem("instructorID|VST")));
  };
  useEffect(() => {
    dispatch(instructorProfile(INSTRUCTOR_ID));
    dispatch(getCourseByInstructor(INSTRUCTOR_ID));
  }, [dispatch, INSTRUCTOR_ID]);
  return (
    <>
      <div className="">
        <div className="bg-blue-200  rounded-sm p-4 tracking-wider ">
          <section className="grid md:grid-cols-2 items-center flex-col gap-5  py-8">
            <div className="">
              <figure className="w-40 border-color relative h-40 shadow rounded-full bg-white flex justify-center items-center mx-auto">
                <label htmlFor="profpic">
                  <span className="text-2xl cursor-pointer pb-1.5 flex justify-center items-center w-6 h-6 bg-color absolute bottom-2 right-2 rounded-full text-white">
                    +
                  </span>
                </label>
                <img
                  src={`${BASEURL}${instructorData?.profpic}`}
                  className="w-full   h-full rounded-full object-cover"
                />
                <img
                />
              </figure>
              <input
                id="profpic"
                type="file"
                name="profpic"
                defaultValue={edit ? formData1.profpic : ""}
                onChange={handleInstructorProfilePicturehange}
                className="hidden"
              />
              {/* <input
                id="profile"
                type="file"
                
                defaultValue={edit ? formData1.profile : ""}
                onChange={handleInstructorProfilePicturehange}
                className="hidden"
              /> */}

            </div>
            <div className="gap-2 grid">
              <div className="flex gap-3">
                <span className=" tracking-wider text-gray-600 font-medium">
                  Name :
                </span>
                {instructorData?.name}
              </div>
              <div className="flex gap-3">
                <span className=" tracking-wider text-gray-600 font-medium">
                  Email :
                </span>
                {instructorData?.email}
              </div>
              <div className="flex gap-3">
                <span className=" tracking-wider text-gray-600 font-medium">
                  Phone :
                </span>
                {instructorData?.phone}
              </div>
            </div>
           
          </section>
          <div className="flex items-center justify-end">
            <div className="">
              {(
                <Button event={updateProPicture} title=" Update Profile Picture" />
              )}
            </div>
          </div>
          
        </div>

        {/*1 */}
        <div className="bg-blue-200  rounded-sm p-4 tracking-wider my-6 md:my-10">
          <h3 className="text-slate-900 underline pb-6">Update </h3>
          <div className="grid md:grid-cols-2 grid-cols-1  gap-x-8">
            {/* Current Password */}
            {/* <div className="flex flex-col gap-2">
              <label htmlFor="profile" className="text-xs">
                Profile Picture ::
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  id="profile"
                  type="file"
                  accept="image/*"
                  //defaultValue={edit ? formData.profile : ""}
                  //onChange={handleInstructorProfileCertsChange}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div> */}
            {/* Password */}
            <div className="flex flex-col gap-2 group">
              <label htmlFor="Password" className="text-xs">
                Certificates<span className="text-red-600 font-bold"> * </span>::<span className="text-red-600  hidden group-hover:block">[Please upload zip file with Pancard photo and credential certificates or proof of establishment documents.]</span>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="certs"
                  type="file"
                  accept=".zip"
                  defaultValue={edit ? formData.certs : ""}
                  onChange={handleInstructorProfileCertsChange}
                  // value={formData.certs}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="">
              {(
                <Button event={updateProfile} title=" Update" />
              )}
            </div>
          </div>
        </div>
        {/* 2 */}
        <div className="bg-blue-200  rounded-sm p-4 tracking-wider my-6 md:my-10">
          <h3 className="text-slate-900 underline pb-6">Update Password</h3>
          <div className="grid md:grid-cols-2 grid-cols-1  gap-x-8">
            {/* Current Password */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-xs">
                Current Password
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  id="Current"
                  name="prevpassword"
                  type={show ? "text" : "password"}
                  onChange={handleInstructorProfileChange}
                  value={formValues?.prevpassword}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
                <span
                  onClick={() => setShow(!show)}
                  className="text-xl ml-2 text-slate-800 cursor-pointer"
                >
                  {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </div>
            {/* Password */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Password" className="text-xs">
                New Password
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  id="Password"
                  name="password"
                  value={formValues?.password}
                  onChange={handleInstructorProfileChange}
                  type={show1 ? "text" : "password"}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
                <span
                  onClick={() => setShow1(!show1)}
                  className="text-xl ml-2 text-slate-800 cursor-pointer"
                >
                  {show1 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="">
              {(
                <Button event={updateInstructorPassword} title="Change Password" />
              )}
            </div>
          </div>
        </div>
        {/* 2 */}
        <div className="bg-blue-200  rounded-sm p-4 tracking-wider my-6 md:my-10">
          <h3 className="text-slate-900 underline pb-6">Update Profile</h3>
          <div className="grid md:grid-cols-2 grid-cols-1  gap-x-8">
            {/* Address Line 1 */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Address Line 1" className="text-orange">
                Address Line 1::<b>{instructorData?.addrline1}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="addrline1"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? formValues.addrline1 : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.addrline1}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>
            {/* Address Line 2 */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Password" className="text-orange">
                Address Line 2::<b>{instructorData?.addrline2}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="addrline2"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? formValues.addrline2 : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.addrline2}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* City */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                City<span className="text-red-600 font-bold"> * </span>::<b>{instructorData?.city}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="city"
                  type="text"
                  maxLength={25}
                  defaultValue={edit ? formValues.city : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.city}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* State */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                State::<b>{instructorData?.state}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="state"
                  type="text"
                  maxLength={25}
                  defaultValue={edit ? formValues.state : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.state}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Pincode */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                PinCode<span className="text-red-600 font-bold"> * </span>::<b>{instructorData?.pincode}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="pincode"
                  type="number"
                  max={6}
                  defaultValue={edit ? (Number(formValues.pincode) ? formValues.pincode : 0) : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.pincode}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Alternate Contact Number */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Referral Email::<b>{instructorData?.refemail}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="refemail"
                  type="email"
                  maxLength={50}
                  defaultValue={edit ? formValues.refemail : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.refemail}

                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Highest Educational Qualification */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Highest Education<span className="text-red-600 font-bold"> * </span>::<b>{instructorData?.highestedu}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="highestedu"
                  type="text"
                  maxLength={25}
                  defaultValue={edit ? formValues.highestedu : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.highestedu}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Years of Experience */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Years of Experience::<b>{instructorData?.yearsofexp}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="yearsofexp"
                  type="number"

                  defaultValue={edit ? formValues.yearsofexp : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.yearsofexp}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Current Organisation */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Current Organisation::<b>{instructorData?.currorg}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="currorg"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? formValues.currorg : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.currorg}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Current Role */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Current Role::<b>{instructorData?.currole}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="currole"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? formValues.currole : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.currole}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Previous Organisation */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Previous Organisation::<b>{instructorData?.prevorg}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="prevorg"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? formValues.prevorg : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.prevorg}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Subject Specialization */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Subject Specialization::<b>{instructorData?.subspec}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="subspec"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? formValues.subspec : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.subspec}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Pancard<span className="text-red-600 font-bold"> * </span>::<b>{instructorData?.pancard}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="pancard"
                  type="text"
                  maxLength={10}
                  defaultValue={edit ? formValues.pancard : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.pancard}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                BatchCode ::<b>{instructorData?.batchcode}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="batchcode"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? formValues.batchcode : ""}
                  onChange={handleInstructorProfileChange}
                  value={formValues.batchcode}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

          </div>
          <div className="flex items-center justify-end">
            <div className="">
              {(
                <Button event={updateInstructorProfileData} title="Update Profile" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructorComponent(Profile);
