import React from "react";

const Terms = () => {
  return (
    <section className="container mx-auto sm:px-0 py-5 px-4">
      <h3 className="text-2xl md:text-3xl xl:text-4xl py-3 tracking-wider font-semibold">
      TERMS OF USE
      </h3>
      {/* 1 */}
      <div className="tracking-wider space-y-2.5 sm:py-2 py-1 md:py-1">
<br></br>
We StarDataInsights, a sole proprietor company, registered in India, having its office at B-404, Ganga Ocean Square (G.O Square) Wakad Hinjewadi Road, Near 
Mankar Chowk, Wakad Pune, Maharastra 411057 (collectively “STARDATAINSIGHTS”, “Company", “we”, “us”, “ours”) and is operating its business under the brand 
name “VERYSIMILARTESTS” (hereinafter referred to as “Brand Name”). We have designed a platform under the Brand Name that will provide various courses on various subjects taught by experts in their fields. From programming to competitive exam preparation, academics to digital marketing, data science, cloud computing to corporate pieces of training, there's something for everyone. We believe that learning should be engaging, interactive, and fun, and we strive to make our courses as interactive and engaging as possible. VerySimilarTests will undertake all invoicing to both teachers and students through our website bearing the URL www.verysimilartests.com (hereinafter referred to as “Website”) and mobile application vstapp (hereinafter referred to as “Application”). This Terms of Use (hereinafter referred to as “Terms of Use”) is our commitment towards your trust, and you have to provide the finest Services for teachers and students through our Website and Application. This Terms of Use lays down all the terms and conditions surrounding the usage of our Website and Application for you and the company.
<br></br> 
Upon usage, registration, or even browsing to our Website and Application, creates a contractual relationship between you and the company through this Terms of Use and other relevant policies for all contractual, legal, and compliance purposes. This Terms of Use is considered a legally binding agreement and is also considered as an electronic record in terms of the Information Technology Act, 2000 and rules thereunder as applicable and the amended provisions about electronic records in various statutes as amended by the Information Technology Act, 2000. A computer system generates this electronic record and does not require any physical or digital signatures.
<br></br>
<br></br>
1. DEFINITION AND INTERPRETATION
<br></br>
<br/>1.1.  Definition
<br></br>
1.1.1. 	Applicable Laws - shall mean and refer to all the laws, rules, ordinances, by-laws, etc., and amendments thereafter, applicable within the territory of India.
<br></br>1.1.2. 	Content – shall mean and refer to all the text, advertisement, graphics, user/student's interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music, and artwork.
<br></br>1.1.3. 	Third Parties – shall mean and refer to all the legal entities either individual or entity linked on the Website or Application apart from the user/student's and the creator of this Website and Application.
<br></br>1.1.4. 	Service – shall mean and refer to all the company's digital marketing and promotion activities for the goods and services of the user/student.
<br></br><br></br>1.2.  Interpretation
<br></br>1.2.1. 	Company – For the purposes of this Terms of Use, wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the company and its owned Website and Application.
<br></br>1.2.2. 	Platform – For the purposes of this Terms of Use, the Website and the Application shall be referred to as the Platform, unless specified otherwise in this Terms of Use.
<br></br>1.2.3. 	The headings of each section in these Terms of Use are only to organise the various provisions under these Terms of Use in an orderly manner. You shall not use them to interpret the provisions contained herein in a manner as may apply to you. Further, it is specifically agreed by you that the headings shall not have legal or contractual value on your usage of the Website and Platform.
<br></br>1.2.4. 	The Parties shall refer and mean the company and Teacher’s/Student’s together to interpret terms of this Terms of Use.
<br></br>1.2.6. 	The use of this Website and Application is solely governed by these Terms of Use, Privacy Policy, and any other relevant policies as updated on the Website and Application and any modifications or amendments made thereto by us from time to time, at our sole discretion. If you as a Teacher/Student continue to access and use this Website and/or Application, you are agreeing to comply with and be bound by the following Terms of Use, Privacy Policy, and any other applicable policies, thereof.
<br></br>1.2.7. 	You expressly agree and acknowledge that these Terms of Use and Privacy Policy, and any other applicable policies, are co-terminus in nature and that expiry/termination of either one will lead to the termination of the other.
<br></br>1.2.8. 	You unequivocally agree that these Terms of Use, Privacy Policy, and any other applicable policies, constitute a legally binding agreement between us, and that you shall be subject to the rules, guidelines, policies, terms, and conditions applicable to your use of the Platform, and that the same shall be deemed to be incorporated into these Terms of Use and shall be treated as part and parcel of the same. You acknowledge and agree that no signature or express act is required to make these Terms of Use and the Privacy Policy binding on you and that your act of visiting/browsing any part of the Website and/or Platform constitutes your full and final acceptance of these Terms of Use, Privacy Policy, and any other applicable policies.
<br></br>1.2.9. 	We reserve the sole and exclusive right to amend or modify these Terms of Use without any prior permission or intimation to you, and you expressly agree that any such amendments or modifications shall come into effect immediately. If you do not adhere to the changes, you must stop using the Website and/or Application at once. Your continued use of the Website and/or Application will signify your acceptance of the changed Terms of Use.
<br></br>
2. 	Platform Overview
www.verysimilartests.com is an online learning platform that allows instructors to create and publish courses on a wide range of topics. The platform operates in the following way:
<br></br><br></br>2.1.  Create a Course: Teachers design courses that include learning resources, a schedule, and a number of sessions. They can post their materials so that any interested students can access them.
<br></br>2.2.  Course Publication: When the course is developed, the VerySimilarTests team analyses the course material to make sure it complies with service standards and policies before approving its publishing.
<br></br>2.3.  Enrollment of Students: On VerySimilarTests, students may look for and enroll in courses. The VerySimilarTests website or mobile app can be used to access the course content.
<br></br>2.4.  Delivery of the course: Before the date of the scheduled lecture sessions, students and the teacher receive emails from VerySimilarTests that include a video bridge link. The instructor serves as the moderator by selecting the video bridge connection to start the session. The session would be open to students who have registered for that course.
<br></br>2.5.  Payment to instructors: When lecturers have finished their presentations, the finance staff at VerySimilarTests creates an invoice and credits the instructor's account with the appropriate amount. As a commission, VerySimilarTests retains a portion of the tuition.<br></br>
<br></br>2.6.  Support and feedback: Assistance and feedback are provided by VerySimilarTests for both teachers and students. Students may obtain support with technological concerns or course material, while instructors can get aid with course planning and marketing. In order to assist other students in making educated choices on the courses to enrol in, students may also offer comments and ratings on the courses they have taken.
<br></br><br></br>
3. 	Services on the Platform
    VerySimilarTests’s range of services includes providing an online learning platform that links students with teachers who design and publish courses on a variety of subjects. Services provided by VerySimilarTests include:
    <br></br>3.1.  Create a Course: VerySimilarTests enables educators to design and share their courses on the website.
    <br></br>3.2.  Course Delivery: With the help of VerySimilarTests, teachers may give live, interactive lectures to their students.
    <br></br>3.3.  Sharing of Revenue: The teacher and VerySimilarTests split the course's earnings. As a commission, VerySimilarTests retains a portion of the tuition.
    <br></br>3.4.  Students Support: VerySimilarTests offers assistance to students who sign up for courses on the site. This covers help with technological problems, course material, and other queries.
    <br></br>3.5.  Teachers Support: VerySimilarTests offers assistance to teachers who design courses on the platform. This covers help with course development, marketing, and other issues.
    <br></br>3.6.  Ultimately, the services offered by VerySimilarTests are designed to make education accessible and inexpensive for everyone on the planet. The platform offers a wide selection of courses that can be taken from any location with an internet connection, covering anything from business and technology to health and wellness.
    <br></br><br></br>
4. 	Compliance
<br></br>4.1.  This Terms of Use is made in accordance with the provisions of the Information Technology Act, 2000 and the rules thereinunder, along with the amendment from time to time. This Terms of Use is published on the Platform in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 of  Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Use and practices for access and usage of any functional Website and Application.
 
<br></br><br></br>5. 	Registration
<br></br>5.1.  Registration on the Platform is mandatory for Website and Application Users/Teachers/Students. 
<br></br>The Users/Teachers/Students can register by providing the following information/data:
<br></br>i.           	Name;
<br></br>ii.         	Age 
<br></br>iii.        	Date of Birth
<br></br>iv.        Highest educational qualification 
<br></br>v.         Address     
<br></br>vi.        Billing Address  
<br></br>vii.       Phone Number
<br></br>viii.      Email ID
<br></br>Additionally, following data/information will be collected for the teachers only:
<br></br>i.           	Account details in which they will be getting their payments, 
<br></br>ii.         	Course delivery language 

<br></br>5.2.  VerySimilarTests also collects data on time spent and activities inside the platform to provide reports and usage analytics.
 
<br></br><br></br><br></br>6. 	Eligibility
<br></br>6.1.  You represent and warrant that you are competent and eligible to enter into legally binding agreements of competent age and have the requisite authority to bind yourself/themselves to these Terms of Use as per the Applicable Law. However, if you are a minor using this Website and/or Application, you may do so with the consent of your legal guardian. All Acts of the minor shall be deemed to be considered as acts undertaken under the supervision of their legal guardian.
<br></br>6.2.  You further represent that you will comply with these Terms of Use and all applicable local, state, national, and international laws, rules, and regulations.
<br></br>6.3.  You shall not use the Website and/or Application if you are not competent to enter into a contract or are disqualified from doing so by any other Applicable Laws, rules, or regulations, currently in force.
<br></br><br></br>
7. 	Payments
<br></br>7.1. For making all payments for services on the Website and Mobile Application, you shall be required to make a payment, for which you will be redirected to a third-party payment gateway with which we may have an agreement. The payment gateway may additionally seek information from you to process the payment for your purchase and additionally may charge you the payment gateway charges that may be applicable. The payment gateway will be CCAvenue or CashFree or RazorPay. You shall be governed under the concerned payment gateway’s Terms and Conditions and other Policies for the purpose of all payment-related aspects.

<br></br>7.2. VerySimilarTests may offer you the right to pay through cash or other channels such as cash collection. You agree that Paid Services will be made available to you only upon receipt of payment confirmation from the Payment Gateway or collection agencies. Upon making payment to us through any medium whatsoever, you warrant that you are entitled to use the medium or otherwise make the payment to us under applicable law. 
<br></br>7.3. You have the ability to provide us with your Goods and Services Tax Identification Number (GSTIN) on the platform. Your GSTIN details are mandatory for passing on the credit of GST charged. If you fail to provide us with your GSTIN details, we assume you are not registered with the GST authorities without independently verifying your registration status. Unless otherwise mentioned on the Website, prices indicated include applicable taxes.
<br></br><br></br> 
8. 	Content
<br></br>8.1.  General: All the Content is generated/provided or based on information provided by the Users/Teachers or Third Parties. We have no control and make no guarantees regarding the quality, accuracy, integrity, and/or genuineness of such Content or other information provided on the Platform.
<br></br>8.2.  All the Content displayed on the Platform is subject to copyright. It shall not be reused by You (or a third party) without the company's and copyright owner's prior written consent.
<br></br>8.3.  You are solely responsible for the integrity, authenticity, quality, and/or genuineness of the Content provided by you on the Platform. Whilst feedback and comments by You can be made via the Website and/or Application, we bear no liability whatsoever for any feedback or comments made by the other Users/Teachers or made in respect of any of the Content on the Platform. Further, the company reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the company or to terminate the account of any User who is found to have created or shared or submitted any Content or part thereof that is found to be untrue/ inaccurate/ misleading/ offensive/ vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/ sharing/ submission of Content or part thereof that is deemed to be untrue/ inaccurate/ misleading/ offensive/ vulgar.
<br></br>8.4.  You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the Content on the Platform. You shall not copy, adapt, and modify any Content without written permission from the company.
<br></br><br></br>
9. 	Indemnity
<br></br>9.1.  You agree to indemnify, defend and hold harmless the Us. Our respective directors, officers, employees, and agents (hereinafter collectively referred to as "Parties") from and against any losses, liabilities, claims, damages, demands, costs, and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant, or agreement made or obligation to be performed according to these Terms of Use. Further, you agree to hold the company harmless against any claims made by any Third-Party due to/ or arising out of or in connection with
<br></br>a) 	Your use of the Website and/or Application;
<br></br>b)	Your violation of these Terms of Use;
<br></br>c) 	Your violation of any rights of another party, Users, and/or Third-Party;
<br></br>d)	Your alleged improper conduct according to these Terms of Use; and
<br></br>e) 	Your conduct in connection with the Website and/or Application.
 
<br></br>9.2.  You agree to fully cooperate in indemnifying the company at your own expense. You also agree not to settle with any party without the company's consent.
<br></br>9.3.  In no event shall we be liable to compensate you or any Third Party for any special, incidental, indirect, consequential, or punitive damages whatsoever, including those resulting from loss of use, data, or profits, whether or not foreseeable, and whether or not you had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence, or other tortuous action, or any other claim arising out of or in connection with your use of, or access, or availing Services from or materials contained therein on the Website and/or Application.
 
<br></br><br></br>10.  Limitation of Liability
<br></br>10.1.           	We are not responsible for any consequences arising out of the following events:
<br></br>10.1.1.  if the Website and/or Platform is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, or server failure;
<br></br>10.1.2.  if you have fed incorrect information or data or for any deletion of data;
<br></br>10.1.3.  if there is an undue delay or inability to communicate through email;
<br></br>10.1.4.  if there is any deficiency or defect in the Services managed by the company; and
<br></br>10.1.5.  if there is a failure in the functioning of any other Service the company provides.
<br></br><br></br>10.2.           	The Website and/or Application accepts no liability for any errors or omissions on behalf of itself or for any damage caused to you, your belongings, or any Third Party resulting from the use or misuse of the Platform or any Service provided by the company through the Platform. The Service and any Content or material displayed of the Services are provided without any guarantees, conditions, or warranties as to its accuracy, suitability, completeness, or reliability. We will not be liable to you for the unavailability or failure of the Platform.
<br></br>10.3.           	You are to comply with all Applicable Laws upon you or on your activities and with all applicable policies, which are hereby incorporated into this Terms of Use by reference.
<br></br>10.4.           	The Platform expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the Website and which is incurred by you in connection with the Website, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of Use.
<br></br>10.5.           	To the fullest extent permitted by law, We shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Website.
<br></br>
11.  Term
<br></br>11.1.           	These Terms of Use shall continue to form a valid and binding agreement between us and shall continue to be in full force and effect until you continue to access and use the Websites and/or Application.
<br></br>11.2.           	You may terminate your use of the Website and/or Application at any time, as per the process specified under this Terms of Use.
<br></br>11.3.           	We may terminate these Terms of Use with you and close your account at any time without notice and/or suspend or terminate Your access to the Website and/or Application at any time and for any reasons if any discrepancy or legal issue arises.
<br></br>11.4.           	Such suspension or termination shall not limit our right to take any other action against you that we consider appropriate.
<br></br>11.5.           	It is also hereby declared that we may discontinue the Services on the Websites and/or Application without any prior notice.
<br></br>11.6.        Accepting our policy as a Non-GST User also means that they authorise us to raise self-raising invoices that amount to prices after deducting GST and Platform fees.
<br></br>11.9.         Teacher’s must ensure consistent quality of service to the Student’s/User’s and ensure they adhere to students requirements; repeated poor quality deliveries can lead to a reduction in rating and even suspension and/or cancellation of Teacher’s accounts.
<br></br>11.10.           If Teacher’s have doubts regarding the nature of the information that needs to be shared to complete the course, they can contact VerySimilarTests via email Id support@verysimilartests.com to clarify their doubts.
<br></br><br></br>12.  Termination
<br></br>12.1.           	In its sole discretion, we reserve the right to unilaterally terminate Your access to the Website, or any portion thereof, at any time, without notice or cause.
<br></br>12.2.           	We also reserve the universal right to deny access to You to any/all of are on its Website without any prior notice/explanation to protect the interests of the Website and/or other Users/Teachers/Students to the Website.
<br></br>12.3.              Any violation will lead to suspension post-investigation by the VerySimilarTests team; during the investigation, VerySimilarTests has the right to terminate the User’s accounts.
<br></br>12.4.           	You shall continue to be bound by these Terms of use, and you expressly agree that You shall not have the right to terminate these Terms of Use until the same expires.
<br></br>12.5.       Any sharing or requesting of personal information or information pertaining to causes that leads to courses being carried out of VerySimilarTests will be marked as a violation unless it is explicitly for carrying out the course.
<br></br>12.6.         We reserve the right to limit, deny or create different access to the Website and its features concerning different Users/Teachers or to change any of the features or introduce new features without prior notice.
<br></br><br></br>13.  Communication
By using this Website and/or Application and providing your identity and contact information to the company through the Website and/or Application, you agree and consent to receive e-mails or SMS from us and/or any of its representatives at any time.
 
You can report to “support@verysimilartests.com” if you find any discrepancy with regard to Website and/or Application or Content-related information, and we will take necessary action after an investigation.  The response with resolution (if any issues are found) shall depend on the time taken for investigation.
 
You expressly agree that notwithstanding anything contained hereinabove, it may be contacted by us relating to any Services provided by you on the Website and/or Application or anything pursuant thereto, and you agree to indemnify us from any harassment claims. It is expressly agreed to by us that any information shared by us shall be governed by the Privacy Policy.
 
<br></br><br></br>14.  User obligations and formal undertakings as to conduct
You agree and acknowledge that you are a restricted user/teacher of this Platform, and you:
<br></br>14.1.           	agree to provide genuine credentials during the process whenever required on the Website and/or Application. You shall not use a fictitious identity. We are not liable if you have provided incorrect information;
<br></br>14.2.           	agree to ensure the Name, Email address, Phone Number, and any such other information that may be provided is valid at all times and shall keep your information accurate and up-to-date;
<br></br>14.3.           	You agree that you are solely responsible for maintaining the confidentiality of your account. You agree to notify us immediately of any unauthorised use of your account. We reserve the right to close your account at any time for any or no reason;<br></br>
<br></br>14.4.           	understand and acknowledge that the data submitted is manually entered into the database of the Platform. You also acknowledge the fact that data so entered into the database is for easy and ready reference for you and to streamline the Services through the Website and/or Platform;
<br></br>14.5.           	authorise the Platform to use, store, or otherwise process certain personal information and all published Content, responses, locations, User comments, reviews and ratings for personalisation of Services, marketing and promotional purposes, and for optimisation of User-related options and Services;
<br></br>14.6.           	understand and agree that, to the fullest extent permissible by law, the Platform or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the Platform or this Terms of Use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages;
<br></br>14.7.           	are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the Platform. Any such use/limited use of the Platform will only be allowed with the prior express written permission;
<br></br>14.8.           	agree not to access (or attempt to access) the Platform and/or the materials or Services by any means other than through the interface provided by the Platform. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or its Content or in any way reproduce or circumvent the navigational structure or presentation of the Platform, materials or any Content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform will lead to suspension or termination of your access to the Platform. We disclaim any liabilities arising concerning such offensive Content on the Platform; and
<br></br>14.9.           	expressly agree and acknowledge that the company does not own the Content generated by the Users/Teacher and displays on the Platform and that we are in no way responsible for the Content of the same. You may, however, report any offensive or objectionable content, which we may then remove from the Platform at our sole discretion.
<br></br>
You further undertake not to:
<br></br>14.10.        	engage in any activity that interferes with or disrupts access to the Platform or the Services provided therein (or the servers and networks which are connected to the Platform);
<br></br>14.11.        	impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity;
<br></br>14.12.        	probe, scan, or test the vulnerability of the Platform or any network connected to the Platform, nor breach the security or authentication measures on the Platform or any network connected to the Platform. The User may not reverse look-up, trace, or seek to trace any information relating to any other User of, or visitor to the Platform, or any other viewer of the Platform, including any User account, maintained on the Platform not operated/managed by the User, or exploit the Platform or information made available or offered by or through the Platform, in any manner;
<br></br>14.13.        	disrupt or interfere with the security of, or otherwise cause harm to the Platform, systems resources, accounts, passwords, servers, or networks connected to or accessible through the Platform or any affiliated or linked Platform;
<br></br>14.14.        	use the Platform or any material or Content therein for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of this Platform or any other Third-Party(ies);
<br></br>14.15.        	violate any code of conduct or guideline which may apply for or to any particular Service offered through the Platform;
<br></br>14.16.        	violate any applicable laws, rules, or regulations currently in force within or outside India;
<br></br>14.17.        	violate any portion of these Terms of Use or the Privacy Policy, including but not limited to any applicable additional terms and conditions of the Platform contained herein or elsewhere, whether made by amendment, modification or otherwise;
<br></br>14.18.        	commit any act that causes the company to lose (in whole or in part) the Services of its Internet Establishment ("ISP") or in any manner disrupts the Services of any other supplier/service provider of the Platform;
<br></br>14.19.        	you hereby expressly authorise the company to disclose any and all information relating to you in our possession to law enforcement or other government officials as we may, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. You further understand that the Platform might be directed to disclose any information (including the identity of persons providing information or materials on the Platform) as necessary to satisfy any judicial order, Applicable Law, regulation or valid governmental request;
<br></br>14.20.        	by indicating your acceptance to use any Services offered through the Platform, you are obligated to complete such transactions after making payment. You shall be prohibited from indicating their acceptance to avail of Services where the transactions have remained incomplete;
<br></br>14.21.        	you agree to use the Services provided by the company, our affiliates, consultants and contracted companies, for lawful purposes only;
<br></br>14.22.        	you agree to provide authentic and true information. We reserve the right to confirm and validate the information and other details you provided at any time. If upon confirmation such details are found to be false, not to be true (wholly or partly), we shall, in our sole discretion reject the order and debar you from using the Platform without prior intimation whatsoever;
<br></br>14.23.        	you agree not to post any material on the Platform that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful or advertise any goods or services. More specifically, you agree not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that:
<br></br>14.23.1.             	belongs to another person and to which you have no right to;
<br></br>14.23.2.             	is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
<br></br>14.23.3.             	is in any way harmful to minors;
<br></br>14.23.4.             	infringes any patent, trademark, copyright or other proprietary rights;
<br></br>14.23.5.             	violates any Applicable Law for the time being in force;
<br></br>14.23.6.             	deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing;
<br></br>14.23.7.             	abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;
<br></br>14.23.8.             	impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;
<br></br>14.23.9.             	publish, post, disseminate any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's Privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986; and
<br></br>14.23.10.          	threatens the unity, integrity, defence, security, or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.
<br></br><br></br>14.24.  VerySimilarTests requires Teachers to not share any information such as personal information, like phone number, email ID, social media accounts etc to the User/Students, unless specifically for the purpose of completion of the doubts from the course. Any personal details shared in any manner that incentivises the user/students to use outside the framework of VerySimilarTests will be marked as a violation. Any Teachers requesting the same from a User/Student is also marked as violation. All project deliverables should be through the workspace and/or chat, Teachers can share personal cloud storage links via chat to share files crossing the limit in VerySimilarTests.
 
<br></br>15.  Suspension of User/Teachers access and activity
Notwithstanding other legal remedies that may be available, we may, in our sole discretion, limit your access and/or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend/terminating your association with the Platform, and/or refusing to the usage of the Platform, without being required to provide you with notice or cause:
<br></br>a.          	if you are in breach of any of these Terms of Use, Privacy Policy, or any other applicable policies;
<br></br>b.          	if you have provided wrong, inaccurate, incomplete or incorrect information; and
<br></br>c.          	if your actions may cause any harm, damage or loss to the other Users/Teachers/Students or the company, at our sole discretion.
 
<br></br><br></br>16.  Intellectual Property Rights
<br></br>16.1.  Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports, and other distinctive brand features, save according to the provisions of these Terms of Use that shall be available on the Platform. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the Platform or such other Third-Party and other distinctive brand features of the Platform are the property of the Platform or the respective copyright or trademark owner. Furthermore, concerning the Platform, we shall be the exclusive owner of all the designs, graphics and the like related to the Platform.
<br></br>16.2.  Teachers are allowed to use the Intellectual Property of VerySimilarTests such as the Trademark logo, name, and website, to promote their services and portfolio. However, Users/Teachers, in general, are not allowed to run ads that contain VerySimilarTests Trademarks and properties.
<br></br>16.2.  You shall not use any of the Intellectual Property displayed on the Platform in any manner that is likely to cause confusion among existing or prospective Users/Teacher of the Platform or that in any manner disparages or discredits the Platform, to be determined in the sole discretion.
<br></br>16.3.  Teachers should ensure their work does not infringe any protected data, content or information of the user/student or the company. If any user raises such violations/issues, VerySimilarTests reserves the right to suspend and/or terminate the Teacher account immediately.
<br></br>16.4.  You are aware all Intellectual Property, including but not limited to copyrights, relating to said Services resides with the owners and that at no point does any such Intellectual Property stand transferred from the aforementioned creators. You are aware that we merely provide the Platform through which you can communicate with other Users/Teachers/Students. The Platform does not own any of the Intellectual Property relating to the independent Content displayed on the Platform, apart from created graphics and specified Content.
<br></br>16.5.  You are further aware that any reproduction or infringement of the Intellectual Property of the aforementioned owners by you will result in legal action being initiated against you by the respective owners of the Intellectual Property so reproduced/infringed upon. You agree that the Contents of this section shall survive even after the termination or expiry of these Terms of Use and/or Privacy Policy.
<br></br><br></br>17.  Disclaimer of Warranties and Liabilities
<br></br>17.1.  Acceptance of Teachers' profiles are only after the company completes background verification; they need to adhere to our Terms and Conditions and Privacy policy to be on the platform.
<br></br>17.2.  VerySimilarTests does not guarantee the quality of work the Teachers deliver to the Users/Students. VerySimilarTests wants to be free of any lawsuits about issues with quality/delivery/duration of work and no-shows from either party that lead to projects being held up. VerySimilarTests only acts as a mediator and can only assist either party with the required information to help their case be stronger.
<br></br>17.2.           	You agree that any kind of information, resources, activities, or recommendations obtained/availed from the Platform, written or oral, will not create any warranty and we disclaim all liabilities resulting from these.
<br></br>17.3.           	We do not guarantee that the features and content contained in the Platform will be uninterrupted or error-free or that the Platform or its server will be free of viruses or other harmful components. You hereby expressly accept any associated risks involved with your use of the Platform.
<br></br>17.3.               Users/Teachers who posts courses on or request services that include illegal, obscene or unethical requests can be suspended or removed from the platform. Course posted can be subjected to review if required.
<br></br>17.4.             Users/Teachers are not allowed to promote other websites or their websites or links that invite other users to go to website links. Inviting people to social media accounts or competitive sites will be a violation.
<br></br>17.5.              Teachers are requested not to ask for or share personal or company details of the user/students unless it is directly related to the completion of the job. Please consult support@verysimilartests.com .if you have any questions regarding the same.
<br></br>17.6.           	You further agree to it that the contents of this section shall survive even after the termination or expiry of the Terms of Use and/or Privacy Policy.
 
<br></br><br></br>18.  FORCE MAJEURE
We will not be liable for damages for any delay or failure to perform our obligations hereunder if such delay or failure is due to cause beyond our control or without its fault or negligence due to force majeure events, including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorised.
 
<br></br><br></br>19.  DISPUTE RESOLUTION AND JURISDICTION
In the event of any dispute arising out of or in connection with this Terms of Use, including any dispute relating to the validity of this Terms of Use, the Parties shall, at first instance, attempt to resolve the dispute by mediation administered by the Centre for Online Resolution of Disputes (“CORD”) (www.resolveoncord.com) and conducted in accordance with the CORD Rules of Mediation, by a sole mediator to be appointed by CORD.
In the event the Parties are unable to resolve any such dispute through mediation within 45 days of the initiation of the dispute, subject to a contrary agreement between the Parties, the dispute shall be finally resolved by arbitration administered by CORD and conducted in accordance with the Rules of Arbitration of CORD, by a sole arbitrator to be appointed by CORD. The language of the arbitration shall be English. The seat of arbitration shall be Pune, India.
You expressly agree that the Terms of Use, Privacy Policy and any other agreements entered into between the Parties are governed by India's laws, rules, and regulations.
<br></br><br></br>
20.  MISCELLANEOUS PROVISIONS
<br></br>20.1.           	Entire Agreement: These Terms of Use, read with the Privacy Policy and Disclaimer, form the complete and final contract between us with respect to the subject matter hereof and supersede all other communications, representations, and agreements (whether oral, written, or otherwise) relating thereto.
<br></br>20.2.           	Waiver: The failure at any time to require the performance of any provision of these Terms of Use shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of Use, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach or a waiver of any other breach of these Terms of Use.
<br></br>20.3.           	Severability: If any provision/clause of these Terms of Use is held to be invalid, illegal, or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of Use shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of Use shall be valid and enforceable to the fullest extent permitted by Applicable Law. In such case, these Terms of Use shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability while preserving to the maximum extent the Parties' original rights, intentions and commercial expectations hereto, as expressed herein.
<br></br>20.4.           	Contact Us: If you have any questions about these Terms of Use, the practices of the Website, or your experience, you can contact us by emailing us at support@verysimilartests.com   or by writing to us at :
<br></br><br></br> 
STARDATAINSIGHTS
<br></br>B 404, Ganga Ocean Square (G.O Square), 
<br></br>Wakad Hinjewadi Road, Near Mankar Chowk, 
<br></br>Wakad Pune, Maharastra 411057
<br></br>Primary Contact No. +918275694545
<br></br>Secondary Contact No. +918275584545

      </div>{" "}
    </section>
  );
};

export default Terms;
