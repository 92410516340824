// import Logo from "../Components/Logo";
import React, { useState } from "react";
import { TfiEmail, TfiLock } from "react-icons/tfi";
import {
  Link,
  unstable_HistoryRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserLogin } from "../Redux/Actions/AuthAction";
import axios from "axios";
import { toast } from "react-hot-toast";
import ApiLoader from "../Components/ApiLoader";

const BASEURL = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.AuthReducer);
  const [emailVerify, setEmailVerify] = useState(false);
  const [formValues, setFormValues] = useState({});

  // handleChange
  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  // handelSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formValues.email && formValues.password) {
      dispatch(
        UserLogin(
          formValues,
          () => {
            setFormValues({});
            navigate("/");
          },
          (event) => setEmailVerify(event)
        )
      );
    } else {
      toast.error("Please fill all fields");
    }
  };

  const sendEmailVerification = async () => {
    let url = `${BASEURL}api/auth/verify-email`;

    try {
      const res = await axios.put(url, {
        email: formValues.email,
      });
      if (!res.data.error) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="flex lg:h-screen lg:flex-row flex-col  bg-white w-full lg:bg-gray-50 lg:gap-5 items-center">
        <figure className=" lg:h-full h-80 md:h-96 w-full  lg:w-1/3 flex ">
          <img
            src="Assets/login-bg-2.jpg"
            className="h-full object-cover w-full"
            alt="register"
          />
        </figure>
        <section className="lg:w-2/3 my-4  w-full lg:p-4 sm:w-4/5 md:w-3/4 mx-auto lg:px-20">
          <div className="lg:p-10 p-5 bg-white rounded lg:shadow">
            {/* <Logo/> */}
            <h3 className="text-lg mb-5 uppercase font-bold">
              Student Login
            </h3>
            <form onSubmit={handleSubmit}>
              {/* Email */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="email">
                  <TfiEmail className="text-gray-500 text-xl" />
                </label>
                <input
                  type="email"
                  name="email"
                  value={formValues?.email}
                  onChange={handleChange}
                  className="outline-none px-3 w-full text-sm"
                  placeholder="Your Email Address"
                />
              </div>
              {/* Password */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="password">
                  <TfiLock className="text-gray-500 text-xl" />
                </label>
                <input
                  type="password"
                  name="password"
                  value={formValues?.password}
                  onChange={handleChange}
                  className="outline-none px-3 w-full text-sm"
                  placeholder="Password"
                />
              </div>

              {/* Forgot */}
              <div className="px-1 mb-5 rounded sm:flex-row flex-col flex justify-between items-center gap-1 sm:gap-3">
                <Link to="/forgot" className="font-medium text-sm">
                  Forgot Your Password ?
                </Link>
                {/* <div className=" text-center text-sm">
                  {emailVerify && (
                    <span
                      className="text-color hover:no-underline cursor-pointer font-semibold underline"
                      onClick={() => sendEmailVerification()}
                    >
                      Send Email verification mail again
                    </span>
                  )}
                </div> */}
              </div>
              {/* Submit */}
              <div
                className={` mb-5 border hover:bg-transparent hover:text-color ${
                  data.loading ? "bg-transparent" : "bg-color"
                }  text-white cursor-pointer border-color text-center hover:text-black rounded flex items-center gap-3`}
              >
                <button
                  type="submit"
                  disabled={data.loading}
                  className="w-full p-2 uppercase cursor-pointer text-center"
                >
                  {data.loading ? <ApiLoader /> : "Login"}
                </button>
              </div>
              {/* Login Text */}
              <div className=" flex items-center gap-1.5">
                <span className="text-gray-500">Don't have an account ? </span>
                <Link to="/register" className="text-color  ">
                  SignUp
                </Link>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
