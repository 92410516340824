import React from "react";
import { BASEURL } from "../Redux/Actions/ActionTypes";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, USER_ID } from "../config";
import {
  deleteCartItem,
  deleteBySbCourse,
  getCart,
} from "../Redux/Actions/CartAction";
import { addFav } from "../Redux/Actions/WishlistAction";

const CartItem = ({ data, subcourse }) => {
  const userData = (localStorage.getItem("userData|VST"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deleteCart = (itemId) => {
    let payload = {
      user: userData,
      course: itemId,
    };
    dispatch(deleteCartItem(payload));
  };
  const GetCart = () => {
    dispatch(getCart());
  }
  const addFavourite = () => {
    const payload = {
      user: userData,
      course: data?._id,
    };
    dispatch(addFav(payload));
  };
  const deleteBySubCourse = (sbCourseId) => {
    let payload = {
      user: userData,
      subcourse: sbCourseId,
    };
    dispatch(
      deleteBySbCourse({
        send: {
          data: payload,
          callback: () => {
            dispatch(getCart(userData));
          },
        },
      })
    );
  };
  return (
    <>
      <li className="flex capitalize flex-col py-6 sm:flex-row sm:justify-between">
        <div className="flex w-full gap-7">
          <img
            className="flex-shrink-0 object-cover w-20 h-20 dark:border-transparent rounded outline-none sm:w-36 sm:h-36 dark:bg-gray-500"
            src={`${BASE_URL}${data?.image}`}
            alt="Set of travel chargers"
          />
          <div className="flex flex-col justify-between w-full pb-4">
            <div className="flex justify-between w-full pb-2 space-x-2">
              <div className="space-y-1">
                <h3 className="text-color font-semibold  sm:pr-8">
                  {data?.name}
                </h3>
                <p className="text-sm dark:text-gray-400">{data?.language}</p>
              </div>

              <div className="text-right">
                <p className=" font-semibold">₹{data?.price}</p>
                <p className="text-sm line-through dark:text-gray-600">
                  {/* 15.99€ */}
                </p>
              </div>
            </div>

            <div className="flex flex-col">
              {subcourse &&
                subcourse.map((sbCourse, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="flex py-2 px-4 shadow-sm border border-gray-100 rounded-sm text-sm mb-2 bg-gray-50  capitalize justify-between items-center"
                      >
                        <p>
                          {index + 1}. {sbCourse?.name}
                        </p>
                        <p className="flex items-center gap-3">
                          {" "}
                          {sbCourse?.price && `₹ ${sbCourse?.price} /-`}
                          {/* <button
                            type="button"
                            className="flex text-red-600 items-center px-2 py-1 pl-0 space-x-1"
                            //onClick={() => deleteBySubCourse(sbCourse._id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              className="w-4 h-4 fill-current"
                            >
                              <path d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z"></path>
                              <rect
                                width="32"
                                height="200"
                                x="168"
                                y="216"
                              ></rect>
                              <rect
                                width="32"
                                height="200"
                                x="240"
                                y="216"
                              ></rect>
                              <rect
                                width="32"
                                height="200"
                                x="312"
                                y="216"
                              ></rect>
                              <path d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"></path>
                            </svg>
                          </button> */}
                        </p>
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="flex text-sm divide-x">
              <button
                type="button"
                className="flex items-center px-2 py-1 pl-0 space-x-1"
                onClick={() => {deleteCart(data._id); setTimeout(function() {
                  navigate("/courses");}, 1000);
                }
                }
              >
                <svg color="red"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="w-4 h-4 fill-current"
                >
                  <path d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z"></path>
                  <rect width="32" height="200" x="168" y="216"></rect>
                  <rect width="32" height="200" x="240" y="216"></rect>
                  <rect width="32" height="200" x="312" y="216"></rect>
                  <path d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"></path>
                </svg>
                <span>Remove all subcourses </span>
              </button>
              <button
                type="button"
                onClick={() => addFavourite()}
                className="flex items-center px-2 py-1 space-x-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="w-4 h-4 fill-current"
                >
                  <path d="M453.122,79.012a128,128,0,0,0-181.087.068l-15.511,15.7L241.142,79.114l-.1-.1a128,128,0,0,0-181.02,0l-6.91,6.91a128,128,0,0,0,0,181.019L235.485,449.314l20.595,21.578.491-.492.533.533L276.4,450.574,460.032,266.94a128.147,128.147,0,0,0,0-181.019ZM437.4,244.313,256.571,425.146,75.738,244.313a96,96,0,0,1,0-135.764l6.911-6.91a96,96,0,0,1,135.713-.051l38.093,38.787,38.274-38.736a96,96,0,0,1,135.765,0l6.91,6.909A96.11,96.11,0,0,1,437.4,244.313Z"></path>
                </svg>
                <span>Add to favorites</span>
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default CartItem;
