import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL, USER_ID } from "../../config";
import {
  ADD_SESSIONS,
  ADD_SESSIONS_FAILURE,
  ADD_SESSIONS_SUCCESS,
  DELETE_SESSIONS,
  DELETE_SESSIONS_FAILURE,
  DELETE_SESSIONS_SUCCESS,
  GET_SESSIONS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_SUCCESS,
  UPDATE_SESSIONS,
  UPDATE_SESSIONS_FAILURE,
  UPDATE_SESSIONS_SUCCESS,
} from "./ActionTypes";

export const getSessions = (id) => async (dispatch) => {
  dispatch({ type: GET_SESSIONS });
  let url = `${BASE_URL}api/session/subcourse/${id}`;
  try {
    const res = await axios.get(url, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    dispatch({
      type: GET_SESSIONS_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_SESSIONS_FAILURE });
  }
};

export const addSessions = (payload, close) => async (dispatch) => {
  dispatch({ type: ADD_SESSIONS });

  let url = `${BASE_URL}api/session`;
  try {
    const res = await axios.post(url, payload, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    toast.success("Added Session");

    dispatch({
      type: ADD_SESSIONS_SUCCESS,
      payload: res.data.data,
    });
    close();
  } catch (error) {
    dispatch({ type: ADD_SESSIONS_FAILURE });
  }
};

export const updateSessions = (payload, id, close) => async (dispatch) => {
  dispatch({ type: UPDATE_SESSIONS });
  let url = `${BASE_URL}api/session/${id}`;
  try {
    let res = await axios.patch(url, payload, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });

    dispatch({
      type: UPDATE_SESSIONS_SUCCESS,
      payload: res.data.data,
    });
    close();
  } catch (error) {
    dispatch({ type: UPDATE_SESSIONS_FAILURE });
  }
};

export const deleteSession = (id) => async (dispatch) => {
  dispatch({ type: DELETE_SESSIONS });

  let url = `${BASE_URL}api/session/${id}`;
  try {
    let res = await axios.delete(url, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    if (res.status === 200) {
      toast.success(res.data.message);
      if (res.data.message === "Incomplete session can not be deleted.")
      {
        dispatch({
          type: DELETE_SESSIONS_SUCCESS,
        });
      } else {
        dispatch({
          type: DELETE_SESSIONS_SUCCESS,
          payload: id,
        });
      }
    }
  } catch (error) {
    dispatch({ type: DELETE_SESSIONS_FAILURE });
  }
};


export const getMeetingSession = (id) => async (dispatch) => {
  dispatch({ type: GET_SESSIONS });

  let url = `${BASE_URL}api/session/meeting/${id}`;
  try {
    let res = await axios.get(url, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    if (res.status === 200) {
      if (res.data.data === "Please start the meeting at the scheduled time." ||
          res.data.data === "The session is over.....")
      {
        toast.success(res.data.message);
      } else {
        window.open(res.data.data);
      }
    }
  } catch (error) {
    dispatch({ type: GET_SESSIONS_FAILURE });
  }
};

export const getStudentMeetingSession = (obj) => async (dispatch) => {
  dispatch({ type: GET_SESSIONS });
  let url = `${BASE_URL}api/session/student/meeting/${obj.id}`;
  try {
    let res = await axios.get(url, {
      params: {
        name: obj.name,
      },
      headers: {
        userToken: localStorage.getItem("userToken|VST"),
      },
    });
    if (res.status === 200) {
      if (res.data.data === "Please wait, the session has not started yet" ||
          res.data.data === "The session is over...")
      {
        toast.success(res.data.message);
        dispatch({
          type: GET_SESSIONS_SUCCESS
      });
      } else {
        window.open(res.data.data);
      }
    }
  } catch (error) {
    dispatch({ type: GET_SESSIONS_FAILURE });
  }
};

export const checkLogSession = (payload) => async (dispatch) => {
  let url = `${BASE_URL}api/session-log`;
  try {
    await axios.patch(url, payload.data, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    });

    return payload.callback();
  } catch (error) {
    dispatch({ type: DELETE_SESSIONS_FAILURE });
  }
};
