import React from "react";

const Button = (props) => {
  return (
    <button
      className=" px-4 py-2 text-xs  tracking-widest font-medium text-white uppercase transition-colors duration-300 transform bg-color rounded lg:w-auto  focus:outline-none "
      onClick={props.event}
    >
      {props.title}
    </button>
  );
};

export default Button;
