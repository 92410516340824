import {
  ADD_BY_USER_ENROLLMENT,
  ADD_BY_USER_ENROLLMENT_FAILURE,
  ADD_BY_USER_ENROLLMENT_SUCCESS,
  ADD_COURSE_BY_INSTRUCTOR,
  ADD_COURSE_BY_INSTRUCTOR_FAILURE,
  ADD_COURSE_BY_INSTRUCTOR_SUCCESS,
  GET_COURSE,
  GET_ALL_COURSE,
  GET_COURSE_BY_ID,
  GET_COURSE_BY_ID_FAILURE,
  GET_COURSE_BY_ID_SUCCESS,
  GET_COURSE_BY_INSTRUCTOR,
  GET_COURSE_BY_INSTRUCTOR_FAILURE,
  GET_COURSE_BY_INSTRUCTOR_SUCCESS,
  GET_COURSE_FAILURE,
  GET_COURSE_SUCCESS,
  GET_ALL_COURSE_FAILURE,
  GET_ALL_COURSE_SUCCESS,
  GET_ENROLLMENT_COURSE,
  GET_ENROLLMENT_COURSE_FAILURE,
  GET_ENROLLMENT_COURSE_SUCCESS,
  GET_POPULAR_COURSE,
  GET_POPULAR_COURSE_FAILURE,
  GET_POPULAR_COURSE_SUCCESS,
  GET_TRENDING_COURSE,
  GET_TRENDING_COURSE_FAILURE,
  GET_TRENDING_COURSE_SUCCESS,
  UPDATE_COURSE_BY_INSTRUCTOR,
  UPDATE_COURSE_BY_INSTRUCTOR_FAILURE,
  UPDATE_COURSE_BY_INSTRUCTOR_SUCCESS,
} from "../Actions/ActionTypes";

const initialState = {
  loading: false,
  courses: [],
  searchList: [],
  courseById: {},
  subcourse: {},
  popularCourses: [],
  trendingCourses: [],
  courseByInstructor: [],
  allCourseData: {},
  courselog: [],
};

const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET_COURSE
    case GET_COURSE:
      return { ...state, loading: true };
    case GET_COURSE_SUCCESS:
      return { ...state, courses: action.payload, loading: false };
    case GET_COURSE_FAILURE:
      return { ...state, loading: false };

    // GET_ALL_COURSE
    case GET_ALL_COURSE:
      return { ...state, loading: true };
    case GET_ALL_COURSE_SUCCESS:
      return { ...state, searchList: action.payload, loading: false };
    case GET_ALL_COURSE_FAILURE:
      return { ...state, loading: false };

    // GET_POPULAR_COURSE
    case GET_POPULAR_COURSE:
      return { ...state, loading: true };
    case GET_POPULAR_COURSE_SUCCESS:
      return { ...state, popularCourses: action.payload, loading: false };
    case GET_POPULAR_COURSE_FAILURE:
      return { ...state, loading: false };

    // GET_TRENDING_COURSE
    case GET_TRENDING_COURSE:
      return { ...state, loading: true };
    case GET_TRENDING_COURSE_SUCCESS:
      return { ...state, trendingCourses: action.payload, loading: false };
    case GET_TRENDING_COURSE_FAILURE:
      return { ...state, loading: false };

    // GET_COURSE_BY_ID
    case GET_COURSE_BY_ID:
      return { ...state, loading: true };
    case GET_COURSE_BY_ID_SUCCESS:
      return { ...state, courseById: action.payload, loading: false };
    case GET_COURSE_BY_ID_FAILURE:
      return { ...state, loading: false };

    //  GET_COURSE_BY_INSTRUCTOR
    case GET_COURSE_BY_INSTRUCTOR:
      return { ...state };
    case GET_COURSE_BY_INSTRUCTOR_SUCCESS:
      return { ...state, courseByInstructor: action.payload };
    case GET_COURSE_BY_INSTRUCTOR_FAILURE:
      return { ...state };

    //   ADD_COURSE_BY_INSTRUCTOR
    case ADD_COURSE_BY_INSTRUCTOR:
      return { ...state, loading: true };
    case ADD_COURSE_BY_INSTRUCTOR_SUCCESS:
      return {
        ...state,
        courseByInstructor: [...state.courseByInstructor, action.payload],
        loading: false,
      };
    case ADD_COURSE_BY_INSTRUCTOR_FAILURE:
      return { ...state, loading: false };

    //   UPDATE_COURSE_BY_INSTRUCTOR
    case UPDATE_COURSE_BY_INSTRUCTOR:
      return { ...state, loading: true };
    case UPDATE_COURSE_BY_INSTRUCTOR_SUCCESS:
      let tempArr = [...state.courseByInstructor];
      let index = tempArr.findIndex((item) => item._id === action.payload._id);
      let x = { ...action.payload };
      if (index > -1) {
        tempArr[index] = {
          ...tempArr[index],
          ...x,
        };
      }
      return {
        ...state,
        courseByInstructor: tempArr,
        loading: false,
      };
    case UPDATE_COURSE_BY_INSTRUCTOR_FAILURE:
      return { ...state, loading: false };

    // ADD_BY_USER_ENROLLMENT
    case ADD_BY_USER_ENROLLMENT:
      return { ...state, loading: true };
    case ADD_BY_USER_ENROLLMENT_SUCCESS:
      return { ...state, loading: false, courselog: action.payload.courselog };
    case ADD_BY_USER_ENROLLMENT_FAILURE:
      return { ...state, loading: false };

    // GET_ENROLLMENT_COURSE
    case GET_ENROLLMENT_COURSE:
      return { ...state, loading: true };
    case GET_ENROLLMENT_COURSE_SUCCESS:
      return {
        ...state,
        course: action.payload.course,
        subcourse: action.payload.subcourse,
        allCourseData: action.payload,
        courselog: action.payload.courselog,
        loading: false,
      };
    case GET_ENROLLMENT_COURSE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default CourseReducer;
