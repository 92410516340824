import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { payment } from "../Redux/Actions/PayAction";
import { useEffect, useState } from "react";
import Button from "./Button";
import { Link, useNavigate } from "react-router-dom";
import { MERCHANT_ID, SALT_KEY, SALT_INDEX, USER_ID } from "../config";
import { getCart } from "../Redux/Actions/CartAction";
import axios from "axios";
import { v4 as uuid } from 'uuid';
import { PLACE_ORDER_SUCCESS } from "../Redux/Actions/ActionTypes";
import toast from "react-hot-toast";

const Checkout = ({ data }) => {

  // for (const item of data.subcourse) {
  //   console.log("item", item)
  // }
  // console.log("datadatadata", data, data.subcourse[data?.course[0]?._id], data?.course[0])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderData = useSelector((state) => state.PayReducer.order);
  const userData = useSelector((state) => state.AuthReducer.userData);
  const userID = localStorage.getItem("userID|VST");
  const [hamburger, setHamburger] = useState(false);
  const userToken = (localStorage.getItem("userData|VST"));
  const clickCheckout = async () => {

    //console.log("USER_ID", USER_ID, USER_ID.length, new Date().getTime())
    let payload = await {
      token: userToken,
      user: USER_ID,
      course: data?.course[0]._id,
      subcourse: USER_ID,
      sections: data.subcourse[data?.course[0]._id],
      transaction_id: new Date().getTime(),
      payment_successful: false,
      sub_price: data.price ? data.price : 0,
      price: data.price ? data.price + Number(9 * Number(data.price) / 100) * 2 : 0,
      sgst: data.price ? Number(9 * Number(data.price) / 100) : 0,
      cgst: data.price ? Number(9 * Number(data.price) / 100) : 0,

    };
    await dispatch(payment(payload));
    // dispatch(getCart());
  };

  useEffect(() => {
    console.log(orderData?.data?.data?.data, "orderDataorderDataorderData", orderData)
    if (orderData && orderData.data && orderData.data.data && orderData.data.data.data && orderData.data.data.data.instrumentResponse && orderData.data.data.data.instrumentResponse.redirectInfo) {
      let win = window.open(orderData.data.data.data.instrumentResponse.redirectInfo.url, "_self");
      win.document.body.innerHTML = "Loading...";
      // popupCenter({url: orderData.data.data.data.instrumentResponse.redirectInfo.url, title: 'Phonepe', w: 900, h: 600});  
      dispatch({
        type: PLACE_ORDER_SUCCESS,
        payload: { data: {} },
      });
    } else {
      console.log("please try later!")
      handleClose();
      // toast.
    }
  }, [orderData, userID]);


  // const popupCenter = ({url, title, w, h}) => {
  //   const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  //   const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  //   const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : 800;
  //   const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : 600;

  //   const systemZoom = width / window.screen.availWidth;
  //   const left = (width - w) / 2 / systemZoom + dualScreenLeft
  //   const top = (height - h) / 2 / systemZoom + dualScreenTop
  //   const newWindow = window.open(url, title, 
  //     `
  //     scrollbars=yes,
  //     width=${w / systemZoom}, 
  //     height=${h / systemZoom}, 
  //     top=${top}, 
  //     left=${left}
  //     `
  //   )

  //   if (newWindow) {
  //       newWindow.focus();
  //   }
  // }


  const handleClose = () => setHamburger(false);
  return (
    <>
      {data.price ? <div className="flex flex-col w-full rounded-sm mb-10 lg:w-2/5 space-y-4 divide-y   ">
        <h2 className="text-2xl font-semibold">Order items</h2>
        <div className="pt-4 space-y-2">
          <div>
            <div className="flex justify-between">
              <span>Subtotal</span>
              <span>{data.price}₹</span>
            </div>
          </div>
        </div>
        <div className="pt-4 space-y-2">
          {/* <div className="flex flex-col">
              <Link
                rel="noopener noreferrer"
                to="/"
                className="text-xs hover:underline text-color"
              >
                How do our fees work?
              </Link>
            </div> */}
          <div className="space-y-4 ">
            <div className="flex justify-between">
              <span>Tax</span>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="font-semibold">SGST: {data.price ? 9 * Number(data.price) / 100 : 0}₹</span>
                <span className="font-semibold">CGST: {data.price ? 9 * Number(data.price) / 100 : 0}₹</span>
              </div>
            </div>
            <div className="flex justify-between">
              <span>Total</span>
              <span className="font-semibold">{data.price ? data.price + Number(9 * Number(data.price) / 100) * 2 : 0}₹</span>
            </div>
            <button
              type="button"
              className="w-full py-2 font-semibold text-white rounded bg-color uppercase border-color"
              onClick={clickCheckout}
            >
              Go to checkout
            </button>

            {/*<Button
              title="GO to checkout"
              event={() => {
                navigate("/payment");
                handleClose();
              }}
              Go to chekcout
            /> */}
          </div>
        </div>
      </div> : null}
    </>
  );
};

export default Checkout;