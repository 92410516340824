import {
  ADD_ORDER,
  ADD_ORDER_FAILURE,
  ADD_ORDER_SUCCESS,
  DELETE_ORDER_SUCCESS,
  GET_ORDER,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS
} from "../Actions/ActionTypes";

const initialState = {
  order: [],
  loading: false,
  itemCount: 0
};

const OrderReducer = (state = initialState, action) => {

  // console.log("statestatestatestate", state)
// 
  switch (action.type) {
    // Get Cart
    case GET_ORDER:
      return { ...state, loading: true };

    case GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        itemCount: action.payload.count,
        loading: true
      };

    case GET_ORDER_FAILURE:
      return { ...state, loading: false };

    //   Add Cart

    case ADD_ORDER:
      return { ...state, loading: true };

    case ADD_ORDER_SUCCESS:
      return { ...state, loading: true, itemCount: action.payload.count };

    case ADD_ORDER_FAILURE:
      return { ...state, loading: false };

    //   Item Count

    case DELETE_ORDER_SUCCESS:
      let temp = state.order.course;
      let da = temp.filter((t) => t._id !== action.payload);
      return {
        ...state,
        order: { ...state.order, course: [...da] },
        itemCount: state.itemCount - 1
      };

    default:
      return state;
  }
};

export default OrderReducer;
