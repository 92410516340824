import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ isInstruter }) => {
  let isUserAuth = localStorage.getItem("userToken|VST");
  let isInstructorAuth = localStorage.getItem("instructorToken|VST");

  if (isInstruter) {
    return isInstructorAuth ? <Outlet /> : <Navigate to={"/"} />;
  } else {
    return isUserAuth ? <Outlet /> : <Navigate to={"/"} />;
  }
};

export default PrivateRoute;
