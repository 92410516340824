import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { BASE_URL, INSTRUCTOR_ID, INSTRUCTOR_TOKEN, USER_ID } from "../../config";
import NoData from "../../Components/NoData";
import Button from "../../Components/Button";
import InstructorComponent from "../../Components/InstructorComponent";
import { FiTrash2, FiEye, FiEyeOff } from "react-icons/fi";
import lodash from 'lodash';


const EnrolledInstructor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null)
  const [courseData, setCourseData] = useState([]);
  const [object, setObject] = useState({});
  const [filter, setfilter] = useState("incomplete");

  const token = localStorage.getItem("instructorToken|VST");
  const id = localStorage.getItem("instructorID|VST");

  const getBooking = async (id) => {
    const res = await axios.get(`${BASE_URL}api/enrollment/session/instructor/${id}/${filter}`, {
      headers: {
        instructortoken: token,
      },
    });
    if (!res.data.error) {
      if (filter == "incomplete") {
        setCourseData(res.data.data)
      } else {
        let cats = lodash.groupBy(res.data.data, 'subcourse.name');
        console.log("cats", cats)
        Object.keys(cats).map(function (key, index) {
          console.log("key, index", key, index)
          cats[key][0]['expand'] = false;
        });
        console.log("typeof", typeof (cats), cats)
        cats = Object.keys(cats).sort().reduce((res, key) => (res[key] = cats[key], res), {})

        console.log("cats", cats);
        setCourseData(cats)
      }
    } else {
      setCourseData([])
    }

  }

  const sessionComplete = async (item, type, value, cprice) => {
    let url = "";
    if (value == "subcourse") {
      url = `${BASE_URL}api/enrollment/session/subcourse/status/${item._id}`
    } else {
      url = `${BASE_URL}api/enrollment/session/status/${item._id}`
    }
    const res = await axios.put(url, { status: type, price: cprice }, {
      headers: {
        instructortoken: localStorage.getItem("instructorToken|VST"),
      },
    });
    if (!res.data.error) {
      getBooking(id);
      // setCourseData(res.data.data)
    } else {
      // setCourseData([])
    }
  }

  useEffect(() => {
    getBooking(id);
    // dispatch(getMyEnrollCourse(USER_ID));
  }, [id]);

  useEffect(() => {
    // setCourseData(course);
    callfilterApi();
  }, [filter]);

  const callfilterApi = async () => {
    if (filter == "incomplete") {
      const res = await axios.get(`${BASE_URL}api/enrollment/session/instructor/${id}/${filter}`, {
        headers: {
          instructortoken: token,
        },
      });
      if (!res.data.error) {
        setCourseData(res.data.data)
      } else {
        setCourseData([])
      }

    } else {
      const res = await axios.get(`${BASE_URL}api/enrollment/session/instructor/${id}/${filter}`, {
        headers: {
          instructortoken: token,
        },
      });
      if (!res.data.error) {
        let cats = lodash.groupBy(res.data.data, 'subcourse.name');
        console.log("cats", cats)
        Object.keys(cats).map(function (key, index) {
          console.log("key, index", key, index)
          cats[key][0]['expand'] = false;
        });
        console.log("typeof", typeof (cats), cats)
        cats = Object.keys(cats).sort().reduce((res, key) => (res[key] = cats[key], res), {})
        console.log("cats", cats);
        setCourseData(cats);
      } else {
        setCourseData([]);

      }
    }
  }



  function handleSelectChange(event) {
    console.log("event.target.valueevent.target.valueevent.target.value", event.target.value)
    setfilter(event.target.value);
    setCourseData([]);
  }

  const setValue = (item) => {
    // setOpen(true);
    setObject(item);
    navigate(`/instructor/enrolled-course/${item._id}`, {
      state: { data: item }
    });
    // /instructor/enrolled-course/:id
  }

  const handleEpandRow = async (item, index) => {
    console.log("itemitemitemitem", item)
    if (filter == "incomplete") {
      courseData[index].expand = !courseData[index].expand;
      console.log("courseDatacourseData", courseData)
      setCourseData([...courseData])
    } else {
      courseData[item][0].expand = !courseData[item][0].expand;
      setCourseData({ ...courseData })
    }
  }

  const getUserLength = (item) => {
    let user = 0;
    if (item && item.sessions && item.sessions.length > 0) {
      item.sessions.forEach(element => {
        // if (element.users && element.users.length > 0) {
          user = element.users.length;
        // }
      });
      return user;
    }
    return user;
  }


  return (
    <div className="py-0">
      {/* {courseData && courseData?.length === 0 && (
       
      )} */}
      {/* {courseData &&
        courseData?.length !== 0 &&
        courseData?.map((e, i) => {
          return (
            <React.Fragment key={e._id}>
              <CourseCard enrolled={true} data={e} />
            </React.Fragment>
          );
        })} */}

      <div className="overflow-x-auto py-5">
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '12px' }}>
          <select style={{ padding: '10px 20px', background: ' #29aefb', color: '#fff', borderRadius: '5px', minWidth: '150px' }} onChange={handleSelectChange} value={filter}>
            <option value="complete">Complete</option>
            <option value="incomplete">Incomplete</option>
          </select>
        </div>
        <table className="table-auto min-w-full text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">

              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                Course Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Subcourse Name
              </th>

              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Student count
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Overall course fees
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Instructor earnings
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Status
              </th>
              {filter == 'incomplete' && <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Action
              </th>}
              {/* <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br">
                Actions
              </th> */}
            </tr>
          </thead>


          {((filter == "incomplete" && courseData && courseData?.length > 0) || (filter == "complete" && courseData && Object.keys(courseData)?.length > 0)) && <tbody>

            {
              filter == "incomplete" ?
                courseData.map((item, index) => (

                  <>
                    <tr key={index} className="border-b text-sm">


                      <td className="px-4 py-3">
                        <button onClick={(event) => handleEpandRow(item, index)} style={{ padding: '5px 10px', background: '#ddd', color: '#111', fontSize: '12px', borderRadius: '5px' }}>
                          {/* {courseData[item][0].expand?'Close':'Open'} */}

                          {item.expand ? <FiEye
                            style={{ fontSize: 18 }}
                            className="cursor-pointer"
                          /> :
                            <FiEyeOff
                              style={{ fontSize: 18 }}
                              className="cursor-pointer"
                            />}
                        </button>
                      </td>
                      <td className="px-4 py-3">{item?.course?.name}</td>
                      <td className="px-4 py-3">{item?.name}</td>
                      <td className="px-4 py-3">{getUserLength(item)}</td>
                      <td className="px-4 py-3 ">
                        {item?.price}
                      </td>
                      <td className="px-4 py-3 ">
                        {item && item.price ? item.price * getUserLength(item) * 0.65: 0}
                      </td>
                      <td className="px-4 py-3 ">
                        {item?.status}
                      </td>
                      {filter == 'incomplete' && item && getUserLength(item) > 0 &&
                        <td className="px-4 py-3 ">
                          <button onClick={() => sessionComplete(item, 'complete', 'subcourse', item && item.price ? item.price * getUserLength(item) * 0.65: 0)} style={{ padding: '5px 10px', background: '#29aefb', color: '#fff', fontSize: '12px', width: '120px' }}>Mark as Complete</button>
                        </td>
                      }
                    </tr>

                    {item?.expand ? <>
                      <tr rowSpan="9">
                        <th></th>
                        <th colSpan="3" className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                          Session Name
                        </th>
                        <th colSpan="3" className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                          Status
                        </th>
                        {filter == 'incomplete' && <th colSpan="2" className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                          Action
                        </th>}
                      </tr>
                      {
                        item.sessions.length > 0 ? item.sessions.map((el, index) => (
                          <tr colSpan="8" key={el._id} className="border-b text-sm">
                            <td onClick={() => setValue(el)} className="px-4 py-3"></td>
                            <td onClick={() => setValue(el)} className="px-4 py-3" colSpan="3">{el.session.name}</td>
                            <td onClick={() => setValue(el)} className="px-4 py-3" colSpan="3">{el.status}</td>
                            {filter == 'incomplete' && item.sessions.length != 1 && <td className="px-4 py-3" colSpan="2">
                              <button onClick={() => sessionComplete(el, 'complete', 'session')} style={{ padding: '5px 10px', background: '#29aefb', color: '#fff', fontSize: '12px', width: '120px' }}>Mark as Complete</button>
                            </td>}
                          </tr>
                        ))
                          :
                          <tr colSpan="8">
                            <td colSpan={8}>
                              <div className="w-full text-center">
                                <NoData />
                              </div>
                            </td>
                          </tr>
                      }

                    </> : null}
                  </>
                ))
                :

                Object.keys(courseData).map((item, index) => (
                  <>
                    {console.log("itemitemitem else", item, "courseDatacourseDatacourseDatacourseDatacourseData", courseData)}
                    <tr key={index} className="border-b text-sm">
                      <td className="px-4 py-3">
                        <button onClick={(event) => handleEpandRow(item, index)} style={{ padding: '5px 10px', background: '#ddd', color: '#111', fontSize: '12px', borderRadius: '5px' }}>
                          {courseData[item][0].expand ? <FiEye
                            style={{ fontSize: 18 }}
                            className="cursor-pointer"
                          /> :
                            <FiEyeOff
                              style={{ fontSize: 18 }}
                              className="cursor-pointer"
                            />}
                        </button>
                      </td>
                      <td className="px-4 py-3">{courseData[item][0]?.course?.name}</td>
                      <td className="px-4 py-3">{item}</td>
                      <td className="px-4 py-3">{courseData[item][0].users.length}</td>
                      <td className="px-4 py-3 ">
                        {courseData[item][0]?.subcourse?.price}
                      </td>
                      <td className="px-4 py-3 ">
                        {courseData[item][0] && courseData[item][0].subcourse && courseData[item][0].subcourse.price ? courseData[item][0].subcourse.price * courseData[item][0]?.users.length * 0.65: 0}
                      </td>
                      <td className="px-4 py-3 ">
                        {courseData[item][0]?.subcourse?.status}
                      </td>
                      {filter == 'incomplete' &&
                        <td className="px-4 py-3 ">
                          <button onClick={() => sessionComplete(courseData[item][0].subcourse, 'complete', 'subcourse', courseData[item][0] && courseData[item][0].subcourse && courseData[item][0].subcourse.price ? courseData[item][0].subcourse.price * courseData[item][0]?.users.length * 0.65: 0)} style={{ padding: '5px 10px', background: '#29aefb', color: '#fff', fontSize: '12px', width: '120px' }}>Mark as Complete</button>
                        </td>
                      }
                    </tr>

                    {courseData[item][0].expand ? <>
                      <tr rowSpan="9">
                        <th></th>
                        <th colSpan="3" className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                          Session Name
                        </th>
                        <th colSpan="3" className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                          Status
                        </th>
                        {filter == 'incomplete' && <th colSpan="2" className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                          Action
                        </th>}
                      </tr>
                      {
                        courseData[item].length > 0 ?
                          courseData[item].map((el, index) => (
                            <tr colSpan="8" key={el._id} className="border-b text-sm">
                              <td onClick={() => setValue(el)} className="px-4 py-3"></td>
                              <td onClick={() => setValue(el)} className="px-4 py-3" colSpan="3">{el.session.name}</td>
                              <td onClick={() => setValue(el)} className="px-4 py-3" colSpan="3">{el.status}</td>
                              {filter == 'incomplete' && <td className="px-4 py-3" colSpan="2">
                                <button onClick={() => sessionComplete(el, 'complete', 'session')} style={{ padding: '5px 10px', background: '#29aefb', color: '#fff', fontSize: '12px', width: '120px' }}>Mark as Complete</button>
                              </td>}
                            </tr>
                          ))
                          :
                          <tr colSpan="8">
                            <td colSpan={8}>
                              <div className="w-full text-center">
                                <NoData />
                              </div>
                            </td>
                          </tr>
                      }

                    </> : null}
                  </>

                ))
            }

            {/*  */}

          </tbody>}

          {((filter == "incomplete" && courseData && courseData.length == 0) || (filter == "complete" && courseData && Object.keys(courseData)?.length == 0)) && <tbody>
            <tr>

              <td colSpan={8}>
                <div className="w-full text-center">
                  <NoData />
                </div>
              </td>
            </tr>

          </tbody>}
        </table>
      </div>
    </div>
  );
};

export default InstructorComponent(EnrolledInstructor);