import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../Components/TopBar";
import CartItem from "../Components/CartItem";
import { getCart } from "../Redux/Actions/CartAction";
import Checkout from "../Components/Checkout";
import Button from "../Components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../config";
import axios from "axios";

const Failed = () => {
  const id = useParams().transactionId;
  // alert(id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState([]);
  // const cartData = useSelector((state) => state.CartReducer.cart);

  const getOrder = async() =>{
    axios.get(BASE_URL+'api/order/transaction/'+id).then(async(response) => {
      console.log("response", response);
      if(response.data){
        setOrder(response.data)
      }
    })
    .catch((error) => {
        console.log(error);
    })
  };

  useEffect(() => {
    getOrder(id);
  }, [id]);
  return (
    <>
      <div className="container min-h-screen md:px-6 p-4 tracking-wider mx-auto">
        <TopBar title="Payment Failed" />
        <section className="p-6 mb-8  flex flex-col items-center rounded-md shadow-md">
            <div className="w-[250px] md:w-[350px]">
              <img src="/Assets/cart.png" alt="cart" className="w-full" />
            </div>
            <p className=" sm:text-base  text-sm my-3 text-slate-600 text-center  w-full">
              Your Payment failed <small>Please try later</small>
            </p>
            <Button event={() => navigate("/cart")} title="Cart" />
          </section>
      </div>
    </>
  );
};

export default Failed;
