import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import CourseCard from "../Components/CourseCard";
import Marquee from "react-fast-marquee";
import Heading from "../Components/Heading";
import {
  getPopularCourse,
  getTrendingCourse,
  getCourse,

} from "../Redux/Actions/CourseAction";
import InstructorLogin from "./Instructor/InstructorLogin";
import Register from "./Instructor/IntructorRegister";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { INSTRUCTOR_TOKEN } from "../config";
import { getInstructor } from "../Redux/Actions/InstructorAction";
import { getLanguage } from "../Redux/Actions/LanguageAction";
import { GetCategory } from "../Redux/Actions/CategoryAction";

// responsive to testimonial
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 564, min: 0 },
    items: 1,
  },
};

const Home = () => {
  const [step, setStep] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [showForm1, setShowForm1] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const courses = useSelector((state) => state.CourseReducer.courses);
  const popularCourses = useSelector(
    (state) => state.CourseReducer.popularCourses
  );
  const trendingCourses = useSelector(
    (state) => state.CourseReducer.trendingCourses
  );
  const courses = useSelector(
    (state) => state.CourseReducer.courses.length
  )
  const instructors = useSelector(
    (state) => state.InstructorReducer.instructors.length
  )

  const languages = useSelector(
    (state) => state.LanguageReducer.languages.length
  )
  const categories = useSelector(
    (state) => state.CategoryReducer.categories.length
  )
  // const loading = useSelector((state) => state.CourseReducer.loading);
  const userToken = localStorage.getItem("userToken|VST");
  const insToken = localStorage.getItem("instructorToken|VST");

  useEffect(() => {
    dispatch(getPopularCourse());
    dispatch(getTrendingCourse());
    dispatch(getCourse());
    dispatch(getInstructor());
    dispatch(getLanguage());
    dispatch(GetCategory());
    if (showForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showForm, dispatch]);

  return (
    <>
      {/* Hero */}
      <section
        className="w-full border-b bg-center mb-[1rem] bg-cover h-[18rem] sm:h-[22rem] md:h-[26rem] lg:h-[31rem] xl:h-[10rem]"
        style={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80)",
        }}
      >
        <div className="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-60">
          <div className="text-center">
            <h1 className="text-2xl mb-4 font-semibold text-white uppercase lg:text-3xl">
              Nurturing Value <span className="text-color">Education</span>
            </h1>
            <h1 className="text-2xl mb-4 font-semibold text-white uppercase lg:text-3xl">
              Enlightening experience for every <span className="text-color">Student</span>
            </h1>
            <div className="flex justify-start gap-3">
              {!userToken && (
                <Button title="Do you want to be an enlightened student? Get Stated." event={() => navigate("/register")} />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Become An Instructor */}
      {!insToken && (
        <section className={`mx-auto mb-[1rem] `}>
          <div className=" relative h-[18rem] sm:h-[22rem] md:h-[26rem] lg:h-[31rem] xl:h-[10rem] flex  gap-4 items-center">
            <img
              src="https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              className="object-cover h-full object-center  sm:object-end w-full"
              alt="instruct"
            />
            <div className="absolute  bg-[rgb(0,0,0,0.5)] h-full  flex flex-col justify-center w-full ">
              <div className="container mx-auto  md:px-6 px-4 ">
                <div className="flex flex-col gap-1">
                  <div className="">
                    {/* <h1 className="text-2xl sm:text-3xl text-white capitalize xl:text-5xl lg:text-4xl ">
                      Do you want to be an Instructor ?
                    </h1> */}
                  </div>
                  <p className="text-yellow-300 lg:text-xl  sm:text-base text-sm tracking-wider md:text-lg  ">
                  <Marquee>ज्ञातिभिर्वण्ट्यते नैव चौरेणापि न नीयते। दानेन न क्षयं याति विद्यारत्नं महाधनम्।।</Marquee>
                  </p>
                  <p className="text-blue-100 lg:text-xl  sm:text-base text-sm tracking-wider md:text-lg  ">
                    Around the world, students are enriching their knowledge, starting new careers, and progressing in their fields.
                  </p>
                  <div className="flex justify-between">
                    <div className="flex justify-start gap-5 text-red-500">
                      <Button
                        title="Do you want to be an Instructor? Register here."
                        event={() => setShowForm1(true)}

                      />

                      <Button
                        title="instructor login"
                        event={() => setShowForm(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Most Popular */}
      <div className="container mb-[1rem] mx-auto  p-4">
        <div className="flex gap-1 mb-1 justify-between items-center">
          <Heading title="Popular Courses" />
          {/* <Button 
            event={() =>
              navigate("/popular/courses", { state: "All Popular Courses" })
            }
            title="View All"
          /> */}
        </div>
        <br />
        <div className="flex flex-wrap gap-y-1 -m-4 ">
          {popularCourses.slice(0, 10).map((item) => {
            return <CourseCard key={item._id} data={item} />;
          })}
        </div>
      </div>

      {/* Features */}
      <section className=" mb-[1rem]  bg-orange-300">
        <div className="container px-4 py-1  mx-auto">
          <div className="grid grid-cols-1 text-center md:text-left  gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="">
              <svg
                className="w-8 h-8 mx-auto md:mx-0"
                viewBox="0 0 30 30"
                fill="none"
              >
              </svg>

              <h1 className="mt-4 text-xl font-semibold text-gray-800 dark:text-gray-800">
                Variety of Subjects
              </h1>
              <p className="mt-2 text-gray-500 dark:text-gray-500">
                Our platform offers a wide range of courses on a variety of subjects. From programming to competitive exam preparation, from academics to digital marketing, from data science, cloud computing to corporate trainings, there's something for everyone.
              </p>
            </div>

            <div>
              <svg
                className="w-8 h-8 mx-auto md:mx-0"
                viewBox="0 0 30 30"
                fill="none"
              >
              </svg>

              <h1 className="mt-4 text-xl font-semibold text-gray-800">
                Minimum Class Strength
              </h1>

              <p className="mt-2 text-gray-500 ">
                Every session our platform provides each student with more individual attention. Instructors have more manageable workloads and spends more time in one on one interaction with students.
              </p>
            </div>

            <div>
              <svg
                className="w-8 h-8 mx-auto md:mx-0"
                viewBox="0 0 30 30"
                fill="none"
              >
              </svg>

              <h1 className="mt-4 text-xl font-semibold text-gray-800 ">
                Languages Support
              </h1>

              <p className="mt-2 text-gray-500 ">
                We believe a teacher at any remote location can not only enlighten the knowledge of a local student residing nearby but also provide value education to student in remote location and that is reason we support a varieties of languages.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Trending Courses */}
      <div className="container mb-[1rem]  mx-auto p-4">
        <div className="flex gap-2 mb-2 justify-between items-center">
          <Heading title="Trending Courses" />
          {/* <Button
            event={() =>
              navigate("/trending/courses", { state: "All Trending Courses" })
            }
            title="View All"
          /> */}

        </div>
        <br />
        <div className="flex gap-y-5 flex-wrap -m-4">
          {trendingCourses.slice(0, 10).map((item) => {
            return <CourseCard key={item._id} data={item} />;
          })}
        </div>
      </div>

      {/* Testimonials 
      <section className="relative mb-[4rem] overflow-hidden">
        <div className="container mx-auto px-4">
          <Heading title="What are students saying?" />
          <Carousel
            autoPlay
            autoPlaySpeed={2000}
            infinite
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            showDots={true}
            responsive={responsive}
          >
            {Array(4)
              .fill("")
              .map((i, index) => {
                return (
                  <div className="bg-white">
                    <div className="container xl:max-w-7xl mx-auto px-4 py-5 lg:px-8">
                      <div className="relative">
                        <div className="absolute top-0 right-0 text-9xl -mt-5 text-gray-200 opacity-75 font-serif">
                          “
                        </div>
                        <div className="relative text-center">
                          <div className="mb-3">
                            <div className="inline-flex items-center text-yellow-500 bg-orange-100 px-2 py-1 rounded-lg">
                              <svg
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hi-solid hi-star inline-block w-5 h-5"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hi-solid hi-star inline-block w-5 h-5"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hi-solid hi-star inline-block w-5 h-5"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hi-solid hi-star inline-block w-5 h-5"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                              <svg
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hi-solid hi-star inline-block w-5 h-5"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>
                          </div>
                          <blockquote className="lg:w-2/3 xl:w-1/2 mx-auto">
                            <p className="font-semibold text-xl leading-8 mb-5">
                              I’ve been using pixelcave themes for years. The
                              code is consistently high-quality and pleasant to
                              work with, so I highly recommended them.
                            </p>
                            <footer className="space-y-4">
                              <a
                                href="#"
                                className="block mx-auto rounded-full overflow-hidden w-20 h-20 transform transition ease-out duration-150 border-2 border-gray-50 hover:border-white hover:shadow-md hover:scale-125 active:border-gray-50 active:shadow-sm active:scale-110"
                              >
                                <img
                                  src="https://cdn.tailkit.com/media/placeholders/avatar-iFgRcqHznqg-160x160.jpg"
                                  alt="Avatar Photo"
                                />
                              </a>
                              <div>
                                <a
                                  href="#"
                                  className="text-lg font-semibold text-blue-600 hover:text-blue-200"
                                >
                                  John Smith
                                </a>
                                <div className="mx-auto w-10 h-1 my-1 rounded-lg bg-blue-200" />
                                <p className="text-gray-500 font-medium">
                                  Founder and Lead Developer
                                </p>
                              </div>
                            </footer>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </section> */}

      {/* Stats */}
      <div className="bg-blue-300">
        <div className="container xl:max-w-7xl mx-auto px-0 py-1 lg:px-8 ">
          <div className="grid grid-cols-1 sm:grid-cols-4 text-center divide-y sm:divide-y-0 sm:divide-x">
            <dl className="space-y-1 p-4">
              <dt className="text-4xl font-extrabold">{courses}</dt>
              <dd className="text-sm uppercase tracking-wide font-semibold text-color">
                Courses
              </dd>
            </dl>
            <dl className="space-y-1 p-4">
              <dt className="text-4xl font-extrabold">{languages}</dt>
              <dd className="text-sm uppercase tracking-wide font-semibold text-color">
                Languages
              </dd>
            </dl>
            <dl className="space-y-1 p-4">
              <dt className="text-4xl font-extrabold">{instructors}</dt>
              <dd className="text-sm uppercase tracking-wide font-semibold text-color">
                Instructor
              </dd>
            </dl>
            <dl className="space-y-1 p-4">
              <dt className="text-4xl font-extrabold">{categories}</dt>
              <dd className="text-sm uppercase tracking-wide font-semibold text-color">
                Categories
              </dd>
            </dl>
          </div>
        </div>
      </div>

      {/* ------------ Instructor Popup------------- */}
      {showForm && (
        <div
          id="showForm"
          className="fixed top-0 overflow-hidden z-30 left-0 flex justify-center items-center h-screen bg-[rgb(0,0,0,0.8)] w-full"
        >
          <div className="bg-white lg:w-1/2 md:w-2/3 sm:w-3/4 w-5/6 rounded p-4">
            {step === 0 ? (
              <InstructorLogin
                handleStep={() => setStep(1)}
                event={() => {
                  setShowForm(false);
                  setStep(0);
                }}
              />
            ) : (
              <Register
                handleStep={() => setStep(0)}
                event={() => {
                  setShowForm1(false);
                  setStep(0);
                }}
              />
            )}
          </div>
        </div>
      )}





      {showForm1 && (
        <div
          id="showForm"
          className="fixed top-0 overflow-hidden z-30 left-0 flex justify-center items-center h-screen bg-[rgb(0,0,0,0.8)] w-full"
        >
          <div className="bg-white lg:w-1/2 md:w-2/3 sm:w-3/4 w-5/6 rounded p-4">
            {step === 0 ? (
              <Register
                handleStep={() => setStep(1)}
                event={() => {
                  setShowForm1(false);
                  setStep(0);
                }}
              />
            ) : (
              <  InstructorLogin
                handleStep={() => setStep(0)}
                event={() => {
                  setShowForm(false);
                  setStep(0);
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
