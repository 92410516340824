import { toast } from "react-hot-toast";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import {
  changePass,
  editProfileData,
  userProfile,
  editUserProfilePicture
} from "../Redux/Actions/AuthAction";
import Button from "../Components/Button";
import UserData from "../Components/UserData";
import ApiLoader from "../Components/ApiLoader";
import UserComponent from "../Components/UserComponent";
import { USER_ID, USER_TOKEN } from "../config";

const BASEURL = process.env.REACT_APP_BASE_URL;

const UserProfile = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState();
  const [show1, setShow1] = useState();
  const [edit, setEdit] = useState();
  const [btnloading, setBTNLoading] = useState();
  const userId = localStorage.getItem("userData|VST");
  const [img, setImg] = useState("/Assets/user.png");
  const [profileData, setProfileData] = useState({});
  const [profileData1, setProfileData1] = useState({});
  const [multipleValue, setMultipleValue] = useState();
  // const userData = UserData();
  let userData = useSelector((state) => state.AuthReducer.userData);

  // handleChange
  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.files ? e.target.files[0] : e.target.value,
    });
  };

  const handleUserProfileChange = (e) => {
    const { name, value } = e.target;
    const inputValue = name === 'yearsofexp' ? Math.max(0, parseInt(value, 10)) : value;
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.files ? e.target.files[0] : e.target.value,
    });
  };


  const updateUserPassword = (event) => {
    event.preventDefault();

    if (profileData.password && profileData.prevpassword) {
      const payload = {
        password: profileData.password,
        prevpassword: profileData.prevpassword,
        token: localStorage.getItem("userToken|VST"),
        id: localStorage.getItem("userID|VST"),
      };
      setBTNLoading(true);
      dispatch(
        changePass(
          payload,
          () => setProfileData({ password: "", prevpassword: "" }),
          () => setBTNLoading(false)
        ));
    }
  };
  const updateUserProfileData = (event) => {
    event.preventDefault();
    const payload = {
      token: localStorage.getItem("userToken|VST"),
      id: localStorage.getItem("userID|VST"),
      data: profileData, profileData1
    };

    dispatch(
      editProfileData(
        payload, localStorage.getItem("userID|VST"),
        () => setProfileData({ password: "", prevpassword: "" }),
        () => setBTNLoading(false))
    );

  };

  // handleChange profileImage
  const handleProfileChange = (event) => {
    if (event.target.files[0]) {
      let fd = new FormData();
      setImg(URL.createObjectURL(event.target.files[0]));
      fd.append("profile", event.target.files[0]);
      dispatch(editProfileData(fd, USER_ID));
    }
  };
  const UserProfilePicturehange = (e) => {
    setProfileData1({
      ...profileData1,
      [e.target.name]: e.target.files
        ? e.target.files.length > 1
          ? multipleData(e.target.files)
          : e.target.files[0]
        : e.target.value,
    });
  };

  const multipleData = (data) => {
    let file = Object.values(data);
    setMultipleValue(file);
  };
  const updateUserPicture = () => {
    const fd = new FormData();
    fd.append("userpic", profileData1.userpic);
    const payload = {
      userpic: profileData1.userpic,
      token: localStorage.getItem("userToken|VST"),
      id: localStorage.getItem("userID|VST"),
    };
    dispatch(
      editUserProfilePicture(fd, localStorage.getItem("userID|VST"))
    );
  }
  useEffect(() => {
    dispatch(userProfile(userId));
  }, [dispatch, userId]);
  return (
    <>
      <div className="">
        <div className="bg-blue-200  rounded-sm p-4 tracking-wider ">
          <section className="grid md:grid-cols-2 items-center flex-col gap-5  py-8">
            <div className="">
              <figure className="w-40 border-color relative h-40 shadow rounded-full bg-white flex justify-center items-center mx-auto">
                <label htmlFor="userpic">
                  <span className="text-2xl cursor-pointer pb-1.5 flex justify-center items-center w-6 h-6 bg-color absolute bottom-2 right-2 rounded-full text-white">
                    +
                  </span>
                </label>
                <img
                  //src={img}
                  src={`${BASEURL}${userData?.userpic}`}
                  // alt="user"
                  className="w-full h-full rounded-full object-cover"
                />
              </figure>
              <input
                id="userpic"
                type="file"
                name="userpic"
                defaultValue={edit ? profileData1.userpic : ""}
                onChange={UserProfilePicturehange}
                className="hidden"
              />
            </div>
            <div className="gap-2 grid mx-8 ">
              <div className="flex gap-3">
                <span className=" tracking-wider text-gray-600 font-medium">
                  Name :
                </span>
                {userData?.name}
              </div>
              <div className="flex gap-3">
                <span className=" tracking-wider text-gray-600 font-medium">
                  Email :
                </span>
                {userData?.email}
              </div>
              <div className="flex gap-3">
                <span className=" tracking-wider text-gray-600 font-medium">
                  Phone :
                </span>
                {userData?.phone}
              </div>
            </div>
          </section>
          <div className="flex items-center justify-end">
            <div className="">
              {(
                <Button event={updateUserPicture} title=" Update Profile Picture" />
              )}
            </div>
          </div>
        </div>
        {/* 2 */}
        <div className="bg-blue-200  rounded-sm p-4 tracking-wider my-6 md:my-10">
          <h3 className="text-blue-bold-900 underline pb-6">Update Password</h3>
          <div className="grid md:grid-cols-2 grid-cols-1  gap-x-8">
            {/* Pofile */}
            {/* <div className="flex  flex-col gap-2">
              <label htmlFor="profile" className="text-xs">
                Profile
              </label>
              <input
                id="profile"
                name="profile"
                type="file"
                accept="image/*"
                maxLength={10}
                onChange={handleChange}
                className="bg-white border-indigo-400 tracking-widest  text-sm rounded-sm shadow-md outline-none py-1.5 px-2 border"
              />
            </div> */}
            {/* Current Password */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-xs">
                Current Password
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  id="Current"
                  name="prevpassword"
                  type={show ? "text" : "password"}
                  onChange={handleUserProfileChange}
                  value={profileData?.prevpassword}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
                <span
                  onClick={() => setShow(!show)}
                  className="text-xl ml-2 text-slate-800 cursor-pointer"
                >
                  {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </div>
            {/* Password */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Password" className="text-xs">
                New Password
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  id="Password"
                  name="password"
                  value={profileData?.password}
                  onChange={handleUserProfileChange}
                  type={show1 ? "text" : "password"}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
                <span
                  onClick={() => setShow1(!show1)}
                  className="text-xl ml-2 text-slate-800 cursor-pointer"
                >
                  {show1 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="">
              {(
                <Button event={updateUserPassword} title="Change Password" />
              )}
            </div>
          </div>
        </div>
        <div className="bg-blue-200  rounded-sm p-4 tracking-wider my-6 md:my-10">
          <h3 className="text-blue-900 underline pb-6">Update Profile</h3>
          <div className="grid md:grid-cols-2 grid-cols-1  gap-x-8">
            {/* Address Line 1 */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Address Line 1" className="text-orange">
                Address Line 1::<b>{userData?.addrline1}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="addrline1"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? profileData.addrline1 : ""}
                  onChange={handleUserProfileChange}
                  value={profileData.addrline1}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>
            {/* Address Line 2 */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Password" className="text-orange">
                Address Line 2::<b>{userData?.addrline2}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="addrline2"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? profileData.addrline2 : ""}
                  onChange={handleUserProfileChange}
                  value={profileData.addrline2}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* City */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                City::<b>{userData?.city}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="city"
                  type="text"
                  maxLength={25}
                  defaultValue={edit ? profileData.city : ""}
                  onChange={handleUserProfileChange}
                  value={profileData.city}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* State */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                State::<b>{userData?.state}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="state"
                  type="text"
                  maxLength={25}
                  defaultValue={edit ? profileData.state : ""}
                  onChange={handleUserProfileChange}
                  value={profileData.state}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Pincode */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                PinCode::<b>{userData?.pincode}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="pincode"
                  type="number"
                  defaultValue={edit ? (Number(profileData.pincode) ? profileData.pincode : 0) : ""}
                  onChange={handleUserProfileChange}
                  value={profileData.pincode}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Batch Code */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Batch Code ::<b>{userData?.batchcode}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="batchcode"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? profileData.batchcode : ""}
                  onChange={handleUserProfileChange}
                  value={profileData.batchcode}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>

            {/* Highest Educational Qualification */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Highest Education::<b>{userData?.highestedu}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="highestedu"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? profileData.highestedu : ""}
                  onChange={handleUserProfileChange}
                  value={profileData.highestedu}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>


            {/* Subject Specialization */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-orange">
                Subject Interest:<b>{userData?.subspec}</b>
              </label>
              <div className="p-2 mb-4 bg-white border rounded flex items-center gap-3">
                <input
                  name="subspec"
                  type="text"
                  maxLength={65}
                  defaultValue={edit ? profileData.subspec : ""}
                  onChange={handleUserProfileChange}
                  value={profileData.subspec}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="">
              {btnloading ? (
                <ApiLoader />
              ) : (
                <Button event={updateUserProfileData} title="Update Profile" />
              )}
            </div>
          </div>

        </div>





      </div >
    </>
  );
};

export default UserComponent(UserProfile);
