import axios from "axios";
import { BASE_URL } from "../../config";
import {
  GET_CATEGORY,
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_SUCCESS,
  GET_LANGUAGE,
  GET_LANGUAGE_FAILURE,
  GET_LANGUAGE_SUCCESS,
} from "./ActionTypes";

// Get Category
export const GetCategory = () => async (dispatch) => {
  dispatch({ type: GET_CATEGORY });
  try {
    const res = await axios.get(`${BASE_URL}api/category`);
    dispatch({
      type: GET_CATEGORY_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_CATEGORY_FAILURE });
  }
};

// Languages
export const GetLanguages = () => async (dispatch) => {
  dispatch({ type: GET_LANGUAGE });
  try {
    const res = await axios.get(`${BASE_URL}api/language`);
    dispatch({
      type: GET_LANGUAGE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_LANGUAGE_FAILURE });
  }
};
