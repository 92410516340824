import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import CourseCard from "../Components/CourseCard";
import NoData from "../Components/NoData";
import UserComponent from "../Components/UserComponent";
import { BASE_URL, USER_ID } from "../config";
import { getMyEnrollCourse } from "../Redux/Actions/CourseAction";
import axios from 'axios';
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { getStudentMeetingSession } from "../Redux/Actions/SessionAction";
import { userProfile } from "../Redux/Actions/AuthAction";

const Enrolled = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null)
  const [courseData, setCourseData] = useState([]);
  const [object, setObject] = useState({});
  const [filter, setfilter] = useState("incomplete");
  const userToken = localStorage.getItem("userToken|VST");
  const userId = localStorage.getItem("userID|VST");
  // 'complete','incomplete'
  const [loader, setLoader] = useState(true);
  const course = useSelector((state) => state.CourseReducer.course);
  let userData = useSelector((state) => state.AuthReducer.userData);
  const getBooking = async (id) => {
    const res = await axios.get(`${BASE_URL}api/enrollment/session/user/${id}/${filter}`, {
      headers: {
        token: userToken,
      },
    });
    if (!res.data.error) {
      setCourseData(res.data.data);
      setLoader(false);
    } else {
      setCourseData([]);
      setLoader(false);
    }
  }

  useEffect(() => {
    getBooking(userId);
    dispatch(userProfile(userId));
  }, [userId, filter]);

  // useEffect(() => {
  //   setCourseData(course);
  // }, [course]);



  function handleSelectChange(event) {
    setfilter(event.target.value);
  }

  const setValue = (item) => {
    // setOpen(true);
    setObject(item);
  }

  return (
    <div className="py-0">
      {/* {courseData && courseData?.length === 0 && (
       
      )} */}
      {/* {courseData &&
        courseData?.length !== 0 &&
        courseData?.map((e, i) => {
          return (
            <React.Fragment key={e._id}>
              <CourseCard enrolled={true} data={e} />
            </React.Fragment>
          );
        })} */}

      <div className="overflow-x-auto py-5">
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '12px' }}>
          <select style={{ padding: '10px 20px', background: ' #29aefb', color: '#fff', borderRadius: '5px', minWidth: '150px' }} onChange={handleSelectChange} value={filter}>
            <option value="complete">Complete</option>
            <option value="incomplete">Incomplete</option>
          </select>
        </div>
        <table className="table-auto min-w-full text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                Course Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Subcourse Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Session Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Instructor Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Meeting Link
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Status
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Date
              </th>
              {/* <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br">
                Actions
              </th> */}
            </tr>
          </thead>
          {courseData && courseData?.length > 0 && <tbody>

            {courseData.map((item, index) => (
              <tr onClick={() => setValue(item)} key={item._id} className="border-b text-sm">
                <td className="px-4 py-3">{item?.course?.name}</td>
                <td className="px-4 py-3">{item?.subcourse?.name}</td>
                <td className="px-4 py-3">{item?.session?.name}</td>
                <td className="px-4 py-3">{item?.instructor?.name}</td>
                <td className="px-4 py-3 text-red-500 underline">
                  <button
                    onClick={() => dispatch(getStudentMeetingSession({ id: item._id, name:userData?.name}))}
                    className="cursor-pointer"
                  >
                    Join the class
                  </button>
                </td>
                <td className="px-4 py-3 ">
                  {item?.status}
                </td>
                <td className="px-4 py-3 ">
                  {item.updatedAt ? moment(new Date(item.updatedAt)).format("DD/MMM/YYYY hh:mm A") : ""}
                </td>
              </tr>

            ))}

          </tbody>}

          {!loader && courseData && courseData?.length === 0 && <tbody>
            <tr>

              <td colSpan={6}>
                <div className="w-full text-center">
                  <NoData />

                  <Button
                    title="Browse Courses Now"
                    event={() => navigate("/courses")}
                  />
                </div>
              </td>
            </tr>

          </tbody>}
          
          {loader && courseData && courseData?.length === 0 && <tbody>
            <tr>

              <td colSpan={6}>
                <div role="status" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                  <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>

          </tbody>}
        </table>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                  <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                    <button
                      type="button"
                      className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                      <div className="sm:col-span-8 lg:col-span-7">
                        <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{object.name}</h2>

                        <section aria-labelledby="information-heading" className="mt-2">
                          <h3 id="information-heading" className="sr-only">
                            Product information
                          </h3>

                          <p className="text-2xl text-gray-900">{object._id}</p>

                          {/* Reviews */}
                          <div className="mt-6">
                            <h4 className="sr-only">Reviews</h4>
                            <div className="flex items-center">
                              <div className="flex items-center">

                              </div>
                              <p className="sr-only">{object.name} out of 5 stars</p>
                              <a href="#" className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                {object.name} reviews
                              </a>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>

              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    </div>
  );
};

export default UserComponent(Enrolled);
