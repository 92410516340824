import React from "react";
import { BASE_URL } from "../config";


const Content = ({ data }) => {
  return (
    <section className="bg-white my-10 text-gray-900">
      <div className="container   mx-auto">
        <div className="my-4 lg:-mx-6 lg:flex lg:items-center">
          <figure className="lg:w-72 lg:h-72 w-40  md:h-60 h-40  md:w-60 mx-auto rounded-full shadow">
            <img
              src={`${BASE_URL}${data?.image}`}
              //src="https://images.unsplash.com/photo-1526379095098-d400fd0bf935?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80"
              alt={data?.name}
              className="w-full h-full bg-gray-100 rounded-full object-contain"
            />
          </figure>

          <div className=" lg:p-4 mt-3 lg:w-1/2">
            <p className="md:text-sm text-xs text-blue-500 ">
              {data?.category?.name}
            </p>

            <span className="block md:mt-3 mt-2 text-xl sm:text-2xl font-semibold text-gray-800   md:text-3xl">
              {data?.name}
            </span>
            <p className="my-3 text-sm text-gray-700  md:text-sm">
              {data?.description}
            </p>
            <p className=" text-sm text-gray-700 tracking-widest font-semibold">
              <span className="text-gray-500  uppercase tracking-wider font-semibold pr-2">
                Price :
              </span>
              Rs {data?.price}
              /-
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
