import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL, INSTRUCTOR_ID, INSTRUCTOR_TOKEN, USER_TOKEN } from "../../config";
import {
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  FORGOT_PASS_SUCCESS,
  FORGOT_PASS_FAIL,
  FORGOT_PASS,
  UPDATE_PASS,
  UPDATE_PASS_SUCCESS,
  UPDATE_PASS_FAIL,
  CHANGE_PASS,
  CHANGE_PASS_SUCCESS,
  USER_PROFILE,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_PICTURE,
  UPDATE_USER_PROFILE_PICTURE_SUCCESS,
  INSTRUCTOR_PROFILE,
  INSTRUCTOR_PROFILE_SUCCESS,
  INSTRUCTOR_PROFILE_FAIL,
  UPDATE_INSTRUCTOR_PROFILE_SUCCESS,
  UPDATE_INSTRUCTOR_PROFILE,
  UPDATE_INSTRUCTOR_PROFILE_FAILURE,
  UPDATE_INSTRUCTOR_PROFILE_PICTURE,
  UPDATE_INSTRUCTOR_PROFILE_PICTURE_SUCCESS
} from "./ActionTypes";
import { getCourseByInstructor } from "./CourseAction";

// REGISTER
export const UserRegister = (formValues, cb) => async (dispatch) => {
  dispatch({ type: REGISTER });
  try {
    await axios.post(`${BASE_URL}api/auth/sign-up`, formValues).then((res) => {
      if (!res.data.error) {
        toast.success(res.data.message);
        cb();
      } else {
        toast.error(res.data.message);
      }
      dispatch({ type: REGISTER_SUCCESS });
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: REGISTER_FAIL });
  }
};
export const InstructorRegister = (formValues, cb) => async (dispatch) => {
  dispatch({ type: REGISTER });
  try {
    await axios
      .post(`${BASE_URL}api/instructor/sign-up`, formValues)
      .then((res) => {
        if (!res.data.error) {
          cb();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        dispatch({ type: REGISTER_SUCCESS });
      });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: REGISTER_FAIL });
  }
};

// LOGIN
export const UserLogin = (formData, callback, verify) => async (dispatch) => {
  dispatch({ type: LOGIN });
  try {
    const res = await axios.post(`${BASE_URL}api/auth/login`, formData);
    if (res.data.token) {
      localStorage.setItem("userToken|VST", res.data.token);
      localStorage.setItem("userID|VST", res.data.data._id);
      callback("/");
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
      dispatch({
        type: LOGIN_SUCCESS,
      });
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    toast.error(error.response.data.message);
    if (error.response.data.message === "Email not verified") {
      verify(true);
    }
    if (error.response.data.message === "User not found") {
      callback("/register");
    }
  }
};
export const instructorLogin = (formValues, cb, verify) => async (dispatch) => {
  dispatch({ type: LOGIN });
  try {
    await axios
      .post(`${BASE_URL}api/instructor/login`, formValues)
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem("instructorToken|VST", res.data.token);
          localStorage.setItem("instructorID|VST", res.data.data._id);
          toast.success(res.data.message);
          dispatch(getCourseByInstructor(res.data.data._id));
          cb();
        } else {
          toast.error(res.data.message);
          dispatch({ type: LOGIN_SUCCESS });
        }
      });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    toast.error(error.response.data.message);
    if (error.response.data.message === "Email not verified") {
      verify();
    }
  }
};

// FORGOT PASSWORD
export const forgotPass = (formData, callback) => async (dispatch) => {
  dispatch({ type: FORGOT_PASS });
  try {
    const res = await axios.post(
      `${BASE_URL}api/auth/forgot-password`,
      formData
    );
    if (res.data.error) {
      toast.error(res.data.message);
      dispatch({ type: FORGOT_PASS_SUCCESS });
    } else {
      toast.success(res.data.message);
      dispatch({ type: FORGOT_PASS_SUCCESS });
      callback();
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: FORGOT_PASS_FAIL });
  }
};

// FORGOT PASSWORD
export const forgotInsPass = (formData, callback) => async (dispatch) => {
  dispatch({ type: FORGOT_PASS });
  try {
    const res = await axios.post(
      `${BASE_URL}api/auth/forgot-ins-password`,
      formData
    );
    if (res.data.error) {
      toast.error(res.data.message);
      dispatch({ type: FORGOT_PASS_SUCCESS });
    } else {
      toast.success(res.data.message);
      dispatch({ type: FORGOT_PASS_SUCCESS });
      callback();
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: FORGOT_PASS_FAIL });
  }
};

export const updatePass = (formData, callback) => async (dispatch) => {
  dispatch({ type: UPDATE_PASS });
  try {
    const res = await axios.post(
      `${BASE_URL}api/auth/change-password`,
      formData
    );
    if (res.data.error) {
      toast.error(res.data.message);
      dispatch({ type: UPDATE_PASS_SUCCESS });
    } else {
      toast.success(res.data.message);
      dispatch({ type: UPDATE_PASS_SUCCESS });
      callback();
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: UPDATE_PASS_FAIL });
  }
};

// UPDATE PROFILE & CHANGE PASS
export const instructorChangePass =
  (formData, callback, btnLoader) => async (dispatch) => {
    dispatch({ type: CHANGE_PASS });
    try {
      const res = await axios.put(
        `${BASE_URL}api/auth/change-ins-password`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            instructortoken: formData.instructorToken,
          },
        }, formData.id,
      );
      toast.success(res.data.message);
      dispatch({ type: CHANGE_PASS_SUCCESS });
      callback();
      btnLoader();
    } catch (error) {
      btnLoader();
      toast.error(error.response.data.message);
      dispatch({ type: UPDATE_PASS_FAIL });
    }
  };

// UPDATE PROFILE & CHANGE PASS
export const changePass =
  (formData, callback, btnLoader) => async (dispatch) => {
    dispatch({ type: CHANGE_PASS });
    try {
      const res = await axios.put(
        `${BASE_URL}api/auth/change-password`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: formData.token,
          },
        },formData.id,
      );
      toast.success(res.data.message);
      dispatch({ type: CHANGE_PASS_SUCCESS });
      callback();
      btnLoader();
    } catch (error) {
      btnLoader();
      toast.error(error.response.data.message);
      dispatch({ type: UPDATE_PASS_FAIL });
    }
  };
export const editProfileData = (payload, id) => (dispatch) => {
  dispatch({ type: UPDATE_USER_PROFILE });

  let url = `${BASE_URL}api/user/${id}`;
  try {
    const res = axios.patch(url, payload.data, {
      headers: {
        token: payload.token,
      },
    });
    console.log(res);
    toast.success("Profile is successfully updated.");
    dispatch({
      type: UPDATE_USER_PROFILE_SUCCESS,
      // payload: res.data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: UPDATE_USER_PROFILE_FAILURE });
  }
};

export const editUserProfilePicture = (payload, id) => (dispatch) => {
  dispatch({ type: UPDATE_USER_PROFILE_PICTURE });
  let url = `${BASE_URL}api/user/userpic/${id}`;
  try {
    const res = axios.patch(url, payload, {
      headers: {
        // token: payload.token,
        token: localStorage.getItem("userToken|VST")
      },
    });
    console.log(res);
    toast.success("User profile picture is updated.");
    dispatch({
      type: UPDATE_USER_PROFILE_PICTURE_SUCCESS,
      
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: UPDATE_USER_PROFILE_FAILURE });
  }
};

export const editInstructorProfileData = (payload, id) => (dispatch) => {
  dispatch({ type: UPDATE_INSTRUCTOR_PROFILE });
  let url = `${BASE_URL}api/instructor/${id}`;
  try {
    const res = axios.patch(url, payload.data, {
      headers: {
        instructortoken: payload.instructorToken,
      },
    });
    console.log(res);
    toast.success("Instructor profile is updated.");
    dispatch({
      type: UPDATE_INSTRUCTOR_PROFILE_SUCCESS,
      //payload: res.data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: UPDATE_INSTRUCTOR_PROFILE_FAILURE });
  }
};

export const editInstructorProfileCertsData = (payload, id) => (dispatch) => {
  dispatch({ type: UPDATE_INSTRUCTOR_PROFILE });
  let url = `${BASE_URL}api/instructor/certs/${id}`;
  try {
    const res = axios.patch(url, payload, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST")
      },
    });
    console.log(res);
    toast.success("Instructor certificate is updated.");
    dispatch({
      type: UPDATE_INSTRUCTOR_PROFILE_SUCCESS,
      //payload: res.data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: UPDATE_INSTRUCTOR_PROFILE_FAILURE });
  }
};

export const editInstructorProfilePicture = (payload, id) => (dispatch) => {
  dispatch({ type: UPDATE_INSTRUCTOR_PROFILE_PICTURE });
  let url = `${BASE_URL}api/instructor/profpic/${id}`;
  try {
    const res = axios.patch(url, payload, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST")
      },
    });
    console.log(res);
    toast.success("Instructor profile picture is updated.");
    dispatch({
      type: UPDATE_INSTRUCTOR_PROFILE_PICTURE_SUCCESS,
      
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: UPDATE_INSTRUCTOR_PROFILE_FAILURE });
  }
};

// get user by id
export const userProfile = (id) => async (dispatch) => {
  dispatch({ type: USER_PROFILE });
  try {
    const res = await axios.get(`${BASE_URL}api/user/${id}`, {
      headers: {
        token: USER_TOKEN,
      },
    });
    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({ type: USER_PROFILE_FAIL });
  }
};

// get instructor by id
export const instructorProfile = (id) => async (dispatch) => {
  dispatch({ type: INSTRUCTOR_PROFILE });
  try {
    const res = await axios.get(`${BASE_URL}api/instructor/${id}`, {
      headers: {
        instructortoken: INSTRUCTOR_TOKEN,
      },
    });

    dispatch({
      type: INSTRUCTOR_PROFILE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({ type: INSTRUCTOR_PROFILE_FAIL });
  }
};
