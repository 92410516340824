import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL, USER_ID } from "../../config";
import {
  ADD_CART,
  ADD_CART_FAILURE,
  ADD_CART_SUCCESS,
  DELETE_BY_SUBCOURSE,
  DELETE_BY_SUBCOURSE_FAILURE,
  DELETE_BY_SUBCOURSE_SUCCESS,
  DELETE_CART,
  DELETE_CART_FAILURE,
  DELETE_CART_SUCCESS,
  GET_CART,
  GET_CART_FAILURE,
  GET_CART_SUCCESS,
} from "./ActionTypes";

// Get Cart
export const getCart = () => async (dispatch) => {
  dispatch({ type: GET_CART });
  try {
    const userID = localStorage.getItem("userID|VST");
    const res = await axios.get(`${BASE_URL}api/cart/user/${userID}`, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    });
    const data = res.data.data;

    dispatch({
      type: GET_CART_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: GET_CART_FAILURE });
  }
};

// Add Cart
export const addCart = (payload, callback) => async (dispatch) => {
  dispatch({ type: ADD_CART });
  try {
    // done bro
    const res = await axios.post(`${BASE_URL}api/cart`, payload, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    });
    if (res.status === 200) {
      toast.success(res.data.message);
      
      if (res.data.message !== "Cart already exists") {
        dispatch({
          type: ADD_CART_SUCCESS,
          payload: { data: res.data, count: res.data.data.count },
        });
      }
      setTimeout(2000);
      return callback();
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ADD_CART_FAILURE });
  }
};

// Delete Cart
export const deleteCartItem = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CART });
    try {
      const res = await axios.post(`${BASE_URL}api/cart/course`, payload, {
        headers: {
          token: localStorage.getItem("userToken|VST"),
        },
      });
      toast.success(res.data.message);
      dispatch({
        type: DELETE_CART_SUCCESS,
        payload: payload.course,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_CART_FAILURE });
    }
  };
};

// Delete cart by subcourse
export const deleteBySbCourse = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_BY_SUBCOURSE });
  try {
    let res = await axios.post(
      `${BASE_URL}api/cart/subcourse`,
      payload.send.data,
      {
        headers: {
          token: localStorage.getItem("userToken|VST"),
        },
      }
    );
    if (res.status === 200) {
      toast.success("Course is deleted.");
      dispatch({
        type: DELETE_BY_SUBCOURSE_SUCCESS,
        payload: payload.send.data.subcourse,
      });
      return payload.send.callback();
    }
  } catch (error) {
    dispatch({ type: DELETE_BY_SUBCOURSE_FAILURE });
  }
};
