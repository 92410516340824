import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../config";
import {
  ADD_SUBCOURSE,
  ADD_SUBCOURSE_FAILURE,
  ADD_SUBCOURSE_SUCCESS,
  DELETE_SUBCOURSE,
  DELETE_SUBCOURSE_FAILURE,
  GET_SUBCOURSE,
  GET_SUBCOURSE_FAILURE,
  GET_SUBCOURSE_SUCCESS,
  UPDATE_SUBCOURSE,
  UPDATE_SUBCOURSE_FAILURE,
  UPDATE_SUBCOURSE_SUCCESS,
  DOWNLOAD_MATERIALS,
  DOWNLOAD_MATERIALS_FAILURE,
} from "./ActionTypes";

export const getSubCourse = (id) => async (dispatch) => {
  dispatch({ type: GET_SUBCOURSE });
  let url = `${BASE_URL}api/subcourse/course/${id}`;
  try {
    const res = await axios.get(url);
    const data = res.data;
    if (res.data.status === 200) {
      dispatch({
        type: GET_SUBCOURSE_SUCCESS,
        payload: data.data,
      });
      return data.data;
    }
  } catch (error) {
    dispatch({ type: GET_SUBCOURSE_FAILURE });
  }
};

export const addSubCourse = (payload, close) => async (dispatch) => {
  dispatch({ type: ADD_SUBCOURSE });
  let url = `${BASE_URL}api/subcourse`;
  try {
    let res = await axios.post(url, payload, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    if (res.data.message === "Wrong amount entered by a premium user." || 
        res.data.message === "Certificate verification is in progress, subcourse addition will be activated soon.") 
    {
      toast.success(res.data.message);
    } else if (res.data.message === "Subcourse created successfully") {
      toast.success(res.data.message);
      close();
      dispatch({
        type: ADD_SUBCOURSE_SUCCESS,
        payload: res.data.data,
      });
  }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: ADD_SUBCOURSE_FAILURE });
  }
};

export const updateSubCourse = (payload, id, close) => async (dispatch) => {
  dispatch({ type: UPDATE_SUBCOURSE });
  let url = `${BASE_URL}api/subcourse/${id}`;
  try {
    let res = await axios.patch(url, payload, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    if (!res.data.error) {
      toast.success(res.data.message);
      dispatch({
        type: UPDATE_SUBCOURSE_SUCCESS,
        payload: res.data.data,
      });
      close();
    }
  } catch (error) {
    dispatch({ type: UPDATE_SUBCOURSE_FAILURE });
  }
};

export const deleteSubCourse = (id, courId) => async (dispatch) => {
  dispatch({ type: DELETE_SUBCOURSE });
  let url = `${BASE_URL}api/subcourse/${id}`;
  try {
    let res=await axios.delete(url, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    toast.success(res.data.message);
    dispatch(getSubCourse(courId));
  } catch (error) {
    dispatch({ type: DELETE_SUBCOURSE_FAILURE });
  }
};

export const downloadMaterials = (id, file_path, courId) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_MATERIALS });
  let url = `${BASE_URL}api/subcourse/${id}`;
  try {
    await axios.get(url, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    toast.success("File successfully downloaded.");
    dispatch(getSubCourse(courId));
  } catch (error) {
    dispatch({ type: DOWNLOAD_MATERIALS_FAILURE });
  }
};

