import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetLanguages } from "../Redux/Actions/CategoryAction";
// import { MdEmail } from "react-icons/md";

const Footer = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.CategoryReducer.categories);
  const languages = useSelector((state) => state.CategoryReducer.languages);

  useEffect(() => {
    dispatch(GetLanguages());
  }, [dispatch]);

  return (
    <section className="bg-gray-900  ">
      <footer className="text-gray-600 tracking-wide body-font">
        <div className="container px-1 py-1 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <Link
              to="/"
              className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
            >
              <img
                src="/Assets/VST1.png"
                alt="footer_logo"
                className="w-20 h-20"
                
              />
              {/* <span className="ml-3 uppercase text-orange-500 text-lg">
                VerySimilarTest
              </span> */}
            </Link>
            {/* <p className="mt-2 text-sm text-gray-500">
              Nurturing Value Education
            </p> */}
          </div>
          <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
            {/* <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-color tracking-widest text-sm mb-3">
                CATEGORIES
              </h2>
              <nav className="list-none mb-10">
                {categories.map((item) => {
                  return (
                    <li key={item._id}>
                      <span className="text-gray-600 cursor-pointer hover:text-orange-500">
                        {item.name}
                      </span>
                    </li>
                  );
                })}
              </nav>
            </div> */}
            {/* <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-color tracking-widest text-sm mb-3">
                LANGUAGES
              </h2>
              <nav className="list-none mb-10">
                {languages.map((item) => {
                  return (
                    <li key={item._id}>
                      <span className="text-gray-600 cursor-pointer hover:text-orange-500">
                        {item.name}
                      </span>
                    </li>
                  );
                })}
              </nav>
            </div> */}
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <nav className="list-none mb-10">
                <li>
                  <Link
                    to="/about-us"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    Terms & Use
                  </Link>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <nav className="list-none mb-10">
                <li>
                  <Link
                    to="/contact-us"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/investors"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    Investors
                  </Link>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/3 md:w-1/2 w-full px-4">
              <nav className="list-none mb-10">
                <li>
                  <Link
                    to="/cookie-policy"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    Cookie Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/refund-policy"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    Refund and Payment Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/career"
                    className="title-font text-color cursor-pointer hover:text-orange-500"
                  >
                    Careers
                  </Link>
                </li>
              </nav>
            </div>

            {/* <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-color tracking-widest text-sm mb-3">
                CATEGORIES
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <a className="text-gray-600 hover:text-gray-800">
                    First Link
                  </a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">
                    Second Link
                  </a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">
                    Third Link
                  </a>
                </li>
                <li>
                  <a className="text-gray-600 hover:text-gray-800">
                    Fourth Link
                  </a>
                </li>
              </nav>
            </div> */}
          </div>
        </div>
        <div className="bg-gray-200 border-t border-gray-200 py-1">
          <div className="container mx-auto  py-1 px-1 flex flex-wrap flex-col sm:flex-row">
            <p className="text-black-500 text-sm text-center sm:text-left">
              © 2024 StarDataInsights. All Rights Reserved.
              <a className="text-gray-600 ml-1"></a>
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
              <a className="text-gray-500 hover:text-orange-500 cursor-pointer">
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a className="ml-3 text-gray-500 hover:text-orange-500 cursor-pointer">
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
              <a className="ml-3 text-gray-500 hover:text-orange-500 cursor-pointer">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
              <a className="ml-3 text-gray-500 hover:text-orange-500 cursor-pointer">
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </span>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
