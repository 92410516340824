import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../config";
import {
  PLACE_ORDER,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
} from "./ActionTypes";
// Add Cart
export const payment = (payload, callback) => async (dispatch) => {
  dispatch({ type: PLACE_ORDER });
  try {
    const res = await axios.post(`${BASE_URL}api/order`, payload, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    });
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type: PLACE_ORDER_SUCCESS,
        payload: { data: res.data},
      });
    return callback();
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: PLACE_ORDER_FAILURE });
  }
};
