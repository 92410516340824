import AuthReducer from "./AuthReducer";
import CategoryReducer from "./CategoryReducer";
import CourseReducer from "./CourseReducer";
import SubCourseReducer from "./SubCourseReducer";
import SessionReducer from "./SessionReducer";
import WishlistReducer from "./WishlistReducer";
import CartReducer from "./CartReducer";
import PayReducer from "./PayReducer";
import InstructorReducer from "./InstructorReducer";
import LanguageReducer from "./LanguageReducer";
import OrderReducer from "./OrderReducer";

import { combineReducers } from "redux";

const Reducer = combineReducers({
  AuthReducer,
  CategoryReducer,
  CourseReducer,
  SubCourseReducer,
  SessionReducer,
  WishlistReducer,
  CartReducer,
  PayReducer,
  InstructorReducer,
  LanguageReducer,
  OrderReducer
});

export default Reducer;
