import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import CourseCard from "../Components/CourseCard";
import NoData from "../Components/NoData";
import UserComponent from "../Components/UserComponent";
import { BASE_URL, USER_ID } from "../config";
import { getMyEnrollCourse } from "../Redux/Actions/CourseAction";
import { getOrder } from "../Redux/Actions/OrderAction";
import axios from "axios";

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const course = useSelector((state) => state.OrderReducer.order);
  const userID = localStorage.getItem("userID|VST");

  useEffect(() => {
    if (course && course.length > 0) {
      setLoader(false)
    }
  }, [course])


  // useEffect(() => {
  //   dispatch(getMyEnrollCourse(USER_ID));
  // }, [USER_ID]);

  // useEffect(() => {
  //   setCourseData(course);
  // }, [course]);

  useEffect(() => {
    dispatch(getOrder(userID));
  }, [userID]);

  const downloadPdf = (data) => {
    window.open(BASE_URL + 'api/order/download/' + data._id);
  }

  return (
    <div className="py-1" style={{ display: 'block' }}>
      {loader && course && course.length === 0 && (
        <section className="p-6 mb-8  flex  rounded-md shadow-md">
          {/* <div className="w-[250px] md:w-[350px]">
            <img src="/Assets/DataFound.png" alt="DataFound" className="w-full" />
          </div> */}
         
          <div
          className="w-full text-center "
          // onClick={() => navigate("/courses")}
        >
          {/* <NoData /> */}
          <p className=" sm:text-base  text-sm my-3 text-slate-600 text-center  w-full">
            Data not found. Keep searching a course suitable to you. !!
          </p>
          {/* <Button title="Browse Courses Now" />
           */}
            <Button event={() => navigate("/courses")} title="Browse Courses Now" />
        </div>
          {/* <Button event={() => navigate("/courses")} title="Keep Learning" /> */}
        </section>
      )}


      {
        ! loader && course && course.length ===0  &&
        (<div role="status" style={{ display: 'flex', justifyContent: 'left', alignItems: 'normal', height: '300px' }}>
          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>)
      }
      <div style={{ display: "inline-block" }}>
        {!loader && course &&
          course?.length > 0 &&
          course?.map((e, i) => {
            return (
              // <React.Fragment key={e._id}>
              //   <OrderCard enrolled={true} data={e} />
              // </React.Fragment>
              <div style={{ backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=600")',
                margin: ' 0px 10px 10px 0',
                display: 'inline-block', float: 'left', width: '100%'
              }} key={e._id} className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-700">
                <a onClick={() => {
                  navigate(`/course/${e?.course._id}`);
                }}>
                </a>
                <div className="p-1">
                  <a style={{ display: 'flex', direction: 'row', justifyContent: 'left', fontSize: '18px', alignItems: 'center' }}>
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"> {e?.course?.name}</h5>
                    <p className="text-white dark:text-white"></p>
                  </a>
                  <div className="">
                    <a style={{ display: 'flex', direction: 'row', justifyContent: 'space-between', fontSize: '18px', alignItems: 'normal' }}>
                
                      {
                        e.sections && e.sections.length > 0 ?
                          <h5 className="mb-2 text-2xl  tracking-tight text-gray-900 dark:text-red">{e.sections.map(item => item.name).join(', ')}</h5>

                          : null
                      }
                      <p className=" text-white dark:text-white">₹{e?.price}</p>
                    </a>
                    {/* <p className="mb-3 font-normal text-white dark:text-info">{e?.course?.description}</p> */}
                    <div className="text-left text-green-700">
                      <p className="font-bold">Status : {e?.payment_status}</p>
                      <p className="font-bold">Receipt Id : {e?.receiptid}</p>
                    </div>

                    <div className="pt-1" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px', alignItems: 'center' }}>
                      <button onClick={() => {
                        downloadPdf(e)
                      }} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Invoice

                        <svg className="w-4 h-4 ml-2 text-white-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                          <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>

                      </button>

                      <button onClick={() => {
                        navigate(`/me/order/${e._id}`);
                      }} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Detail
                        <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {/* 
                  <div className="pt-1" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px', alignItems: 'center' }}>
                    <button onClick={() => {
                      downloadPdf(e)
                    }} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Invoice

                      <svg className="w-4 h-4 ml-2 text-white-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                        <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                      </svg>

                    </button>

                    <button onClick={() => {
                      navigate(`/me/order/${e._id}`);
                    }} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      Detail
                      <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                      </svg>
                    </button>
                  </div> */}
                </div>
              </div>
            );
          })}


      </div>
    </div>
  );
};

export default UserComponent(Order);