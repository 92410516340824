// REGISTER
export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

// LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// USER_PROFILE
export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

// INSTRUCTOR_PROFILE
export const INSTRUCTOR_PROFILE = "INSTRUCTOR_PROFILE";
export const INSTRUCTOR_PROFILE_SUCCESS = "INSTRUCTOR_PROFILE_SUCCESS";
export const INSTRUCTOR_PROFILE_FAIL = "INSTRUCTOR_PROFILE_FAIL";

// FORGOT_PASS
export const FORGOT_PASS = "FORGOT_PASS";
export const FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS";
export const FORGOT_PASS_FAIL = "FORGOT_PASS_FAIL";

// UPDATE_PASS
export const UPDATE_PASS = "UPDATE_PASS";
export const UPDATE_PASS_SUCCESS = "UPDATE_PASS_SUCCESS";
export const UPDATE_PASS_FAIL = "UPDATE_PASS_FAIL";

// CHANGE_PASS
export const CHANGE_PASS = "CHANGE_PASS";
export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";
export const CHANGE_PASS_FAIL = "CHANGE_PASS_FAIL";

// Category
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

// Cart
export const GET_CART = "GET_CART";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const ADD_CART = "ADD_CART";
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAILURE = "ADD_CART_FAILURE";

export const DELETE_CART = "DELETE_CART";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";

// Order
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILURE = "ADD_ORDER_FAILURE";

export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";


export const DELETE_BY_SUBCOURSE = "DELETE_BY_SUBCOURSE";
export const DELETE_BY_SUBCOURSE_SUCCESS = "DELETE_BY_SUBCOURSE_SUCCESS";
export const DELETE_BY_SUBCOURSE_FAILURE = "DELETE_BY_SUBCOURSE_FAILURE";

//Enrollments
export const GET_ENROLLMENT_COURSE = "GET_ENROLLMENT_COURSE";
export const GET_ENROLLMENT_COURSE_SUCCESS = "GET_ENROLLMENT_COURSE_SUCCESS";
export const GET_ENROLLMENT_COURSE_FAILURE = "GET_ENROLLMENT_COURSE_FAILURE";

export const ADD_BY_COURSE_ENROLLMENT = "ADD_BY_COURSE_ENROLLMENT";
export const ADD_BY_COURSE_ENROLLMENT_SUCCESS =
  "ADD_BY_COURSE_ENROLLMENT_SUCCESS";
export const ADD_BY_COURSE_ENROLLMENT_FAILURE =
  "ADD_BY_COURSE_ENROLLMENT_FAILURE";

export const ADD_BY_USER_ENROLLMENT = "ADD_BY_USER_ENROLLMENT";
export const ADD_BY_USER_ENROLLMENT_SUCCESS = "ADD_BY_USER_ENROLLMENT_SUCCESS";
export const ADD_BY_USER_ENROLLMENT_FAILURE = "ADD_BY_USER_ENROLLMENT_FAILURE";

//Sub Courses
export const GET_SUBCOURSE = "GET_SUBCOURSE";
export const GET_SUBCOURSE_SUCCESS = "GET_SUBCOURSE_SUCCESS";
export const GET_SUBCOURSE_FAILURE = "GET_SUBCOURSE_FAILURE";

export const ADD_SUBCOURSE = "ADD_SUBCOURSE";
export const ADD_SUBCOURSE_SUCCESS = "ADD_SUBCOURSE_SUCCESS";
export const ADD_SUBCOURSE_FAILURE = "ADD_SUBCOURSE_FAILURE";

export const UPDATE_SUBCOURSE = "UPDATE_SUBCOURSE";
export const UPDATE_SUBCOURSE_SUCCESS = "UPDATE_SUBCOURSE_SUCCESS";
export const UPDATE_SUBCOURSE_FAILURE = "UPDATE_SUBCOURSE_FAILURE";

export const DELETE_SUBCOURSE = "UPDATE_SUBCOURSE";
export const DELETE_SUBCOURSE_SUCCESS = "DELETE_SUBCOURSE_SUCCESS";
export const DELETE_SUBCOURSE_FAILURE = "DELETE_SUBCOURSE_FAILURE";

// Courses
export const UPDATE_COURSE_BY_INSTRUCTOR = "UPDATE_COURSE_BY_INSTRUCTOR";
export const UPDATE_COURSE_BY_INSTRUCTOR_SUCCESS = "UPDATE_COURSE_BY_INSTRUCTOR_SUCCESS";
export const UPDATE_COURSE_BY_INSTRUCTOR_FAILURE = "UPDATE_COURSE_BY_INSTRUCTOR_FAILURE";

export const ADD_COURSE_BY_INSTRUCTOR = "ADD_COURSE_BY_INSTRUCTOR";
export const ADD_COURSE_BY_INSTRUCTOR_SUCCESS = "ADD_COURSE_BY_INSTRUCTOR_SUCCESS";
export const ADD_COURSE_BY_INSTRUCTOR_FAILURE = "ADD_COURSE_BY_INSTRUCTOR_FAILURE";

export const DELETE_COURSE_BY_INSTRUCTOR = "DELETE_COURSE_BY_INSTRUCTOR";
export const DELETE_COURSE_BY_INSTRUCTOR_SUCCESS = "DELETE_COURSE_BY_INSTRUCTOR_SUCCESS";
export const DELETE_COURSE_BY_INSTRUCTOR_FAILURE = "DELETE_COURSE_BY_INSTRUCTOR_FAILURE";

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAILURE = "GET_COURSE_FAILURE";

export const GET_ALL_COURSE = "GET_ALL_COURSE";
export const GET_ALL_COURSE_SUCCESS = "GET_ALL_COURSE_SUCCESS";
export const GET_ALL_COURSE_FAILURE = "GET_ALL_COURSE_FAILURE";

export const GET_POPULAR_COURSE = "GET_POPULAR_COURSE";
export const GET_POPULAR_COURSE_SUCCESS = "GET_POPULAR_COURSE_SUCCESS";
export const GET_POPULAR_COURSE_FAILURE = "GET_POPULAR_COURSE_FAILURE";

export const GET_TRENDING_COURSE = "GET_TRENDING_COURSE";
export const GET_TRENDING_COURSE_SUCCESS = "GET_TRENDING_COURSE_SUCCESS";
export const GET_TRENDING_COURSE_FAILURE = "GET_TRENDING_COURSE_FAILURE";

export const GET_COURSE_BY_ID = "GET_COURSE_BY_ID";
export const GET_COURSE_BY_ID_SUCCESS = "GET_COURSE_BY_ID_SUCCESS";
export const GET_COURSE_BY_ID_FAILURE = "GET_COURSE_BY_ID_FAILURE";

export const GET_COURSE_BY_INSTRUCTOR = "GET_COURSE_BY_INSTRUCTOR";
export const GET_COURSE_BY_INSTRUCTOR_SUCCESS = "GET_COURSE_BY_INSTRUCTOR_SUCCESS";
export const GET_COURSE_BY_INSTRUCTOR_FAILURE = "GET_COURSE_BY_INSTRUCTOR_FAILURE";

// Language
export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAILURE = "GET_LANGUAGE_FAILURE";

// Sessions
export const GET_SESSIONS = "GET_SESSIONS";
export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS";
export const GET_SESSIONS_FAILURE = "GET_SESSIONS_FAILURE";

export const ADD_SESSIONS = "ADD_SESSIONS";
export const ADD_SESSIONS_SUCCESS = "ADD_SESSIONS_SUCCESS";
export const ADD_SESSIONS_FAILURE = "ADD_SESSIONS_FAILURE";

export const UPDATE_SESSIONS = "ADD_SESSIONS";
export const UPDATE_SESSIONS_SUCCESS = "UPDATE_SESSIONS_SUCCESS";
export const UPDATE_SESSIONS_FAILURE = "UPDATE_SESSIONS_FAILURE";

export const DELETE_SESSIONS = "DELETE_SESSIONS";
export const DELETE_SESSIONS_SUCCESS = "DELETE_SESSIONS_SUCCESS";
export const DELETE_SESSIONS_FAILURE = "DELETE_SESSIONS_FAILURE";

// Wishlist
export const GET_WISHLIST = "GET_WISHLIST";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAILURE = "GET_WISHLIST_FAILURE";

// PROFILE

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";
export const UPDATE_USER_PROFILE_PICTURE="UPDATE_USER_PROFILE_PICTURE";
export const UPDATE_USER_PROFILE_PICTURE_SUCCESS="UPDATE_USER_PROFILE_PICTURE_SUCCESS";

export const UPDATE_INSTRUCTOR_PROFILE = "UPDATE_INSTRUCTOR_PROFILE";
export const UPDATE_INSTRUCTOR_PROFILE_SUCCESS = "UPDATE_INSTRUCTOR_PROFILE_SUCCESS";
export const UPDATE_INSTRUCTOR_PROFILE_FAILURE = "UPDATE_INSTRUCTOR_PROFILE_FAILURE";
export const UPDATE_INSTRUCTOR_PROFILE_PICTURE = "UPDATE_INSTRUCTOR_PROFILE_PICTURE";
export const UPDATE_INSTRUCTOR_PROFILE_PICTURE_SUCCESS = "UPDATE_INSTRUCTOR_PROFILE_PICTURE_SUCCESS";

// PLACE ORDER
export const PLACE_ORDER = "PLACE_ORDER";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";

// DOWNLOAD MATERIALS
export const DOWNLOAD_MATERIALS = "DOWNLOAD_MATERIALS";
export const DOWNLOAD_MATERIALS_SUCCESS = "DOWNLOAD_MATERIALS_SUCCESS";
export const DOWNLOAD_MATERIALS_FAILURE = "DOWNLOAD_MATERIALS_FAILURE";

// LAGUAGES
export const SET_LANGUAGE = "SET_LANGUAGE";
export const GET_ALL_INSTRUCTOR = "GET_ALL_INSTRUCTOR";
export const SET_ALL_INSTRUCTOR = "SET_ALL_INSTRUCTOR";