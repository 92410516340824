import {
  GET_WISHLIST,
  GET_WISHLIST_FAILURE,
  GET_WISHLIST_SUCCESS
} from "../Actions/ActionTypes";

const initialState = {
  wishlist: [],
  loading: false
};

const WishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WISHLIST:
      return { ...state, loading: true };
    case GET_WISHLIST_SUCCESS:
      return { ...state, wishlist: action.payload, loading: false };
    case GET_WISHLIST_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default WishlistReducer;
