import {
  ADD_SESSIONS,
  ADD_SESSIONS_FAILURE,
  ADD_SESSIONS_SUCCESS,
  DELETE_SESSIONS,
  DELETE_SESSIONS_FAILURE,
  DELETE_SESSIONS_SUCCESS,
  GET_SESSIONS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_SUCCESS,
  UPDATE_SESSIONS,
  UPDATE_SESSIONS_FAILURE,
  UPDATE_SESSIONS_SUCCESS
} from "../Actions/ActionTypes";

const initialState = {
  sessions: [],
  loading: false
};
const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get
    case GET_SESSIONS:
      return { ...state, loading: true };

    case GET_SESSIONS_SUCCESS:
      return { ...state, sessions: action.payload, loading: false };

    case GET_SESSIONS_FAILURE:
      return { ...state, loading: false };

    // Add

    case ADD_SESSIONS:
      return { ...state, loading: true };

    case ADD_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: [...state.sessions, action.payload],
        loading: false
      };

    case ADD_SESSIONS_FAILURE:
      return { ...state, loading: false };

    //   Update

    case UPDATE_SESSIONS:
      return { ...state, loading: true };

    case UPDATE_SESSIONS_SUCCESS:
      let tempSession = [...state.sessions];
      let inx = tempSession.findIndex((data) => data._id === action.payload._id);
      let cy = { ...action.payload };
      if (inx > -1) {
        tempSession[inx] = {
          ...tempSession[inx],
          ...cy
        };
      }
      return { ...state, sessions: tempSession, loading: false };

    case UPDATE_SESSIONS_FAILURE:
      return { ...state, loading: false };

    //   Delete

    case DELETE_SESSIONS:
      return { ...state, loading: true };

    case DELETE_SESSIONS_SUCCESS:
      let tempData = [...state.sessions];
      let filteredData = tempData.filter((item) => item._id !== action.payload);
      return { ...state, sessions: filteredData, loading: false };

    case DELETE_SESSIONS_FAILURE:
      return { ...state, loading: true };

    default:
      return state;
  }
};

export default SessionReducer;
