import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";

const BASEURL = process.env.REACT_APP_BASE_URL;

const CourseCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" xl:w-1/5 lg:w-1/4 h-full sm:w-1/3 w-full">
        <div className=" px-4 sm:px-3  transition ease-out duration-1000 rounded-md break-words">
          <Link
            to={`/course/${data._id}`}
            className="block group mb-2 relative transition ease-out active:opacity-75 overflow-hidden"
          >
            <img
              src={`${BASEURL}${data?.image}`}
              alt={`${data.name.slice(0, 4)}...`}
              className="transform transition ease-out group-hover:scale-110"
              style={{height:'150px', width:'100%',margin:'0px'}}
            />
            <div className="absolute inset-0 bg-black bg-opacity-25 transition ease-out group-hover:bg-opacity-0"></div>
            <div className="p-4 flex items-center justify-center absolute inset-0">
              {/* <div className="py-3 px-4 bg-white bg-opacity-95 rounded-3xl text-sm font-semibold uppercase tracking-wide transition ease-out group-hover:text-white group-hover:bg-blue-600">
                Computers
              </div> */}
            </div>
          </Link>
          <div className=" h-full py-0.5 ">
            <div className="flex justify-between items-center">
              <h3
                onClick={() => {
                  navigate(`/course/${data._id}`);
                }}
                className="text-slate-900  text-xs tracking-widest title-font mb-2"
              >
                {data?.category?.name}
              </h3>
              <h3 className="text-color  text-xs font-medium  tracking-widest title-font mb-1">
                {data.language?.name}
              </h3>
            </div>

            <h2 className="text-gray-900 capitalize title-font text-lg font-semibold">
              {data?.name?.slice(0, 50)}
              {data?.name?.length > 50 && "..."}
            </h2>
            <p className="mt-1 text-sm text-gray-900">
              {data.description?.slice(0, 100)}
              {data?.description?.length > 100 && "..."}
            </p>
            <div
              onClick={() => {
                navigate(`/course/${data._id}`);
              }}
              className="mt-3 text-sm text-color flex items-center cursor-pointer"
            >
              Read More
              <BsArrowRightShort className="text-xl mt-0.5" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseCard;
