import React, { useState, useEffect } from "react";
import TopBar from "../../Components/TopBar";
import Button from "../../Components/Button";
import NoData from "../../Components/NoData";
import Content from "../../Components/Content";
import Heading from "../../Components/Heading";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  addSessions,
  deleteSession,
  getSessions,
  updateSessions,
  getMeetingSession
} from "../../Redux/Actions/SessionAction";
import GotoVSTMeet from "./GotoVSTMeet";

const BASEURL = process.env.REACT_APP_BASE_URL;

const EnrolledDetail = () => {
  const { state } = useLocation();
  console.log("state", state)
  const dispatch = useDispatch();
  const subcourse = useLocation().state.data;


  const input =
    "bg-transparent border-indigo-400 tracking-widest text-xs rounded-sm shadow-md outline-none py-1.5 px-2 border";


  return (
    <div className="container mx-auto px-4">
      <TopBar path={-1} title={subcourse.subcourse.name} />
      <Content data={subcourse.subcourse} />
      {/* Heading */}
      <div className="border-b py-3 justify-between">
        <Heading title={"All Enrolled Student"} />
      </div>

      {/* Table */}
      <div className="overflow-x-auto py-5">
      <table className="table-auto min-w-full text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
              Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
              Email
              </th>
            </tr>
          </thead>
          {subcourse && subcourse.users && subcourse.users?.length > 0 && <tbody>

           {subcourse.users.map((item, index)=>(
              <tr key={item._id} className="border-b text-sm">
                <td className="px-4 py-3">{item?.name}</td>
                <td className="px-4 py-3">{item?.email}</td>
              </tr>

           )) }

          </tbody>}

          {subcourse.users && subcourse.users?.length === 0 && <tbody>
            <tr>

              <td colSpan={6}>
                <div className="w-full text-center">
                  <NoData />

                  {/* <Button
                    title="Browse Courses Now"
                    event={() => navigate("/courses")}
                  /> */}
                </div>
              </td>
            </tr>
           
          </tbody>}
        </table>
        <br /><br />
      </div>

    </div>
  );
};

export default EnrolledDetail;
