import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL, USER_ID } from "../../config";
import {
  ADD_BY_COURSE_ENROLLMENT,
  ADD_BY_COURSE_ENROLLMENT_FAILURE,
  ADD_BY_COURSE_ENROLLMENT_SUCCESS,
  ADD_BY_USER_ENROLLMENT,
  ADD_BY_USER_ENROLLMENT_FAILURE,
  ADD_BY_USER_ENROLLMENT_SUCCESS,
  ADD_COURSE_BY_INSTRUCTOR,
  ADD_COURSE_BY_INSTRUCTOR_FAILURE,
  ADD_COURSE_BY_INSTRUCTOR_SUCCESS,
  GET_CART_SUCCESS,
  GET_COURSE,
  GET_COURSE_BY_ID_FAILURE,
  GET_COURSE_BY_ID_SUCCESS,
  GET_COURSE_BY_INSTRUCTOR,
  GET_COURSE_BY_INSTRUCTOR_FAILURE,
  GET_COURSE_BY_INSTRUCTOR_SUCCESS,
  GET_COURSE_FAILURE,
  GET_COURSE_SUCCESS,
  GET_ENROLLMENT_COURSE,
  GET_ENROLLMENT_COURSE_FAILURE,
  GET_ENROLLMENT_COURSE_SUCCESS,
  UPDATE_COURSE_BY_INSTRUCTOR,
  UPDATE_COURSE_BY_INSTRUCTOR_FAILURE,
  UPDATE_COURSE_BY_INSTRUCTOR_SUCCESS,
  DELETE_COURSE_BY_INSTRUCTOR,
  DELETE_COURSE_BY_INSTRUCTOR_FAILURE,
  DELETE_COURSE_BY_INSTRUCTOR_SUCCESS,
  GET_POPULAR_COURSE,
  GET_POPULAR_COURSE_SUCCESS,
  GET_POPULAR_COURSE_FAILURE,
  GET_TRENDING_COURSE,
  GET_TRENDING_COURSE_SUCCESS,
  GET_TRENDING_COURSE_FAILURE,
  GET_COURSE_BY_ID,
  GET_SESSIONS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_SUCCESS,
  GET_ALL_COURSE,
  GET_ALL_COURSE_SUCCESS,
  GET_ALL_COURSE_FAILURE

} from "./ActionTypes";

// Courses
export const getCourse = () => {
  return async (dispatch) => {
    dispatch({ type: GET_COURSE });
    let url = `${BASE_URL}api/course`;
    await axios
      .get(url)
      .then((res) => {
        dispatch({
          type: GET_COURSE_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_COURSE_FAILURE });
      });
  };
};

// Courses
export const getAllCourseWithFilter = (data) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_COURSE });
    let url = `${BASE_URL}api/course/filter/`;
    await axios
      .post(url, data)
      .then((res) => {
        dispatch({
          type: GET_ALL_COURSE_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        console.log("errorerrorerror", error)
        dispatch({ type: GET_ALL_COURSE_FAILURE });
      });
  };
};


export const getPopularCourse = () => {
  return async (dispatch) => {
    dispatch({ type: GET_POPULAR_COURSE });
    let url = `${BASE_URL}api/course/popular`;
    await axios
      .get(url)
      .then((res) => {
        dispatch({
          type: GET_POPULAR_COURSE_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_POPULAR_COURSE_FAILURE });
      });
  };
};
export const getTrendingCourse = () => {
  return async (dispatch) => {
    dispatch({ type: GET_TRENDING_COURSE });
    let url = `${BASE_URL}api/course/rating`;
    await axios
      .get(url)
      .then((res) => {
        dispatch({
          type: GET_TRENDING_COURSE_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_TRENDING_COURSE_FAILURE });
      });
  };
};
export const getCourseById = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_COURSE_BY_ID });
    let url = `${BASE_URL}api/course/${id}`;
    await axios
      .get(url)
      .then((res) => {
        dispatch({
          type: GET_COURSE_BY_ID_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_COURSE_BY_ID_FAILURE });
      });
  };
};
export const getCourseByInstructor = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_COURSE_BY_INSTRUCTOR });
    let url = `${BASE_URL}api/course/instructor/${id}`;
    try {
      const res = await axios.get(url);
      dispatch({
        type: GET_COURSE_BY_INSTRUCTOR_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({ type: GET_COURSE_BY_INSTRUCTOR_FAILURE });
    }
  };
};
export const addCourse = (payload, insId, callback) => {
  return async (dispatch) => {
    dispatch({ type: ADD_COURSE_BY_INSTRUCTOR });
    let url = `${BASE_URL}api/course/${insId}`;
    try {
        let res = await axios.post(url, payload, {
          headers: {
            instructorToken: localStorage.getItem("instructorToken|VST"),
          },
        });
        if (res.data.message === "Course created successfully") {
          toast.success(res.data.message);
          dispatch({
            type: ADD_COURSE_BY_INSTRUCTOR_SUCCESS,
            payload: res.data.data,
          });
          return callback();
      } else {
        toast.error(res.data.message);
        setTimeout(function() {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      dispatch({ type: ADD_COURSE_BY_INSTRUCTOR_FAILURE });
    }
  };
};
export const updateCourse = (payload, insId, callback) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_COURSE_BY_INSTRUCTOR });
    let url = `${BASE_URL}api/course/${insId}`;
    try {
      let res = await axios.patch(url, payload, {
        headers: {
          instructorToken: localStorage.getItem("instructorToken|VST"),
        },
      });
      toast.success(res.data.message);
      dispatch({
        type: UPDATE_COURSE_BY_INSTRUCTOR_SUCCESS,
        payload: res.data.data,
      });
      return callback();
    } catch (error) {
      dispatch({ type: UPDATE_COURSE_BY_INSTRUCTOR_FAILURE });
    }
  };
};

// export const deleteCourse = (id, callback) => {
//   return async (dispatch) => {
//     dispatch({ type: DELETE_COURSE_BY_INSTRUCTOR });
//     let url = `${BASE_URL}api/course/${id}`;
//     try {
//       let res = await axios.delete(url, {
//         headers: {
//           instructorToken: localStorage.getItem("instructorToken|VST"),
//         },
//       });
//       toast.success(res.data.message);
//       dispatch({
//         type: DELETE_COURSE_BY_INSTRUCTOR_SUCCESS
//       });
//       return callback();
//     } catch (error) {
//       dispatch({ type: DELETE_COURSE_BY_INSTRUCTOR_FAILURE });
//     }
//   };
// };

export const deleteCourse = (id, courId) => async (dispatch) => {
  dispatch({ type: DELETE_COURSE_BY_INSTRUCTOR });
  let url = `${BASE_URL}api/course/${id}`;
  try {
    let res=await axios.delete(url, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    toast.success(res.data.message);
    dispatch({
        type: DELETE_COURSE_BY_INSTRUCTOR_SUCCESS
    });
    dispatch(getCourseByInstructor(courId));
  } catch (error) {
    dispatch({ type: DELETE_COURSE_BY_INSTRUCTOR_FAILURE });
  }
};

export const getInstructorDemoMeetingSession = (id) => async (dispatch) => {
  dispatch({ type: GET_SESSIONS });

  let url = `${BASE_URL}api/course/instructor/meeting/${id}`;
  try {
    let res = await axios.get(url, {
      headers: {
        instructorToken: localStorage.getItem("instructorToken|VST"),
      },
    });
    if (res.status === 200) {
      if (res.data.data === "Please start the meeting at the scheduled time." ||
          res.data.data === "The class session is over...")
      {
        toast.success(res.data.message);
      } else {
        window.open(res.data.data);
      }
    }
  } catch (error) {
    dispatch({ type: GET_SESSIONS_FAILURE });
  }
};

export const getStudentDemoMeetingSession = (obj) => async (dispatch) => {
  dispatch({ type: GET_SESSIONS });
  let url = `${BASE_URL}api/course/student/meeting/${obj.id}`;
  try {
    let res = await axios.get(url, {
      params: {
        name: obj.name,
      },
      headers: {
        userId: localStorage.getItem("userData|VST"),
        userToken: localStorage.getItem("userToken|VST"),
      },
    });
    if (res.status === 200) {
      if (res.data.data === "Please wait, the session has not started yet." ||
          res.data.data === "The class session is over.....")
      {
        toast.success(res.data.message);
      } else {
        window.open(res.data.data);
      }
    }
  } catch (error) {
    dispatch({ type: GET_SESSIONS_FAILURE });
  }
};

// User Enrollments
export const getMyEnrollCourse = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ENROLLMENT_COURSE });
    let url = `${BASE_URL}api/enrollment/user/${USER_ID}`;
    try {
      let res = await axios.get(url, {
        headers: {
          token: localStorage.getItem("userToken|VST"),
        },
      });
      dispatch({
        type: GET_ENROLLMENT_COURSE_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({ type: GET_ENROLLMENT_COURSE_FAILURE });
    }
  };
};

export const addCourseByEnrollCourse = () => {
  return async (dispatch) => {
    dispatch({ type: ADD_BY_COURSE_ENROLLMENT });
    let url = `${BASE_URL}api/enrollment/course/${USER_ID}`;
    try {
      await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          token: localStorage.getItem("userToken|VST"),
          "X-Custom-Header": "header value",
        },
      })
        .then((res) => res.json())
        .then((resp) => {
          toast.success(resp.message);

          dispatch({
            type: ADD_BY_COURSE_ENROLLMENT_SUCCESS,
            payload: resp.data,
          });
        });
    } catch (error) {
      dispatch({ type: ADD_BY_COURSE_ENROLLMENT_FAILURE });
    }
  };
};

export const addCourseByUserEnroll = () => {
  return async (dispatch) => {
    dispatch({ type: ADD_BY_USER_ENROLLMENT });
    let url = `${BASE_URL}api/enrollment/user/${USER_ID}`;
    try {
      let res = await fetch(url, {
        method: "POST",
        headers: { token: localStorage.getItem("userToken|VST") },
      })
        .then((res) => res.json())
        .then((resp) => {
          toast.success(resp.message);
          dispatch({
            type: GET_CART_SUCCESS,
            payload: {
              course: [],
              subcourse: [],
              price: 0,
              subcoursePrice: 0,
              count: 0,
            },
          });
          dispatch({
            type: ADD_BY_USER_ENROLLMENT_SUCCESS,
            payload: resp.data.data,
          });
        });
    } catch (error) {
      dispatch({ type: ADD_BY_USER_ENROLLMENT_FAILURE });
    }
  };
};
