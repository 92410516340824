import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../Components/TopBar";
import CartItem from "../Components/CartItem";
import { getCart } from "../Redux/Actions/CartAction";
import Checkout from "../Components/Checkout";
import Button from "../Components/Button";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.CartReducer.cart);
  const userID = localStorage.getItem("userID|VST");

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch, userID]);
  return (
    <>
      <div className="container min-h-screen md:px-6 p-4 tracking-wider mx-auto">
        <TopBar title="Course Cart" path={-1}/>
        {userID && cartData.price === 0 ? (
          <section className="p-6 mb-8  flex flex-col items-center rounded-md shadow-md">
            <div className="w-[250px] md:w-[350px]">
              <img src="/Assets/cart.png" alt="cart" className="w-full" />
            </div>
            <p className=" sm:text-base  text-sm my-3 text-slate-600 text-center  w-full">
              You have not selected any course yet. Keep searching a course suitable to you. !!
            </p>
            <Button event={() => navigate("/courses")} title="Keep Learning" />
          </section>
        ) : (
          <div className="flex mt-5 lg:flex-row gap-10 justify-between flex-col">
            <div className="flex-col w-full lg:w-3/5 space-y-4">
              <ul className="flex flex-col divide-y divide-gray-200">
                {cartData.course &&
                  cartData.course?.map((data, index) => {
                    return (
                      <div key={index}>
                        {" "}
                        <CartItem
                          data={data}
                          subcourse={cartData.subcourse[data._id]}
                          coursePrice={cartData.subcoursePrice[data._id]}
                        />
                      </div>
                    );
                  })}
              </ul>
            </div>
            <Checkout data={cartData} />
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;
