import React from "react";
import { IoIosArrowBack } from "react-icons/io";

const BackButton = (props) => {
  return (
    <button
      title="Back"
      className=" text-white text-sm cursor-pointer w-7 h-7 flex justify-center items-center shadow rounded-full tracking-widest bg-color  "
      onClick={props.event}
    >
      <IoIosArrowBack />
    </button>
  );
};

export default BackButton;
