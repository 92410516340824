import {
  ADD_SUBCOURSE,
  ADD_SUBCOURSE_FAILURE,
  ADD_SUBCOURSE_SUCCESS,
  GET_SUBCOURSE,
  GET_SUBCOURSE_FAILURE,
  GET_SUBCOURSE_SUCCESS,
  UPDATE_SUBCOURSE,
  UPDATE_SUBCOURSE_FAILURE,
  UPDATE_SUBCOURSE_SUCCESS
} from "../Actions/ActionTypes";

const initialState = {
  loading: false,
  subCourse: []
};

const SubCourseReducer = (state = initialState, action) => {
  switch (action.type) {
    // Add Sub Course
    case ADD_SUBCOURSE:
      return { ...state, loading: true };
    case ADD_SUBCOURSE_SUCCESS:
      return {
        ...state,
        subCourse: [...state.subCourse, action.payload],
        loading: false
      };
    case ADD_SUBCOURSE_FAILURE:
      return { ...state, loading: false };

    //   Update Sub Course

    case UPDATE_SUBCOURSE:
      return { ...state, loading: true };
    case UPDATE_SUBCOURSE_SUCCESS:
      let tempSubCourseArr = [...state.subCourse];
      let indx = tempSubCourseArr.findIndex(
        (item) => item._id === action.payload._id
      );
      let y = { ...action.payload };
      if (indx > -1) {
        tempSubCourseArr[indx] = {
          ...tempSubCourseArr[indx],
          ...y
        };
      }
      return {
        ...state,
        loading: false,
        subCourse: tempSubCourseArr
      };
    case UPDATE_SUBCOURSE_FAILURE:
      return { ...state, loading: false };

    // Get Sub Course
    case GET_SUBCOURSE:
      return { ...state, loading: true };

    case GET_SUBCOURSE_SUCCESS:
      return { ...state, subCourse: action.payload, loading: false };

    case GET_SUBCOURSE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default SubCourseReducer;
