import axios from "axios";
import moment from 'moment';
import { format } from "timeago.js";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { ImInfinite } from "react-icons/im";
import { AiOutlineMobile } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { getOrder } from "../Redux/Actions/OrderAction";
import { getStudentDemoMeetingSession } from "../Redux/Actions/CourseAction";
import {
  MdOutlineFavoriteBorder,
  MdOndemandVideo,
  MdOutlineFavorite,
  MdOutlineFileDownload,
} from "react-icons/md";

import NoData from "../Components/NoData";
import Heading from "../Components/Heading";
import ApiLoader from "../Components/ApiLoader";
import BackButton from "../Components/BackButton";
import { payment } from "../Redux/Actions/PayAction";
import { addCart, getCart } from "../Redux/Actions/CartAction";
import { addFav } from "../Redux/Actions/WishlistAction";
import { BASE_URL } from "../config";
import { getSubCourse } from "../Redux/Actions/SubCourseAction";
import { userProfile } from "../Redux/Actions/AuthAction";
import { GET_ORDER } from "../Redux/Actions/ActionTypes";

const CourseById = () => {
  const id = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fav, setFav] = useState();
  const [data, setData] = useState();
  const [demotime, setDemotime] = useState();
  const USER_ID = localStorage.getItem("userID|VST");
  const [open, setOpen] = useState(true);
  const [review, setReview] = useState({
    review: "",
    comment: "",
    course: id,
    user: USER_ID,
  });
  const [allReview, setAllReview] = useState([]);
  const [reviewLoader, setReviewLoader] = useState(false);

  let userData = useSelector((state) => state.AuthReducer.userData);
  const courselog = useSelector((state) => state.CourseReducer.courselog);
  const course1 = useSelector((state) => state.CourseReducer.course);
  const sub_courses = useSelector((state) => state.SubCourseReducer.subCourse);
  const userToken = localStorage.getItem("userToken|VST");
  const userID = localStorage.getItem("userID|VST");

  // Add Favourite
  const addFavourite = () => {
    const payload = {
      user: userID,
      course: data?._id,
    };

    localStorage.getItem("userToken|VST")
      ? dispatch(
        addFav(payload, () => {
          setFav(true);
        })
      )
      : navigate("/login");
  };
  // Get review
  const getReview = async () => {
    try {
      const res = await axios.get(`${BASE_URL}api/review/course/${id}`, review);
      setAllReview(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // Add Review
  const addReview = async () => {
    setReviewLoader(true);
    try {
      await axios.post(`${BASE_URL}api/review`, review, {
        headers: {
          token: userToken,
        },
      });
      setReview({
        review: 0,
        comment: "",
        course: id,
        // category: data?.category._id,
        user: userID,
      });
      setReviewLoader(false);
      getReview();
    } catch (error) {
      setReviewLoader(false);
      toast.error(error.response.data.message);
    }
  };
  // get course by id
  const getCourseById = async () => {
    let url = `${BASE_URL}api/course/${id}`;
    try {
      const res = await axios.get(url);
      const data = res.data.data;
      const demotime = res.data.data;
      setData(data);
      setDemotime(demotime);
    } catch (error) {
      console.log(error);
    }
  };
  // Add CartItem
  let mat = "";
  let base_path = "";
  const addToCart = (courseId) => {
    const payload = {
      course: data?._id,
      subcourse: courseId,
      user: userID,
    };
    dispatch(addCart(payload, () => { }));
    setTimeout(function(){
    window.location.reload();
   }, 2000);
  };

  const meeting_payload = {
    course: data?._id,
    user: userID,
  };

  // Handle pay
  const handlePay = (e) => {
    localStorage.getItem("userToken|VST")
      ? dispatch(payment(e))
      : navigate("/login");
  };

  // Ge Sub Course
  useEffect(() => {
    getReview();
    getCourseById();
    dispatch(getSubCourse(id));
  }, [dispatch, userID]);

  useEffect(() => {
    dispatch(userProfile(userID));
  }, [userID]);

  let buyNowButtonFlag = false;
  return (
    <>
      {/* Top Section */}
      <section className="bg-gray-900 pattern  text-white">
        <div className="container py-1 px-4  mx-auto">
          <div className="w-full flex py-1">
            <div className="ml-auto mr-3">
              <BackButton event={() => navigate(-1)} />
            </div>
          </div>
          <div className="my-4 lg:-mx-6  lg:flex lg:items-center break-words w-full">
            {/* <figure className="lg:w-72 lg:h-72 w-40  md:h-60 h-40  md:w-60 mx-auto rounded-full shadow">
              <img
                src={`${BASE_URL}${data?.image}`}
                alt={`${data?.name.slice(0, 3)}...`}
                //src="https://images.unsplash.com/photo-1526379095098-d400fd0bf935?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80"
                className="w-full h-full bg-gray-100 rounded-full object-contain"
              />
            </figure> */}

            <div className=" lg:p-4 mt-2 ml-3 w-full">
              {/* lg:w-1/2   */}
              <p className="md:text-base text-sm text-color ">
                {data?.category?.name}
              </p>

              <span className="block md:mt-3 mt-2  text-xl sm:text-2xl font-semibold text-gray-300   md:text-3xl">
                {data?.name}
              </span>
              <p className="my-5 text-sm text-gray-200  md:text-base">
                {data?.description}
              </p>
              {/* Demo Meeting Session */}
              <p className="text-slate-800 text-[11px]">
                <td className="md:text-base text-sm text-color justify-left">
                  <button
                    onClick={() => {
                      if (localStorage.getItem("userToken|VST")) 
                      {
                        dispatch(getStudentDemoMeetingSession({id: data?._id, name: userData?.name}))}}
                      }
                    className="cursor-pointer"
                  >
                    Orientation class scheduled for {data?.demosessiontime ? moment(new Date(data?.demosessiontime)).subtract(5.5, 'hours').format("DD/MMM/YYYY hh:mm A") : ""} IST.
                  </button>
                </td>
              </p>
              {/* <Button
                title="Add to Cart"
                event={() => {
                  localStorage.getItem("userToken|VST")
                    ? addToCart(id)
                    : navigate("/login");
                }}
              /> */}
              {/* <p className=" text-sm  tracking-widest font-semibold">
                <span className="  uppercase tracking-wider font-semibold pr-2">
                  Price :
                </span>
                Rs {data?.price}
                /-
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {/* About Course */}
      <section className="container gap-10 flex md:flex-row flex-col justify-between  mt-4 p-4   mx-auto">
        {/* Course Content */}
        <div className="md:w-3/5 lg:w-4/6 tracking-wider w-full ">
          <div>
            <Heading title="Sub Courses" />
            <div className="bg-gray-100 p-3 grid gap-2 mt-4  md:max-h-96 overflow-y-auto over rounded shadow">
              {sub_courses &&
                sub_courses?.map((e, i) => {
                  let subcoursePurchased = false;
                  let subcourseRedirectTitle = "Add to Cart";
                  let subcourseRedirect;
                  subcourseRedirect = () => navigate("/login");
                  if (USER_ID) {
                    mat = e.material;
                    base_path = process.env.REACT_APP_BASE_URL;
                    mat = mat.slice(2);
                    mat = process.env.REACT_APP_BASE_URL + mat;
                    subcoursePurchased = courselog.includes(e._id);
                    subcourseRedirect = () => addToCart(e._id);
                    if (subcoursePurchased) {
                      subcourseRedirect = () =>
                        // navigate("/user/my-courses/ViewCourse/" + id);
                        (subcourseRedirectTitle = "My Courses");
                    } else {
                      buyNowButtonFlag = false;
                    }
                  } else {
                    buyNowButtonFlag = false;
                  }
                  return (
                    <div className="bg-white rounded p-2 ">
                      <div className="flex   items-center justify-between gap-1.5 p-1">
                        <div className="flex items-center gap-2">
                          <div
                            onClick={() => {
                              if ((moment(new Date()) < moment(new Date(e.classbegindate)).subtract(5.5, 'hours')) && e.name === open && e.status === "incomplete") {
                                setOpen("");
                              } else if (e.status === "incomplete"){
                                setOpen(e.name);
                              }
                            }}
                            className="cursor-pointer transition-all duration-100"
                          >
                            {open === e.name ? (
                              <FiChevronUp />
                            ) : (
                              <FiChevronDown />
                            )}
                          </div>
                          <span className="font-semibold text-sm capitalize">
                            {e.name}
                          </span>
                        </div>
                        <div className="flex text-red-500 text-sm items-between gap-2">
                          Session Begin From: 
                              {e.classbegindate ? moment(new Date(e.classbegindate)).subtract(5.5, 'hours').format("DD/MMM/YYYY hh:mm A") : ""}
                        </div>
                        <div className="flex items-center gap-3">
                          <span className="text-sm">₹ {e.price}</span>
                          <div
                            // onClick={() => handlePay(e)}
                            onClick={subcourseRedirect}
                            className="text-sm cursor-pointer underline font-semibold"
                          >
                            {moment(new Date()) < moment(new Date(e.classbegindate)).subtract(5.5, 'hours') && 
                                          e.status === "incomplete" && subcourseRedirectTitle}
                          </div>
                        </div>
                      </div>
                      {/* Sub-Course content */}
                      {open === e.name && (
                        <div className="py-4 mt-2 border-t">
                          <div className="flex flex-col items-left sm:flex-row gap-2 text-xs sm:text-sm justify-between">
                            <div className="sm:w-1/8">
                              <img
                                src={`${BASE_URL}${e.image}`}
                                alt={e.name}
                                className="w-20 h-20 rounded-full object-cover "
                              />
                            </div>
                            {/* <div className="sm:w-1/6">
                              <img
                                src={`${BASE_URL}${e.preview}`}
                                alt={e.name}
                                className="w-20 h-20 rounded-full object-cover "
                              />
                            </div> */}
                            {/* {moment(new Date()) < moment(new Date(e.classbegindate)).subtract(5.5, 'hours') && 
                                          e.status === "incomplete" ( */}
                            <div className="sm:w-1/12">
                              <Link to={mat}
                                className="sm:w-5/6 text-color cursor-pointer hover:text-red-500">
                                Syllabus and Study Materials
                              </Link>
                            </div>
                            <p className="w-50 h-40 rounded-full sm:w-1/2 text-gray-700 ">
                              {e.description}
                            </p>
                            <p className="sm:w-1/6 text-gray-700 ">
                              {e.teachername}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}

              {/* No Data */}
              {sub_courses.length === 0 && <NoData />}
            </div>
          </div>
        </div>

        {/* Buy Now */}
        <div className="md:w-2/5 lg:w-2/6">
          <div className="flex items-center my-3 gap-5">
            {/* <button
              onClick={() => handlePay(data)}
              className=" px-4 py-2 text-xs md:text-sm tracking-widest font-medium text-white uppercase transition-colors duration-300 transform bg-color rounded lg:w-auto  focus:outline-none "
            >
              Buy Now This Course
            </button> */}

            <button
              onClick={addFavourite}
              className={`md:text-3xl text-2xl  ${fav ? "text-red-500" : ""
                } text-center flex border-[rgb(38,57,106)] justify-between items-center rounded-full `}
            >
              <h4 className="font-thin text-xl">WishList </h4>
              {fav ? <MdOutlineFavorite /> : <MdOutlineFavoriteBorder />}
            </button>
          </div>

          <div className="p-2">
            <h4 className="font-semibold">This Course offers :</h4>
            <div className="py-2">
              {/* Video */}
              <div className="flex items-center gap-4 py-1.5">
                <MdOndemandVideo />
                <span className="text-sm">On-demand session recordings</span>
              </div>
              <div className="flex items-center gap-4 py-1.5">
                <MdOutlineFileDownload />
                <span className="text-sm">VST downloadable study materials</span>
              </div>
              <div className="flex items-center gap-4 py-1.5">
                <ImInfinite />
                <span className="text-sm">Practice eBooks</span>
              </div>
              <div className="flex items-center gap-4 py-1.5">
                <AiOutlineMobile />
                <span className="text-sm">Access on mobile</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      <section className="container p-4  mx-auto">
        <div className="md:w-3/5 lg:w-4/6 pb-5 w-full ">
          <div className="border-b py-1 justify-between">
            <Heading title="All Reviews" />
          </div>

          {/* Write */}
          <div className="flex max-w-xl  flex-col">
            <div className="flex flex-col items-center text-center pt-5">
              <h2 className="sm:text-3xl text-2xl font-semibold text-center">
                Your feeback is important to us!
              </h2>
              <ReactStars
                count={5}
                onChange={(newRating) =>
                  setReview({
                    ...review,
                    review: newRating,
                  })
                }
                size={40}
                activeColor="#ffd700"
              />
            </div>
            <textarea
              type="text"
              value={review.comment}
              onChange={(e) =>
                setReview({
                  ...review,
                  comment: e.target.value,
                })
              }
              rows={6}
              className="border-color mt-2 w-full p-2  text-xs tracking-wider outline-none  rounded  sm:text-sm  flex items-center justify-between "
              placeholder="Write your review.."
            />
            <button
              type="button"
              disabled={reviewLoader}
              className={` mt-5 flex items-center justify-center  px-4 py-2 text-sm  tracking-widest font-medium text-white ${reviewLoader ? "bg-white border-color" : "bg-color"
                } uppercase transition-colors duration-300 transform  rounded lg:w-auto  focus:outline-none `}
              onClick={() => {
                if (localStorage.getItem("userToken|VST")) {
                  if (review.comment && review.review) {
                    addReview();
                  } else {
                    toast.error("Please type comment");
                  }
                } else {
                  navigate("/login");
                }
              }}
            >
              {reviewLoader ? <ApiLoader /> : "Submit Review"}
            </button>
          </div>

          {allReview.length === 0 ? (
            <div className="text-center max-w-xl py-5 text-sm ">
              No review found, please provide your feedback.
            </div>
          ) : (
            <div className="mt-8">
              {allReview.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="container mb-4 shadow-md flex flex-col w-full max-w-xl divide-y rounded-md divide-gray-400 bg-gray-100 text-gray-900"
                  >
                    <div className="flex justify-between p-3">
                      <div className="flex space-x-4">
                        <div>
                          <img
                            src="/Assets/user.png"
                            alt={e.user?.name}
                            className="object-cover w-12 h-12 rounded-full bg-white shadow"
                          />
                        </div>
                        <div>
                          <h4 className="font-bold capitalize">
                            {e.user?.name}
                          </h4>
                          <span className="text-xs dark:text-gray-400">
                            {format(e.createdAt)}
                          </span>
                        </div>
                      </div>

                      <div className="flex items-center text-yellow-400">
                        {Array(e.review)
                          .fill("")
                          .map((item) => {
                            return (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-6 h-6 text-yellow-400"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                              </svg>
                            );
                          })}
                      </div>
                    </div>
                    <div className="p-3 space-y-2 text-sm text-gray-700">
                      <p>{e.comment}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CourseById;
