import React from "react";

const Heading = (props) => {
  return (
    <div>
      <h1 className="md:text-xl text-xl font-semibold  text-gray-800 lg:text-2xl ">
        {props.title}
      </h1>
      <div className="flex  mx-auto mt-1.5">
        <span className="inline-block w-28 md:w-36 h-1 bg-color rounded-full"></span>
        <span className="inline-block w-5 h-1 mx-1 bg-color rounded-full"></span>
        <span className="inline-block w-2  h-1 bg-color rounded-full"></span>
      </div>
    </div>
  );
};

export default Heading;
