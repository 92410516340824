import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Sidebar from "./Sidebar";

const InstructorComponent = (WComponent) => {
  return function Component() {
    const location = useLocation().pathname;
    const contents = [
      { name: "Profile", to: "/instructor/me" },
      { name: "My Course", to: "/instructor/me/enrolled-course" }
    ];
    return (
      <>
        <section className="bg-slate-800 h-[13rem]  md:h-[19rem]  pattern ">
          <div className="container mx-auto flex items-start justify-center flex-col relative h-full md:px-6 p-4">
            <h2 className=" text-slate-100 tracking-wide font-bold text-2xl sm:text-4xl lg:text-[40px]">
              My Account
            </h2>

            <div className="flex py-0.5  absolute bottom-2 md:left-6 left-4 mt-3 gap-y-2 gap-x-4  flex-wrap ">
              {contents.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.to}
                    className={`md:text-base underline text-sm ${
                      item.to === location ? "text-white" : "text-gray-400"
                    }  tracking-widest  cursor-pointer hover:text-white`}
                  >
                    <span>{item.name}</span>
                  </Link>
                );
              })}
            </div>
          </div>
        </section>
        {/* Tab Content */}
        <div className="container min-h-[20rem] mx-auto md:px-6 py-10 p-3">
          <WComponent />
        </div>
      </>
    );
  };
};

export default InstructorComponent;
