import React, { useEffect } from "react";
import { AiFillHeart } from "react-icons/ai";
import { BsArrowRightShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import NoData from "../Components/NoData";
import UserComponent from "../Components/UserComponent";
import { getWishList, removeFav, deleteUserWishList } from "../Redux/Actions/WishlistAction";
import { USER_ID } from "../config";

const BASEURL = process.env.REACT_APP_BASE_URL;

const Wishlist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = JSON.parse(localStorage.getItem("userData|VST"));
  const wish = useSelector((state) => state.WishlistReducer.wishlist.data);
  const dele = (c_id) => {
    const payload = {
      user: USER_ID,
      course: c_id,
    };
    dispatch(removeFav(payload));
  };

  const RemoveUserWishList = (c_id) => {
    const payload = {
      user: USER_ID,
      course: c_id,
    };
    dispatch(deleteUserWishList(payload));
  };

  useEffect(() => {
    dispatch(getWishList(data?._id));
  }, [dispatch, USER_ID]);
  return (
    <section className="py-10">
      {wish?.length > 0 ? (
        <div className="flex flex-wrap -m-3">
          {wish?.map((data) => {
            return (
              <div
                key={data._id}
                className="lg:w-1/4 md:w-1/3 sm:w-1/2 p-4 w-full break-words"
              >
                <section className="h-full w-full ">
                  {/* Image */}
                  <div className="block group mb-2 relative transition ease-out active:opacity-75 overflow-hidden">
                    <img
                      src={`${BASEURL}${data?.course?.image}`}
                      //src="https://images.unsplash.com/photo-1526379095098-d400fd0bf935?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80"
                      alt={`${data?.course?.name.slice(0, 4)}...`}
                      className="transform transition ease-out group-hover:scale-110"
                      style={{height:'150px', width:'100%',margin:'0px'}}
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-25 transition ease-out group-hover:bg-opacity-0"></div>
                    <div className="p-2 flex items-center justify-center absolute top-0 right-0">
                      <AiFillHeart
                        onClick={() => RemoveUserWishList(data?._id)}
                        className="text-red-500 text-2xl  cursor-pointer"
                      />
                    </div>
                  </div>
                  {/* Content */}
                  <div className="">
                    {/* Course Title */}
                    <h3 className=" text-slate-900 capitalize  text-lg font-semibold">
                      {data?.course?.name?.slice(0, 25)}
                      {data?.course?.name?.length > 25 && "..."}
                    </h3>

                    {/* Description */}
                    <p className="text-slate-800 text-[13px]">
                      {data?.course?.description?.slice(0, 80)}
                      {data?.course?.description?.length > 80 && "..."}
                    </p>

                    {/* Learn More */}
                    <div
                      onClick={() => {
                        navigate(`/course/${data?.course?._id}`);
                      }}
                      className="mt-3 text-sm text-color flex items-center cursor-pointer"
                    >
                      Read More
                      <BsArrowRightShort className="text-xl mt-0.5" />
                    </div>
                  </div>
                </section>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className="w-full text-center"
          onClick={() => navigate("/courses")}
        >
          <NoData />
          <Button title="Browse Courses Now" />
        </div>
      )}
    </section>
  );
};

export default UserComponent(Wishlist);
