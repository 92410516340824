import {
    GET_ALL_INSTRUCTOR,SET_ALL_INSTRUCTOR
  } from "../Actions/ActionTypes";
  
  const initialState = {
    
    instructors: [],
    loading: false,
   
  };
  
  const InstructorReducer = (state = initialState, action) => {
    switch (action.type) {
      // GET_COURSE
      case GET_ALL_INSTRUCTOR:
        return { ...state, loading: true };
      case SET_ALL_INSTRUCTOR:
        return { ...state, instructors: action.payload, loading: false };
      
    
      default:
        return state;
    }
  };

  export default InstructorReducer;
  