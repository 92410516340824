import axios from "axios";
import { BASE_URL } from "../../config";
import {GET_LANGUAGE ,SET_LANGUAGE } from "./ActionTypes";

// Courses
export const getLanguage = () => {
  return async (dispatch) => {
    dispatch({ type: GET_LANGUAGE });
    let url = `${BASE_URL}api/language`;
    await axios
      .get(url)
      .then((res) => {
        dispatch({
          type: SET_LANGUAGE,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// export const getInstructor = () => async (dispatch) => {
//   dispatch({ type: GET_ALL_INSTRUCTOR });
//   try {
//     const res = await axios.get(`${BASE_URL}api/instructor`);
//     dispatch({
//       type: SET_ALL_INSTRUCTOR,
//       payload: res.data.data,
//     });
//   } catch (error) {
//     dispatch({ error });
//   }
// };

// export const getInstructor = () => async (dispatch) => {

//   try {
//     dispatch({ type: GET_ALL_INSTRUCTOR });
//     const res = await axios.get(`${BASE_URL}api/instructor`, {

//     });
//     const data = res.data.data;

//     dispatch({
//       type: SET_ALL_INSTRUCTOR,
//       payload: data,
//     });
//   } catch (error) {
//     console.log(error)
//   }
// };