import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../Components/BackButton";
import CourseCard from "../Components/CourseCard";
import Heading from "../Components/Heading";
import NoData from "../Components/NoData";
import { GetCategory, GetLanguages } from "../Redux/Actions/CategoryAction";
import {
  getAllCourseWithFilter,
  getCourse,
  getPopularCourse,
  getTrendingCourse,
} from "../Redux/Actions/CourseAction";

const Filter = ({ data, event, filterName }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    event(filterName, value);
  };
  return (
    <>
      <select
        onChange={handleChange}
        className="outline-none w-48 bg-white text-gray-400 appearance-none tracking-widest rounded text-xs md:text-sm  cursor-pointer  border px-2 py-1 border-color "
      >
        <option value="filterName" selected className="text-sm ">
          {filterName}
        </option>
        {data?.map((item) => {
          return (
            <option key={item._id} className="text-sm" value={item._id}>
              {item.name}
            </option>
          );
        })}
      </select>
    </>
  );
};

const Courses = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [filterObj, setFilterObj] = useState({
    search: '',
    pincode: ''
  });
  const [isFilter, setIsfilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  let courses = useSelector((state) => {
    if (location.state === "All Popular Courses") {
      return state.CourseReducer.popularCourses;
    } else if (location.state === "All Trending Courses") {
      return state.CourseReducer.trendingCourses;
    } else {
      return state.CourseReducer.searchList;
    }

  });
  const languages = useSelector((state) => state.CategoryReducer.languages);
  const categories = useSelector((state) => state.CategoryReducer.categories);

  const handleFilter = (title, value) => {
    if (value !== "") {
      if (title === "Select Langauges") {
        setFilterObj({ ...filterObj, language: value });
      } else {
        setFilterObj({ ...filterObj, category: value });
      }
      // setIsfilter(true);
      // let course = courses.filter((element) => {
      //   return title === "Select Langauges"
      //     ? element.language._id === value
      //     : element.category._id === value;
      // });
      // setFilteredData(course);
    } else {
      setIsfilter(false);
    }
  };

  const termByCourse = () => {
    if (location.state === "All Popular Courses") {
      dispatch(getPopularCourse());
    } else if (location.state === "All Trending Courses") {
      dispatch(getTrendingCourse());
    } else {
      dispatch(getCourse());
    }
  };

  const setInputValue = (e, type) => {
    if (type === 'search') {
      setFilterObj({ ...filterObj, search: e.target.value });
    } else {
      setFilterObj({ ...filterObj, pincode: e.target.value });
    }


  }


  useEffect(() => {
    // console.log("filterObj", { ...filterObj });

    dispatch(getAllCourseWithFilter(filterObj));
  }, [filterObj]);


  useEffect(() => {
    setIsfilter(false);
  }, [courses])


  useEffect(() => {
    termByCourse();
    dispatch(getAllCourseWithFilter(filterObj));
    dispatch(GetLanguages());
    dispatch(GetCategory());
  }, [dispatch]);
  return (
    <section className="container tracking-wider mx-auto mb-10 px-4">
      <section className="py-1">
        <div className="flex mb-1 justify-between items-center  ">
          <Heading title={location.state ? location.state : "All Courses"} />
          <BackButton event={() => navigate(-1)} />
        </div>
        <div className="flex justify-between">
          <div className="flex py-3 justify-start gap-3 ">
            <div>
              <input type="text" value={filterObj.search} onChange={(e) => setInputValue(e, 'search')} style={{ minWidth: '150px' }} id="first_name" className="outline-none w-48 bg-white appearance-none tracking-widest rounded text-xs md:text-sm  cursor-pointer  border px-1 py-1 border-color" placeholder="Search here..." required />
            </div>
            <div>
            <input type="number" value={filterObj.pincode} onChange={(e) => setInputValue(e, 'pincode')} style={{ minWidth: '150px' }} maxLength={'6'} pattern="[0-9]*" id="pincode" className="outline-none bg-white w-48 appearance-none tracking-widest rounded text-xs md:text-sm  cursor-pointer  border px-1 py-1 border-color [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none" placeholder="Enter pincode" required />
            </div>
          </div>
        </div>
      </section>
      <section className="py-2">
        <div className="flex justify-start">
          <div className="flex py-4 justify-start gap-3">
            <Filter
              event={handleFilter}
              data={languages}
              filterName="Select Langauges"
               

            />
            <Filter
              event={handleFilter}
              data={categories}
              filterName="Select Categories"
            />
          </div>
          
        </div>
      </section>
      <div className="flex flex-wrap gap-y-10 -m-4">
        {isFilter || courses.length === 0 && <NoData />}
        {!isFilter && courses.slice(0,10).map((item) => {
          return <CourseCard key={item._id} data={item} />;
        })}
      </div>
    </section>
  );
};

export default Courses;
