import { toast } from "react-hot-toast";
import Button from "../Components/Button";
import { MdFavorite } from "react-icons/md";
import { SiCoursera } from "react-icons/si";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineMenuAlt1, HiOutlineShoppingCart } from "react-icons/hi";
import Marquee from "react-fast-marquee";
import BackButton from "../Components/BackButton";
import { getCart } from "../Redux/Actions/CartAction";
import { GetCategory } from "../Redux/Actions/CategoryAction";
import { INSTRUCTOR_ID, USER_ID } from "../config";
const BASEURL = process.env.REACT_APP_BASE_URL;
const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hamburger, setHamburger] = useState(false);
  let userAuth = localStorage.getItem("userToken|VST");
  const cart = useSelector((state) => state.CartReducer.cart);
  let isInstructorAuth = localStorage.getItem("instructorToken|VST");
  let userData = useSelector((state) => state.AuthReducer.userData);
  let instructorData = useSelector((state) => state.AuthReducer.instructorData);

  const handleClose = () => {
    setHamburger(false);
    userAuth = localStorage.getItem("userToken|VST");
    isInstructorAuth = localStorage.getItem("instructorToken|VST");
  }
  
  const GetCart = () => {
    setHamburger(false);
    userAuth = localStorage.getItem("userToken|VST");
    if (userAuth) {
      dispatch(getCart());
    }
  }
  // get apis
  let id = "";
  useEffect(() => {
    dispatch(GetCategory());
    if (userAuth) {
      dispatch(getCart());
      id = USER_ID;
    }
    else
    {
      id = INSTRUCTOR_ID
    }
  }, [dispatch, id]);

  // Hamburger
  useEffect(() => {
    if (hamburger) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [hamburger]);

  return (
    <section className="z-20 px-4 md:px-1 py-2 sticky top-0 left-0 bg-gray-900">
      {!isInstructorAuth ? (
        <div className="flex container mx-auto justify-between items-center">
          <Link to="/">
            <img
              src="/Assets/VST1.png"
              alt="vst"
              className="sm:w-16 w-14 cursor-pointer"
            />
          </Link>
          {/* <div className="flex container mx-auto  text-white">
            <Marquee >
              This site is under maintenance. Enrollment of new courses will resume soon.
            </Marquee>
          </div> */}
          <div className="flex gap-4 text-orange-900 sm:gap-0 sm:mr-4 ml-auto items-center">
            <HiOutlineMenuAlt1
              onClick={() => setHamburger(!hamburger)}
              className="sm:hidden cursor-pointer text-orange-900 text-2xl
          "
            />
          </div>

          <div
            className={` flex sm:flex-row flex-col sm:static absolute top-0 ${
              hamburger ? "left-0" : "-left-full"
            } bg-white h-screen sm:h-auto justify-center sm:bg-transparent sm:rounded-none transition-all duration-100 rounded-none shadow sm:shadow-none sm:pb-0 pb-5 w-full sm:w-auto items-center gap-8 sm:gap-4`}
          >
            <div className="flex sm:flex-row flex-col gap-4">
              <Link
                to="/courses"
                onClick={handleClose}
                className="flex  cursor-pointer uppercase link items-center sm:flex-row text-orange-300  tracking-wider gap-2"
              >
                <SiCoursera className="text-orange-300" />
                <span className="">Browse all courses</span>
              </Link>
              {userAuth ? (
                <div className="flex sm:flex-row flex-col justify-center sm:text-sm text-orange-300 gap-2 sm:gap-2 items-center tracking-wider">
                  <Link
                    to="/cart"
                    onClick={GetCart}
                    className="flex  cursor-pointer link items-center sm:flex-row flex-col  tracking-wider gap-2"
                  >
                    <span className="relative">
                      <HiOutlineShoppingCart className="text-lg" />
                      {cart.count > 0 && (
                        <span className="w-2 h-2 bg-orange-500 uppercase rounded-full absolute -top-0.5 -right-0.5"></span>
                      )}
                    </span>
                    <span className="uppercase">Cart</span>
                  </Link>
                  <Link
                    to="/me/wishlist"
                    onClick={handleClose}
                    className="flex cursor-pointer uppercase link items-center sm:flex-row flex-col  tracking-wider gap-1"
                  >
                    <MdFavorite className="text-lg" />
                    <span className="">Wishlist</span>
                  </Link>
                  <div className="flex items-center gap-2">
                    <Link to="/me" onClick={handleClose} className="link">
                      <img
                        src={`${BASEURL}${userData?.userpic}`}
                        alt="user"
                        className="w-7 h-7 rounded-full border-color bg-white shadow"
                      />
                    </Link>
                    <span
                      onClick={() => {
                        localStorage.clear();
                        handleClose();
                        navigate("/");
                        window.location.reload();
                        setHamburger(false);
                      }}
                      className="cursor-pointer uppercase font-medium text-red-500 pl-3 border-l border-gray-500"
                    >
                      LogOut
                    </span>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-1 sm:flex-row">
                  <Button
                    title="Student Login"
                    event={() => {
                      navigate("/login");
                      handleClose();
                    }}
                  />
                  {/* <Button
                    title="Signup"
                    event={() => {
                      navigate("/register");
                      handleClose();
                    }}
                  /> */}
                </div>
              )}
            </div>

            {/* Close */}
            <div className="sm:hidden absolute top-8 right-5 text-3xl">
              <BackButton event={handleClose} />
            </div>
          </div>
        </div>
      ) : (
        <header className=" text-sm container mx-auto flex items-center justify-between">
          <Link to={"/instructor"} className="">
            <img
              src="/Assets/VST1.png"
              alt="vst"
              className="sm:w-16 w-14 cursor-pointer"
            />
          </Link>
          <div className="flex  text-sm items-center  gap-4">
            <div className="flex items-center gap-3 tracking-wider">
              <Link to="/instructor/me"> 
                {" "}
                <img
                  src={`${BASEURL}${instructorData?.profpic}`}
                  alt="instructor"
                  className="w-7 h-7 rounded-full border-color bg-white shadow"
                />
              </Link>
              <span
                onClick={() => {
                  localStorage.clear();
                  handleClose();
                  navigate("/");
                  window.location.reload();
                  toast.success("Logout Success");
                }}
                className="cursor-pointer uppercase font-medium text-red-500 pl-2 border-l border-gray-500"
              >
                LogOut
              </span>
            </div>
          </div>
        </header>
      )}
    </section>
  );
};

export default Header;
