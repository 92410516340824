// import Logo from "../Components/Logo";
import React, { useState } from "react";
import { TfiEmail } from "react-icons/tfi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotInsPass } from "../../Redux/Actions/AuthAction";
import { toast } from "react-hot-toast";
import ApiLoader from "../../Components/ApiLoader";
import InstructorLogin from "./InstructorLogin";
import Button from "../../Components/Button";

const ForgotInsPass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.AuthReducer);
  const [formValues, setFormValues] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [step, setStep] = useState(0);
  const [showComponentB, setShowComponentB] = useState(false);

  const handleClick = () => {
    setShowComponentB(true);
  };


  // handleChange
  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  // handelSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formValues.email) {
      dispatch(forgotInsPass(formValues));
    } else toast.error("Please fill field");
  };

  return (
    <>
      <div className="flex lg:h-screen lg:flex-row flex-col  bg-white w-full lg:bg-gray-50 lg:gap-5 items-center">
        <figure className=" lg:h-full h-80 md:h-96 w-full  lg:w-1/3 flex ">
          <img
            src="Assets/login-bg.jpg"
            className="h-full object-cover w-full"
            alt="register"
          />
        </figure>
        <section className="lg:w-2/3 my-4  w-full lg:p-4 sm:w-4/5 md:w-3/4 mx-auto lg:px-20">
          <div className="lg:p-10 p-5 bg-white rounded lg:shadow">
            {/* <Logo/> */}
            <h3 className="text-lg mb-5 uppercase font-bold">
              Forgot your password
            </h3>
            <form onSubmit={handleSubmit}>
              {/* Email */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="email">
                  <TfiEmail className="text-gray-500 text-xl" />
                </label>
                <input
                  type="email"
                  name="email"
                  // required
                  value={formValues?.email}
                  onChange={handleChange}
                  className="outline-none px-3 w-full text-sm"
                  placeholder="Your Email Address"
                />
              </div>

              {/* Submit */}
              <div
                className={` mb-5 border hover:bg-transparent hover:text-color ${data.loading ? "bg-transparent" : "bg-color"
                  }  text-white cursor-pointer border-color text-center hover:text-black rounded flex items-center gap-3`}
              >
                <button
                  type="submit"
                  disabled={data.loading}
                  className="w-full p-2 uppercase cursor-pointer text-center"
                >
                  {data.loading ? <ApiLoader /> : "Generate New Pasword"}
                </button>
              </div>
              {/* Login Text */}

            </form>
            <div className=" flex items-center gap-1.5">
              <span className="text-gray-500">I remember password ,</span>
              <div className="">
                <Button
                  title="Login"
                  event={() => setShowForm(true)}
                />
                {showForm && (
                  <div
                    id="showForm"
                    className="fixed top-0 overflow-hidden z-30 left-0 flex justify-center items-center h-screen bg-[rgb(0,0,0,0.8)] w-full"
                  >
                    <div className="bg-white lg:w-1/2 md:w-2/3 sm:w-3/4 w-5/6 rounded p-4">
                      {step === 0 ? (
                        <InstructorLogin
                          handleStep={() => setStep(0)}
                          event={() => {
                            setShowForm(false);
                            setStep(0);
                          }}
                        />
                      ) : " "}
                    </div>
                  </div>
                )}
              </div>
              {/* <Link to="/ins-login" className="text-color  ">
                  Login
                </Link> */}
              {/* <button onClick={handleClick}>Show Component B</button>
                {showComponentB ? <InstructorLogin /> : ""} */}



            </div>
          </div>

        </section>
      </div>

    </>
  );
};

export default ForgotInsPass;
