import React from "react";
import Heading from "./Heading";
import BackButton from "./BackButton";
import { useNavigate } from "react-router-dom";

const TopBar = (props) => {
  const navigate = useNavigate();
  return (
    <div className="flex py-5 pb-3 border-b mb-5 items-end justify-between">
      <Heading title={props.title} />
      {props.title !== "Shopping Cart" && (
        <BackButton event={() => navigate(props.path)} />
      )}
    </div>
  );
};

export default TopBar;
