import React from "react";

const RefundPolicy = () => {
  return (
    <section className="container mx-auto sm:px-0 px-1 py-1 ">
      <h4 className="text-2xl md:text-3xl xl:text-4xl px-1 py-3 tracking-wider font-semibold">
      Cookie Policy
      </h4>
      <div className="tracking-wider space-y-2.5 sm:py-5 px-1 py-4 md:py-7">
        <hr/>

        This Cookie Policy describes the different types of Cookies that may be applied on its platform. We may change this Cookie Policy at any time to reflect, for example, changes to the Cookies we use or for other operational, legal or regulatory reasons. 
If you have questions regarding this Cookie Policy, you can contact us by emailing us at support@verysimilartests.com
The word Platform shall mean and include the website “www.verysimilartests.com”


<hr/>

<br/><b>What is a Cookie?</b>

<br/>If you agree, a cookie is a small file of letters and numbers that we store on your browser or your computer's hard drive. By continuing to browse the site, you agree to our use of Cookies. Cookies contain information that is transferred to your computer’s hard drive. You can set your browser to refuse all or some browser Cookies or to alert you when Platforms set or access Cookies. If you disable or refuse cookies, please note that some parts of this Platform may become inaccessible or malfunction. 
<hr/>

<br/><b>What Cookies do we use?</b>
<br/>1.	<b>Strictly Necessary Cookies:</b> These are those required for our Platform's operation. They include, for example, Cookies that enable you to log into secure areas of our Platform, use a shopping cart or use e-billing services.
<br/>2.	<b>Analytical/Performance Cookies:</b> They allow us to recognise and count the number of visitors and see how visitors move around our Platform when using it. This helps us improve how our Platform works, for example, by ensuring that users find what they are looking for easily.
<br/>3.	<b>Functionality Cookies:</b> When you return to our Platform, these recognise you. This enables us to personalisepersonalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
<br/>4.	<b>Targeting Cookies:</b> These Cookies record your visit to our Platform, the pages you have visited and the links you have followed. We will use this information to make our Platform and its advertising more relevant to your interests. We may also share this information with third parties for this purpose.
Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use Cookies, over which we have no control. These Cookies are likely to be analytical/performance Cookies or targeting Cookies. You can block Cookies by activating the setting on your browser that allows you to refuse the setting of all or some Cookies. However, if you use your browser settings to block all Cookies (including essential Cookies), you may be unable to access all or parts of our Platform.

<hr/>

<br/><b>What kind of cookies and other technologies do we use?</b>
<br/>1.	<b>Persistent cookies:</b> These cookies are stored in your device's browser and used whenever you visit our Platform. These cookies help us remember your settings and preference(s) to make your next visit more convenient. For instance, these cookies will ensure you will not have to log in again. It will also remember the users' language preferences on their next visit.
<br/>2.	<b>Session cookies:</b> These are temporary cookies that remain in the cookies archive of your browser until you leave our Platform. The information we receive using these cookies helps us to analyse web traffic patterns, enabling us to identify and troubleshoot problems and provide a better navigation experience.

<br/>3.  <b>Advertising cookies:</b> These cookies help us provide relevant ads to measure the performance of the advertisers’ campaign by analysing your interaction with the advertiser’s ads. For example, it helps us determine the number of times users have clicked on an advertiser’s ad and have visited the advertiser’s website.

<br/>4.  <b>Web beacons:</b> these are tiny graphic images (also known as “pixel tags” or “clear GIFs”). We use web beacons and cookies to identify our users and their behaviour. 

<br/>5.  <b>Device fingerprinting:</b>This technique combines information we collect from the device from which our users access our Services. When you browse our website or use our mobile app, we obtain certain information from and in relation to your devices and their configuration, such as your IP address, the OLX country domain you are using, your device language settings, your device brand and type, your device operating system and version, browser type and version, and device-specific software information. Based on this user device information, we create a specific device fingerprint. The device-related information and fingerprint we obtain are not unique. We are instead a set of information indicating particular information about the device being used to access our Services at a particular moment. We cannot identify a user on the sole basis of the collected device information. This device-related information enables us to associate user accounts with a respective fraud score based on a partly automated process, using algorithms in combination with human review and moderation. If the fraud score indicates a specific user account is likely fraudulent, we will ban the respective user and the account. The information we obtain in this process is used solely for the purposes of customer safety and security and fraud prevention and is subject to highly restrictive access rights for selected customer safety and security specialists at OLX. We do not share this information with third parties. This device fingerprinting technique is necessary to keep up with the constantly changing developments in online fraud and to ensure our Services are safe and secure for our users. If you think your account is wrongfully banned, please see our Privacy Statement.
<br/><br/>Our Platform may contain links to third-party websites or applications, including those of our partners. Please note those third-party websites may also use cookies. We do not control those third-party websites and are not responsible for the cookies they set or access. If you click on one of these links or apps, please note that each will have its cookies policy. Therefore, please read the cookies policy of other Internet sites or apps before using them.
<br/><br/><b>GOOGLE ANALYTICS</b>
<br/>We use Google Analytics to help us understand how you use our content and work out how we can make things better. These Cookies may follow all visitors of the Platform and collect anonymous data on where they have come from, which pages they visit, and how long they spend on the site. Google then stores this data to create reports. These cookies do not store personal data or business data. The information generated by the Cookie about the use of the Platform, including the IP address, may be transmitted to and stored by Google on servers in the United States. Google may use this information to evaluate the visitor’s use of the Platform, compiling reports on Platform activity for us and providing other services relating to Platform activity and internet usage. Google may also transfer this information to third parties where required by law or where such third parties process the information on Google's behalf. Google will not associate any IP address with any other data held by Google. By using this Platform, all visitors consent to the processing of data by Google in the manner and for the purposes set out above. The Google Platform contains further information about Analytics and a copy of Google's Privacy policy pages.
<br/><br/><b>How do I manage Cookies?</b>
<br/>You can choose whether to accept our Cookies (except Strictly Necessary Cookies) by clicking on the appropriate opt-out links in our Cookie Preference Center, which can be found in our Site’s footer and Cookie banner. You also have the right to refuse or accept Cookies at any time by activating the settings on your browser. You may wish to refer to https://verysimilartests.com/ for information on commonly used browsers. 
Blocking some types of Cookies may impact your experience on our Site and the services we offer you.
<br/><br/><b>What can you do to delete, disable or block cookies?</b>
<br/>Your browser usually provides information on refusing, deleting, or blocking cookies. The following links will take you to that information on some of the commonly used browsers:
<br/>●	Google Chrome
<br/>●	Mozilla Firefox
<br/>●	Safari
<br/>●	Opera
<br/>You may opt out of third-party cookies from Google Analytics through its ads settings or https://adssettings.google.com
In addition, you can change your mobile device settings to control whether you see interest-based advertisements.
<br/><br/>Please note
<br/>If you choose to block the cookies we set on our Platform, you may not be able to access all or part of our services; for example, you may not be able to save customised settings such as login information.
</div>
</section>
);
};

export default RefundPolicy;
