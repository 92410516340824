import {
  FORGOT_PASS,
  FORGOT_PASS_FAIL,
  FORGOT_PASS_SUCCESS,
  INSTRUCTOR_PROFILE,
  INSTRUCTOR_PROFILE_FAIL,
  INSTRUCTOR_PROFILE_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  UPDATE_PASS,
  UPDATE_PASS_FAIL,
  UPDATE_PASS_SUCCESS,
  UPDATE_USER_PROFILE_SUCCESS,
  USER_PROFILE,
  USER_PROFILE_FAIL,
  USER_PROFILE_SUCCESS,
} from "../Actions/ActionTypes";
let initialState = {
  loading: false,
  userData: {},
  instructorData: {},
};

const AuthReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // REGISTER
    case REGISTER:
      return { ...state, loading: true };

    case REGISTER_SUCCESS:
      return { ...state, loading: false };

    case REGISTER_FAIL:
      return { ...state, loading: false };

    // LOGIN
    case LOGIN:
      return { ...state, loading: true };

    case LOGIN_SUCCESS:
      return { ...state, userData: actions.payload, loading: false };

    case LOGIN_FAIL:
      return { ...state, loading: false };

    // USER_PROFILE
    case USER_PROFILE:
      return { ...state };

    case USER_PROFILE_SUCCESS:
      return { ...state, userData: actions.payload };

    case USER_PROFILE_FAIL:
      return { ...state };

    // INSTRUCTOR_PROFILE
    case INSTRUCTOR_PROFILE:
      return { ...state };

    case INSTRUCTOR_PROFILE_SUCCESS:
      return { ...state, instructorData: actions.payload };

    case INSTRUCTOR_PROFILE_FAIL:
      return { ...state };

    // FORGOT_PASS
    case FORGOT_PASS:
      return { ...state, loading: true };

    case FORGOT_PASS_SUCCESS:
      return { ...state, loading: false };

    case FORGOT_PASS_FAIL:
      return { ...state, loading: false };

    // UPDATE_PASS
    case UPDATE_PASS:
      return { ...state, loading: true };

    case UPDATE_PASS_SUCCESS:
      return { ...state, loading: false };

    case UPDATE_PASS_FAIL:
      return { ...state, loading: false };

    // INSTRUCTOR
    case UPDATE_USER_PROFILE_SUCCESS:
      return { ...state, userData: actions.payload };

    default:
      return state;
  }
};

export default AuthReducer;
