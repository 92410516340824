import axios from "axios";
import { JitsiMeeting } from "@jitsi/react-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const BASEURL = process.env.REACT_APP_BASE_URL;
const MeetUrl = "https://meet.jit.si/";

const GotoVSTMeet = () => {
  const navigate = useNavigate();
  const data = localStorage.getItem("userData|VST");

  const SessionId = useParams().id;
  const [sessionData, setSessionData] = useState([]);
  const getSession = async () => {
    try {
      const res = await axios.get(`${BASEURL}api/session/${SessionId}`);
      setSessionData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  const apiRef = useRef();
  const [logItems, updateLog] = useState([]);
  const [showNew, toggleShowNew] = useState(false);
  const [knockingParticipants, updateKnockingParticipants] = useState([]);

  const printEventOutput = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
  };

  const handleAudioStatusChange = (payload, feature) => {
    if (payload.muted) {
      updateLog((items) => [...items, `${feature} off`]);
    } else {
      updateLog((items) => [...items, `${feature} on`]);
    }
  };

  const handleChatUpdates = (payload) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    apiRef.current.executeCommand("toggleChat");
    updateLog((items) => [
      ...items,
      `you have ${payload.unreadCount} unread messages`,
    ]);
  };

  const handleKnockingParticipant = (payload) => {
    updateLog((items) => [...items, JSON.stringify(payload)]);
    updateKnockingParticipants((participants) => [
      ...participants,
      payload?.participant,
    ]);
  };

  const resolveKnockingParticipants = (condition) => {
    knockingParticipants.forEach((participant) => {
      apiRef.current.executeCommand(
        "answerKnockingParticipant",
        participant?.id,
        condition(participant)
      );
      updateKnockingParticipants((participants) =>
        participants.filter((item) => item.id === participant.id)
      );
    });
  };

  const handleJitsiIFrameRef1 = (iframeRef) => {
    iframeRef.style.border = "10px solid #3d3d3d";
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "100vh";
    // iframeRef.style.marginBottom = "20px";
  };

  const handleJitsiIFrameRef2 = (iframeRef) => {
    iframeRef.style.marginTop = "10px";
    iframeRef.style.border = "10px dashed #df486f";
    // iframeRef.style.padding = "5px";
    iframeRef.style.height = "100vh";
  };

  const handleJaaSIFrameRef = (iframeRef) => {
    iframeRef.style.border = "10px solid #3d3d3d";
    iframeRef.style.background = "#3d3d3d";
    iframeRef.style.height = "100vh";
  };

  const handleApiReady = async (apiObj) => {
    apiRef.current = apiObj;
    apiRef.current.on("knockingParticipant", handleKnockingParticipant);
    apiRef.current.on("audioMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "audio")
    );
    apiRef.current.on("videoMuteStatusChanged", (payload) =>
      handleAudioStatusChange(payload, "video")
    );
    apiRef.current.on("raiseHandUpdated", printEventOutput);
    apiRef.current.on("titleViewChanged", printEventOutput);
    apiRef.current.on("chatUpdated", handleChatUpdates);
    apiRef.current.on("knockingParticipant", handleKnockingParticipant);

    // Costum own logic
    const payload = {
      session: sessionData._id,
      user: data._id,
    };
    try {
      const res = await axios.post(`${BASEURL}api/session-log`, payload);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReadyToClose = async () => {
    /* eslint-disable-next-line no-alert */
    // toast.success("Ready to close...");
    const payload = {
      session: sessionData._id,
      user: data._id,
    };
    try {
      const res = await axios.delete(
        `${BASEURL}api/session-log-student`,
        payload
      );
      // console.log(res);
      navigate(`/user`);
    } catch (error) {
      console.log(error);
    }
  };

  const generateRoomName = () =>
    `JitsiMeetRoomNo${Math.random() * 100}-${Date.now()}`;

  // Multiple instances demo
  const renderNewInstance = () => {
    if (!showNew) {
      return null;
    }

    return (
      <JitsiMeeting
        roomName={generateRoomName()}
        getIFrameRef={handleJitsiIFrameRef2}
      />
    );
  };

  const renderSpinner = () => (
    <div
      style={{
        fontFamily: "sans-serif",
        textAlign: "center",
      }}
    >
      Loading..
    </div>
  );
  var interfaceConfig = {
    SHOW_BRAND_WATERMARK: false,
    SHOW_WATERMARK_FOR_GUESTS: false,
  };
  return (
    <div className="fixed top-0 left-0 overflow-y-auto bg-gray-800 w-full h-screen z-50">
      <JitsiMeeting
        userInfo={{
          displayName: data?.name,
        }}
        roomName={sessionData?.link}
        domain={MeetUrl}
        spinner={renderSpinner}
        config={{
          subject: "lalalala",
          hideConferenceSubject: false,
        }}
        onApiReady={(externalApi) => handleApiReady(externalApi)}
        onReadyToClose={handleReadyToClose}
        getIFrameRef={handleJitsiIFrameRef1}
        interfaceConfig={interfaceConfig}
      />
    </div>
  );
};

export default GotoVSTMeet;
