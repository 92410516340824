import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../Components/TopBar";
import CartItem from "../Components/CartItem";
import { getCart } from "../Redux/Actions/CartAction";
import Checkout from "../Components/Checkout";
import Button from "../Components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../config";
import axios from "axios";

const Transaction = () => {
  const id = useParams().transactionId;
  // alert(id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState({});
  // const cartData = useSelector((state) => state.CartReducer.cart);

  const getOrder = async() =>{
    axios.get(BASE_URL+'api/order/transaction/'+id, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    }).then(async(response) => {
      console.log("response", response);
      if(response.data && !response.data.error){
        setOrder(response.data.data)
      }
    })
    .catch((error) => {
        console.log(error);
    })
  };

  useEffect(() => {
    getOrder(id);
  }, [id]);
  return (
    <>
      <div className="container min-h-screen md:px-6 p-4 tracking-wider mx-auto">
        <TopBar title="Payment Status" path={-1}/>
        {order && order.id === 0 ? (
          <section className="p-6 mb-8  flex flex-col items-center rounded-md shadow-md">
            <div className="w-[250px] md:w-[350px]">
              <img src="/Assets/cart.png" alt="cart" className="w-full" />
            </div>
            <p className=" sm:text-base  text-sm my-3 text-slate-600 text-center  w-full">
              You have not selected any course yet. Keep searching a course suitable to you. !!
            </p>
            <Button event={() => navigate("/courses")} title="Keep Learning" />
          </section>
        ) : (
          <div className="flex mt-5 lg:flex-row gap-10 justify-between flex-col">
            <div className="flex-col w-full">
              <ul className="flex flex-col divide-y divide-gray-200">
              <li className="flex capitalize flex-col sm:flex-row sm:justify-between">
                <div className="flex w-full">
                  <img
                    className="flex-shrink-0 object-cover w-20 h-20 dark:border-transparent rounded outline-none sm:w-36 sm:h-36 dark:bg-gray-500"
                    src={`${BASE_URL}${order?.course?.image}`}
                    alt="Set of travel chargers"
                  />
                  <div className="flex flex-col justify-between w-full pb-4" style={{paddingLeft: '15px'}}>
                    <div className="flex justify-between w-full pb-2 space-x-2">
                      <div className="space-y-1">
                        <h3 className="text-color font-semibold  sm:pr-8">
                          {order?.course?.name}
                        </h3>
                        <p className="text-sm  dark:text-gray-600">
                          Status : {order?.payment_status}
                        </p>
                        <p className="text-sm dark:text-gray-600">
                          Receipt Id : {order?.receiptid}
                        </p>
                        <p className="text-sm dark:text-gray-400">{order?.language}</p>
                      </div>

                      <div className="text-right">
                        <p className=" font-semibold">Sub Total: ₹{order?.sub_price}</p>
                        <p className=" font-semibold">CGST: ₹{order?.cgst}</p>
                        <p className=" font-semibold">SGST: ₹{order?.sgst}</p>
                        <p className=" font-semibold">Total: ₹{order?.price}</p>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      {order?.sections &&
                        order?.sections.map((sbCourse, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="flex py-2 px-4 shadow-sm border border-gray-100 rounded-sm text-sm mb-2 bg-gray-50  capitalize justify-between items-center"
                              >
                                <p>
                                  {index + 1}. {sbCourse?.name}
                                </p>
                                <p className="flex items-center gap-3">
                                  {" "}
                                  {sbCourse?.price && `₹ ${sbCourse?.price} /-`}
                                  {/* <button
                                    type="button"
                                    className="flex text-red-600 items-center px-2 py-1 pl-0 space-x-1"
                                    //onClick={() => deleteBySubCourse(sbCourse._id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      className="w-4 h-4 fill-current"
                                    >
                                      <path d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z"></path>
                                      <rect
                                        width="32"
                                        height="200"
                                        x="168"
                                        y="216"
                                      ></rect>
                                      <rect
                                        width="32"
                                        height="200"
                                        x="240"
                                        y="216"
                                      ></rect>
                                      <rect
                                        width="32"
                                        height="200"
                                        x="312"
                                        y="216"
                                      ></rect>
                                      <path d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"></path>
                                    </svg>
                                  </button> */}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    </div>

                    {/* <div className="flex text-sm divide-x">
                      <button
                        type="button"
                        className="flex items-center px-2 py-1 pl-0 space-x-1"
                        // onClick={() => deleteCart(data._id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-4 h-4 fill-current"
                        >
                          <path d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z"></path>
                          <rect width="32" height="200" x="168" y="216"></rect>
                          <rect width="32" height="200" x="240" y="216"></rect>
                          <rect width="32" height="200" x="312" y="216"></rect>
                          <path d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"></path>
                        </svg>
                        <span>Remove the course and select subcourse(s) to enroll. </span>
                      </button>
                      <button
                        type="button"
                        // onClick={() => addFavourite()}
                        className="flex items-center px-2 py-1 space-x-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-4 h-4 fill-current"
                        >
                          <path d="M453.122,79.012a128,128,0,0,0-181.087.068l-15.511,15.7L241.142,79.114l-.1-.1a128,128,0,0,0-181.02,0l-6.91,6.91a128,128,0,0,0,0,181.019L235.485,449.314l20.595,21.578.491-.492.533.533L276.4,450.574,460.032,266.94a128.147,128.147,0,0,0,0-181.019ZM437.4,244.313,256.571,425.146,75.738,244.313a96,96,0,0,1,0-135.764l6.911-6.91a96,96,0,0,1,135.713-.051l38.093,38.787,38.274-38.736a96,96,0,0,1,135.765,0l6.91,6.909A96.11,96.11,0,0,1,437.4,244.313Z"></path>
                        </svg>
                        <span>Add to favorites</span>
                      </button>
                    </div> */}
                  </div>
                </div>
              </li>
              </ul>
            </div>
            {/* <Checkout data={cartData} /> */}
          </div>
        )}
      </div>
    </>
  );
};

export default Transaction;
