import React from "react";

const About = () => {
  return (
    <>
      {/* Vision and mission  */}
      <section className="container mx-auto  px-4 py-16 ">
        <main className="grid md:grid-cols-2 grid-cols-1   ">
          <div className="">
            <p className="text-justify md:text-base text-sm tracking-wider text-black">
            <b>WELCOME TO VERYSIMILARTESTS ONLINE LEARNING PLATFORM!</b>
            <br></br>
            <p>
            <br></br>
            <ul>
            We are a community of passionate educators and learners, dedicated to providing
            high-quality, affordable, and accessible education to everyone.
            Our mission is to empower people to achieve their goals,
            whether that's advancing their career, developing new skills,
            or simply pursuing a passion.&nbsp;
            </ul>
            </p>
            <br></br>
            <p>
            <ol>
            VerySimilarTests or VSTApp is our first e-learning platform under StarDataInsights company.            Our platform offers a wide range of courses on a variety of subjects,
            taught by experts in their fields. From programming to competitive
            exam preparation, from academics to digital marketing, from data
            science, cloud computing to corporate trainings, there's something
            for everyone. We believe that learning should be engaging,
            interactive, and fun, and we strive to make our courses as
            interactive and engaging as possible.
            </ol>
            </p>
            <br></br>
            <ul>
            We are committed to provide interactive quality education to our learners,
             regardless of their background or location,and we believe for every  
            teacher can enlighten the knowledge of a student with value education 
            and for every student there is a teacher who can teach right and show 
            direction. We offer flexible session plans for instructors and flexible 
            payment plans for learners. Our courses are also available in multiple languages,
            so that everyone can benefit from the knowledge and
            expertise of our instructors.
            </ul>

            <br></br>

            <ul>
            We are constantly striving to improve our platform and our courses,and
            we welcome feedback from our community.We believe that 
            everyone has something to teach and something to learn, and 
            we are excited to be part of a community that is dedicated 
            to lifelong learning.
            </ul>
            <br></br>
            <ul>
            HAPPY LEARNING!!
            </ul>            
            </p>
          </div>
          <figure className="p-6 rounded md:flex  items-center hidden relative">
            <img
              alt="about"
              src="Assets/login-bg-2.jpg"
              className="w-full h-full rounded shadow object-cover"
            />
          </figure>
        </main>
      </section>
      {/* Our Partners 
      <section className="container xl:max-w-7xl mx-auto px-2 py-2 lg:px-2 lg:py-10">
        <div className="text-center">
          <h2 className="text-3xl md:text-4xl font-extrabold mb-4">
            Our Partners
          </h2>
          <h3 className="text-lg md:text-xl md:leading-relaxed font-medium text-gray-600 lg:w-2/3 mx-auto">
            They are working nonstop behind the scenes to provide a better e-learning platform.
          </h3>
        </div>
        <div className="container  mx-auto py-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  text-gray-400">
            <div className="px-2 h-28 flex items-center  justify-center">
              <div className="inline-flex items-center space-x-2 text-2xl font-semibold">
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hi-solid hi-cube inline-block w-8 h-8"
                >
                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                </svg>
                <span>USDServices</span>
              </div>
            </div>
            <div className="px-2 h-28 flex items-center  justify-center">
              <div className="inline-flex items-center space-x-2 text-2xl font-light">
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hi-solid hi-code inline-block w-8 h-8"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>Disha Technologies</span>
              </div>
            </div>
            <div className="px-2 h-28 flex items-center  justify-center">
              <div className="inline-flex items-center space-x-2 text-2xl font-bold">
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hi-solid hi-cursor-click inline-block w-8 h-8"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>MSG91</span>
              </div>
            </div>
            <div className="px-2 h-28 flex items-center  justify-center">
              <div className="inline-flex items-center space-x-2 text-3xl">
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hi-solid hi-location-marker inline-block w-10 h-10"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span>CashFree</span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Founders and Co-founder */}
      <div className="space-y-16 container xl:max-w-7xl mx-auto px-4 py-10 lg:px-8">
        {/* Heading 
        <div className="text-center">
          <h2 className="text-3xl md:text-4xl font-extrabold mb-4">
            STRUCTURE OF OUR ORGANIZATION
          </h2>
        </div>
        {/* END Heading */}

        {/* Team 
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-16 text-center">
          <div>
            <span className="inline-block rounded-full bg-white shadow-lg p-2 mb-5">
              <img
                src="https://cdn.tailkit.com/media/placeholders/avatar-bY4GXQKfZrA-320x320.jpg"
                alt="User Avatar"
                className="inline-block w-28 h-28 rounded-full"
              />
            </span>
            <h4 className="text-xl font-semibold mb-1">Irma Norton</h4>
            <p className="text-gray-600 font-medium">Founder &amp; CEO</p>
          </div>
          <div>
            <span className="inline-block rounded-full bg-white shadow-lg p-2 mb-5">
              <img
                src="https://cdn.tailkit.com/media/placeholders/avatar-iFgRcqHznqg-320x320.jpg"
                alt="User Avatar"
                className="inline-block w-28 h-28 rounded-full"
              />
            </span>
            <h4 className="text-xl font-semibold mb-1">Alejandro Lee</h4>
            <p className="text-gray-600 font-medium">Product Design</p>
          </div>
          <div>
            <span className="inline-block rounded-full bg-white shadow-lg p-2 mb-5">
              <img
                src="https://cdn.tailkit.com/media/placeholders/avatar-mEZ3PoFGs_k-320x320.jpg"
                alt="User Avatar"
                className="inline-block w-28 h-28 rounded-full"
              />
            </span>
            <h4 className="text-xl font-semibold mb-1">Elsa King</h4>
            <p className="text-gray-600 font-medium">Web Developer</p>
          </div>
          <div>
            <span className="inline-block rounded-full bg-white shadow-lg p-2 mb-5">
              <img
                src="https://cdn.tailkit.com/media/placeholders/avatar-sibVwORYqs0-320x320.jpg"
                alt="User Avatar"
                className="inline-block w-28 h-28 rounded-full"
              />
            </span>
            <h4 className="text-xl font-semibold mb-1">Alex Saunders</h4>
            <p className="text-gray-600 font-medium">Marketing</p>
          </div>
          <div>
            <span className="inline-block rounded-full bg-white shadow-lg p-2 mb-5">
              <img
                src="https://cdn.tailkit.com/media/placeholders/avatar-euZ2n8dGUcQ-320x320.jpg"
                alt="User Avatar"
                className="inline-block w-28 h-28 rounded-full"
              />
            </span>
            <h4 className="text-xl font-semibold mb-1">Herman Reese</h4>
            <p className="text-gray-600 font-medium">Support Specialist</p>
          </div>
          <div>
            <span className="inline-block rounded-full bg-white shadow-lg p-2 mb-5">
              <img
                src="https://cdn.tailkit.com/media/placeholders/avatar-DLKR_x3T_7s-320x320.jpg"
                alt="User Avatar"
                className="inline-block w-28 h-28 rounded-full"
              />
            </span>
            <h4 className="text-xl font-semibold mb-1">Sue Keller</h4>
            <p className="text-gray-600 font-medium">Web Developer</p>
          </div>
        </div>
         END Team */}
      </div>
    </>
  );
};

export default About;
