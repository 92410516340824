import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL, USER_ID } from "../../config";
import {
  ADD_ORDER,
  ADD_ORDER_FAILURE,
  ADD_ORDER_SUCCESS,
  DELETE_BY_SUBCOURSE,
  DELETE_BY_SUBCOURSE_FAILURE,
  DELETE_BY_SUBCOURSE_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_FAILURE,
  DELETE_ORDER_SUCCESS,
  GET_ORDER,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
} from "./ActionTypes";

// Get Order
export const getOrder = (id) => async (dispatch) => {
  dispatch({ type: GET_ORDER });
  try {
    const res = await axios.get(`${BASE_URL}api/order/user/${id}`, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    });

    console.log("resres", res)
    
    const data = res.data.data;

    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: GET_ORDER_FAILURE });
  }
};

// Add Order
export const addOrder = (payload, callback) => async (dispatch) => {
  dispatch({ type: ADD_ORDER });
  try {
    // done bro
    const res = await axios.post(`${BASE_URL}api/order`, payload, {
      headers: {
        token: localStorage.getItem("userToken|VST"),
      },
    });

   
    if (res.status === 200) {
      toast.success(res.data.message);
      if (res.data.message !== "Order already exists") {
        dispatch({
          type: ADD_ORDER_SUCCESS,
          payload: { data: res.data, count: res.data.data.count },
        });
      }

      return callback();
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ADD_ORDER_FAILURE });
  }
};

// Delete Order
export const deleteOrderItem = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ORDER });
    try {
      const res = await axios.post(`${BASE_URL}api/order/course`, payload, {
        headers: {
          token: localStorage.getItem("userToken|VST"),
        },
      });
      toast.success(res.data.message);
      dispatch({
        type: DELETE_ORDER_SUCCESS,
        payload: payload.course,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_ORDER_FAILURE });
    }
  };
};

// Delete order by subcourse
export const deleteBySbCourse = (payload) => async (dispatch) => {
  dispatch({ type: DELETE_BY_SUBCOURSE });
  try {
    let res = await axios.post(
      `${BASE_URL}api/order/subcourse`,
      payload.send.data,
      {
        headers: {
          token: localStorage.getItem("userToken|VST"),
        },
      }
    );
    if (res.status === 200) {
      toast.success("Course Deleted Successfully");
      dispatch({
        type: DELETE_BY_SUBCOURSE_SUCCESS,
        payload: payload.send.data.subcourse,
      });
      return payload.send.callback();
    }
  } catch (error) {
    dispatch({ type: DELETE_BY_SUBCOURSE_FAILURE });
  }
};
