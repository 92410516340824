import React, { useState } from "react";
import { SiGmail } from "react-icons/si";
import { FaLock } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { instructorLogin } from "../../Redux/Actions/AuthAction";
import { forgotInsPass } from "../../Redux/Actions/AuthAction";
import { Link, useNavigate } from "react-router-dom";
import ApiLoader from "../../Components/ApiLoader";
import axios from "axios";

const BASEURL = process.env.REACT_APP_BASE_URL;

const InstructorLogin = (props) => {
  const [formInputs, setFormInputs] = useState({});
  const [emailVerify, setEmailVerify] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.AuthReducer.loading);
  const handleChange = (e) => {
    setFormInputs({ ...formInputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formInputs.email || !formInputs.password) {
      toast.error("Please fill all fields");
    } else {
      dispatch(
        instructorLogin(
          formInputs,
          () => {
            navigate("/instructor");
            props.event();
            document.body.style.overflow = "auto";
          },
          () => setEmailVerify(true)
        )
      );
    }
  };

  const sendEmailVerification = async () => {
    let url = `${BASEURL}api/auth/verify-email`;

    try {
      const res = await axios.put(url, {
        email: formInputs.email,
      });
      if (!res.data.error) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <div className="flex justify-between border-b pb-2.5 items-center">
        <h1 className="md:text-xl text-lg font-semibold  uppercase text-gray-800  lg:text-2xl ">
          instructor login
        </h1>
        <button
          title="Back"
          onClick={props.event}
          className="text-sm text-white cursor-pointer  w-6 h-6 sm:w-7 sm:h-7 flex justify-center items-center shadow rounded-full tracking-widest bg-color "
        >
          <AiOutlineClose />
        </button>
      </div>

      <section className="py-5">
        <form onSubmit={handleSubmit} className="grid">
          {/* email */}
          <div className="p-2 mb-4 border rounded flex items-center gap-3">
            <SiGmail className="text-slate-500 text-xl" />
            <input
              type="email"
              name="email"
              value={formInputs?.email}
              onChange={handleChange}
              placeholder="Email"
              className="outline-none text-sm  placeholder:text-sm tracking-wide  w-full"
            />
          </div>
          {/* passowrd */}
          <div className="p-2 mb-4 border rounded flex items-center gap-3">
            <FaLock className="text-slate-500 text-lg" />
            <input
              type="password"
              name="password"
              value={formInputs?.password}
              onChange={handleChange}
              placeholder="Password"
              className="outline-none text-sm  placeholder:text-sm tracking-wide w-full"
            />
          </div>
          {/* Forgot */}
          <div className="px-1 mb-5 rounded sm:flex-row flex-col flex justify-between items-center gap-1 sm:gap-3">
            <Link to="/forgotInsPass" className="font-medium text-sm">
              Forgot Your Password ??
            </Link>
            {/* <div className=" text-center text-sm">
              {emailVerify && (
                <span
                  className="text-color hover:no-underline cursor-pointer font-semibold underline"
                  onClick={() => sendEmailVerification()}
                >
                  Send Email verification mail again
                </span>
              )}
            </div> */}
          </div>
          <button
            disabled={loading}
            type="Submit"
            className={` px-4 py-2 text-sm sm:text-base border-color font-medium text-white uppercase transition-colors duration-300 transform ${
              loading ? "bg-transparent" : "bg-color"
            }  rounded lg:w-auto cursor-pointer focus:outline-none `}
          >
            {loading ? <ApiLoader /> : "Login"}
          </button>
        </form>
      </section>

      <div className="flex justify-center border-t pt-2.5 items-center">
        <h1 className="font-medium  tracking-wider  md:text-base text-sm  text-gray-800 capitalize  ">
          Don't have an account ?
          <span
            onClick={props.handleStep}
            className=" pl-1 cursor-pointer  text-color  "
          >
            SignUp
          </span>
        </h1>
      </div>
    </>
  );
};

export default InstructorLogin;
