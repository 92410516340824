import React from "react";

const RefundPolicy = () => {
  return (
    <section className="container mx-auto sm:px-0 py-1 px-1">
      <h4 className="text-2xl md:text-3xl xl:text-4xl px-2 py-3 tracking-wider font-semibold">
      Refund, Payment and Cancellation Policies
      </h4>
      <div className="tracking-wider space-y-2.5 sm:py-5 px-2 py-4 md:py-7">
        <hr/>
        We thank you and appreciate your availing of services with us. 
        Please read the policy, conditions and process carefully. 
        This will give you important information and guidelines about 
        your rights and obligations as our customer/user concerning 
        any service you avail from us. The policy concerning refund, payment and 
        cancellation requests shall follow the clauses: 

<hr/>

<br/><b>USER CANCELLATION AND FAILED TRANSACTIONS</b>

<br/>The Company shall entertain cancellation requests, and a refund shall be processed only 
if there is any issue with the payment gateway. No refund request will be entertained
after the student is enrolled for a sub-course.
<hr/>

<br/><b>REFUND TERMS</b>
<br/>Refunds are processed to the source account within seven days of failed transaction.

<hr/>

<br/><b>GENERAL CANCELLATION AND REFUND POLICIES</b>
<br/>1.	For failed payment gateway transaction, the total amount will be refunded to the user’s 
account.
<br/>2.	For faster request processing, we advise student to send an email to finance@verysimilartests.com 
stating the student email id, failed transaction id, date of failed transaction, failed transaction amount, 
and an UPI enabled phone number in which the amount will be tranferred. We do not ask anyone to provide 
their bank details and we solely transact on UPI enabled phone.
<br/>3.	Enrollment into the course is started after the free orientation session, hence cancellation 
requests are not entertained after enrollment in the course.
<br/>4.	No dispute requests are entertained due to user’s environmental issues like electrical outage, 
network or software of device related issues arised while attending online classes.
<hr/>

<br/><b>PAYMENT TO INSTRUCTORS</b>
<br/>1. The company reserves the right to deny any payment requests if the class content found to be vulgar, not up to the mark or
very bad feedback from most students.
<br/>2. No payment will be processed for incomplete sub-course.
<br/>3.	The process takes seven days from the sub-course completion to process the payments and to generate payment report.
<br/>4. We do not ask anyone to provide their bank details and we solely transact on UPI enabled phone. Hence, we
request our instructor to provide the UPI enabled phone number while registering with our portal.
<hr/>
<br/> For queries related to refund, cancellation or payments, reach us with finance@verysimilartests.com or +918275694545 or write to us in the below given address.
<hr/>
<br/>    STARDATAINSIGHTS
<br/>    B 404, Ganga Ocean Square (G.O Square),
<br/>    Wakad Hinjewadi Road, Near Mankar Chowk,
<br/>    Wakad Pune, Maharastra 411057
<br/>    Contact No. +918275584545

</div>
</section>
);
};

export default RefundPolicy;
