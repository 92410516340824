import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="container mx-auto sm:px-0 py-5 px-4">
      <h3 className="text-2xl md:text-3xl xl:text-4xl px-1 py-3 tracking-wider font-semibold">
        Privacy policy
      </h3>
      {/* 1 */}
      <div className="tracking-wider space-y-2.5 sm:py-5 px-1 py-4 md:py-7">
        <h4 className="md:text-2xl text-xl font-medium">
          {/* What is Loreum lipsum? */}
        </h4>
        <p className="text-gray-700 text-justify md:text-base text-sm">

          <hr />
          <h2 className="text-lg">1.	INTRODUCTION TO VERYSIMILARTESTS </h2>


          <br />1.1. StarDataInsights, a sole proprietorship company registered in India, having its office at B-404, Ganga Ocean square (G.O Square) Wakad-Hinjawadi Road, near Mankar chowk, Wakad Pune, Maharashtra 411057 (collectively known as “<span className="text-red-500 text-xl">S</span><span className="text-slate-950 text-xl">tar</span><span className="text-red-500 text-xl">D</span><span className="text-slate-950 text-xl">ata</span><span className="text-red-500 text-xl ">I</span><span className="text-slate-950 text-xl">nsights</span>” or “<span className="text-red-500 text-xl">SDI</span>”, “company", “we”, “us”, “ours”) offers a wide range of courses on a variety of subjects, taught by experts in their fields. From programming to competitive exam preparation, academics to digital marketing, data science, cloud computing to corporate training, there is something for everyone. We believe that learning should be engaging, interactive, and fun, and we strive to make our courses as interactive and engaging as possible. At the same time, VerySimilarTests covers all ancillary requirements from storage, engagement, management, tracking and invoicing.

          <br />1.2. We are a community of passionate educators and learners dedicated to providing high-quality, affordable, and accessible education to everyone. Our mission is to empower people to achieve their goals, whether advancing their careers, developing new skills, or simply pursuing a passion.

          <br />1.3. We are committed to providing interactive quality education to our learners, regardless of their background or location. Every teacher can enlighten a student's knowledge with value education for every student; there is a teacher who can teach right and show the right direction. We offer flexible session plans for instructors and flexible payment plans for learners. Our courses are also available in multiple languages so that everyone can benefit from the knowledge and expertise of our instructors.

          <br />1.4. We provide a platform to showcase our courses and welcome feedback from our community. Everyone has something to teach and something to learn, and we are excited to be part of a community that is dedicated to lifelong learning. Towards the same, we have introduced this privacy policy (“policy”) to protect your data that has been shared over our platform, verysimilartests.com (“platform”).

          <br/><br/>

          <hr />
         
          <h2 className="text-lg">2. INTRODUCTION TO PRIVACY POLICY</h2>

          <br />2.1. The purpose of this policy is to safeguard the information you give the company. It establishes our guidelines and methodology for gathering, using, storing, disclosing, and safeguarding your personally identifiable information or other data that we acquire on our platform for all the reasons specified in this policy and in compliance with the laws that are currently in effect.

          <br />2.2. The author of this privacy statement guarantees an unwavering dedication to your privacy with regard to safeguarding the priceless data you might divulge on this website and mobile application. Information about the website and mobile application can be found in this privacy statement.

          <br />2.3. To provide you with our uninterrupted use of the website and mobile application, we may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of your privacy, we provide this notice explaining our information collection and disclosure policies and the choices you make about how your information is collected and used.

          <br/><br/>

          <hr />

         
          <h2 className="text-lg">3.	DEFINITIONS</h2>
          <br />3.1. Applicable laws: This refers to all laws, regulations, bylaws, ordinances, and the like that are in effect within the borders of India, as well as any subsequent revisions.

          <br />3.2 Content: This section covers all written material, including copy, ads, images, photos, user interfaces, visual interfaces, trademarks, logos, brand names, descriptions, sounds, music, and original artwork.

          <br />3.3. Third parties - this term refers to any legal entities, individual or entity, that are linked on the website or application, excluding users and the website and application's creator.

          <br />3.4. Service - shall mean and refer to all the digital marketing and promotion activities performed by the company firm for the goods and services of the user.

          <br />3.5. Cookies are little bits of information or software that are automatically stored on your hard drive or in the web browser on your device. They collect and store data and patterns. Through the usage of cookies, we are able to better serve you on our platform and enhance your overall use and browsing experience by helping you remember your activities, information, and preferences over time.

          <br />3.6. Customers: Any person or organization that has registered for an account on the platform in order to use the company's services is considered a customer.

          <br />3.7. Data: This refers to any business data, non-personal data, personally identifiable data, and other particulars that you may submit on our platform while using, perusing, or gaining access to it. These details may be provided singly or in combination.

          <br />3.8. User - refers to every individual or entity who registers itself and creates an account on the platform to avail of the services provided by the company. The term user includes the natural or legal person, including vendors, partners and customers who browse through the platform for informational purposes regarding the platform.

          <br/><br/>

          <hr />

         
          <h2 className="text-lg">4.	INTERPRETATION</h2>
          <br />4.1. For interpretation in this policy,

          <br />4.1.1. the terms “customer”, “vendor, “partner”, “user”, "you", "your", and "yours" shall refer to any natural person or legal person who is either browsing our platform or availing of our services unless the context denotes otherwise;

          <br />4.1.2. the words denoting the singular shall include the plural and vice versa for interpretation in this policy unless the context denotes otherwise;

          <br />4.1.3. the words denoting any gender include all genders for the purposes of interpretation in this policy unless the context denotes otherwise;

          <br />4.1.4.  the headings in this policy are inserted for convenience only.

          <br />4.1.5. the use of this website and application is solely governed by the terms of use, privacy policy, and any other relevant policies as updated on the website and application.


          <br/><br/>
          <hr />

          
          <h2 className="text-lg">5.	COMPLIANCES</h2>
          <br />5.1. The Indian Information Technology Act, 2000, its regulations, and the updated sections regarding electronic records in various legislation as amended by the Indian Information Technology Act, 2000, are the basis for the creation and implementation of this policy. This electronic record is created by a computer system; neither digital nor physical signatures are needed. The terms and conditions for accessing and using any functional website must be published in accordance with Rule 3 (1) of the Indian Information Technology (Intermediaries Guidelines) Rules, 2011 and Rule 4 of the Information Technology (Rational Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 of the Information Technology Act, 2000, amended through Information Technology Amendment Act, 2008.

          <br />5.2. Individuals are granted specific data rights under the General Data Protection Regulation (GDPR). We are pleased to provide transparency and access controls in order to assist users in exercising their rights. The following are the rights that are available to individuals, unless they are restricted by applicable law:

          <br />5.2.1. Right of access: You have the right to know what data we process about you and to ask to see it.

          <br />5.2.2. Right to rectification: In the event that your data is erroneous or lacking, you have the right to ask that we update or correct it.

          <br />5.2.3. Right to erasure: You have the right to ask that your information be deleted.

          <br />5.2.4. Right to restrict: You may ask us to cease processing all or part of your personal data for a certain period of time, either temporarily or permanently.

          <br />5.2.5. Right to object: You have the right to object to our processing of your personal data on the basis of reasons specific to your circumstances. In addition to having the option to object to the use of your personal data for direct marketing,

          <br />5.2.6. Right to data portability, which includes the opportunity to obtain an electronic copy of your personal information and transfer it to another party so that it can be used in their services,

          <br />5.2.7. Right to be exempt from automated decision-making: You have the right to be exempt from decisions that are made exclusively through automated means, including profiling, if they would legally affect you or have a comparably important impact.

          <br/><br/>

          <hr />

          <h2 className="text-lg">6. APPLICABILITY</h2>
          <br />6.1. This policy applies to all natural or legal people who visit our platform either by browsing it or by availing of the services on it. Your registration uses this policy on this platform as a customer. This policy also applies to you by continuously browsing on the platform, even without any account. This policy, along with all the amendments made to it occasionally, applies to all individuals and natural legal persons.


          <br/><br/>

          <hr />

          <h2 className="text-lg">7.	WHY DO WE COLLECT DATA?</h2>
          <br />7.1. We may collect certain information to provide you with the best services and a seamless experience while browsing the platform. We strive to improvise the services and user experience the platform offers daily.

          <br />7.2. We collect and store the data for any legal and regulatory requirements under applicable laws. We collect and maintain your data to notify you of any changes in the policy or operation of the platform.

          <br />7.3. The information you provide shall be used to provide and improve the service for you and all users.

          <br />7.3.1. to provide you with services on your request.

          <br />7.3.2. for maintaining an internal record.

          <br />7.3.3. to enhance the services provided.

          <br />7.3.4. for maintaining records under the legal and statutory provisions.

          <br />7.4. We employ tracking data, like IP addresses and device IDs, to better identify you, collect general demographic information, and provide additional services. We promise not to trade, license, or sell your personal data. Unless another party acts on our instructions or we are compelled by law to do so, we will not disclose your personal information to third parties. Our server logs gather information about users, such as their IP addresses and the pages they visit. This information is utilized for web system management and troubleshooting. We also employ third-party tracking, analytics, optimization, and targeting tools to learn more about how users interact with our mobile application and website. This allows us to make improvements and serve them with tailored adverts and content based on their interests.
          <br/><br/>

          <hr />

          
          <h2 className="text-lg">8. WHAT DO WE COLLECT?</h2>

          <br />8.1. Your online privacy is something we take very seriously. We also understand that you require your personal information shared with us to be appropriately managed and protected.

          <br />The following data could be gathered by us:

          <br />8.1.1. All information submitted by teachers and students who register on the website will be collected, including but not restricted to name, age, date of birth, highest level of education completed, address, billing address, and email address.

          <br />8.1.2. Further information will be gathered, including the language used to provide the courses to teachers exclusively and the UPI enabled mobile number from which they will get their fees.

          <br />8.1.3. Your first and last names as well as your email address will be gathered if you register using a Google account.

          <br />8.1.4. If you register using your Facebook account, we collect your first and last names as they appear on your account and the URL to your profile picture. Suppose you have provided permission to Facebook through their in-app privacy option (which appears just before you register on our platform). In that case, we may collect your gender, age, or email id, depending on your permission.

          <br />8.1.5. If you register using your mobile number, we will collect your mobile number.

          <br />8.1.6. Information is collected through permission derived by the website and mobile application for location access, storage permissions etc.

          <br />8.1.7. Tracking information such as but not limited to, the IP address of your device and device id when connected to the internet. This information may include the URL that you just came from (whether this URL is on the website and mobile application or not), which URL you next go to (whether this URL is on the website and mobile application or not), your computer or device browser information, and other information associated with your interaction with the website and mobile application;

          <br />8.1.8. Your credit card/debit card details you choose to enroll into our platform as defined in our terms of use using your credit/debit card.

          <br />8.1.9. Proof of product ownership, like an invoice of products, please note that the option is currently available to a limited number of users.

          <br />8.1.10. Details of website and mobile application usage for analytics.

          <br/><br/>

          <hr />

          
          <h2 className="text-lg">9.	HOW DO WE COLLECT THE DATA?</h2>
          <br />9.1. When collecting personal information, we will identify the purposes for which information is being collected. Suppose the same is not determined to you. In that case, you have the right to request the company to elucidate the purpose of collecting said personal information, pending the fulfilment of which you shall not be mandated to disclose any information. We will collect and use your personal information solely to fulfil those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only keep your personal information for as long as is required to achieve those goals. We shall get personal information from individuals only with their knowledge and consent, and only through fair and legal procedures.

          <br />9.2 Personal information must be accurate, full, and current to the extent required and pertinent to the purposes for which it is to be used.

          <br />9.3 We gather data about your activities on our platform, such as the websites from which you linked to us, the date and time of each visit, the searches you conducted, the listings or banner ads you clicked, the way you interacted with those listings or ads, the length of your visit, and the sequence in which you browsed the platform's content.
          <br/><br/>

          <hr />

          
          <h2 className="text-lg">10. VALIDATION OF YOUR VERY SIMILAR TESTS ACCOUNT</h2>
          <br />10.1. We validate the accounts of VerySimilarTests users by using SMS verification to ensure that each account is associated with a genuine and unique user. In the event that you decline to have your account validated, it will stay active and you will only have limited access to our services.

          <br />10.2. If you create several VerySimilarTests accounts using the same mobile number or email id, all such accounts will have limited functionality, and you will be asked to choose one. The account you chose will return to full functionality, and the rest of the accounts will remain to have limited functionality or be disabled permanently.

          <br />10.3. Users can request five SMS messages with verification codes within 24 hours. Account validation is done once for each account until the user verifies it via SMS and email. Once you have validated your VerySimilarTests account, it will remain associated with the mobile number used for the SMS verification. You must contact our customer support team to change the mobile number associated with your VerySimilarTests account.
          <br/><br/>       

          <hr />

          
          <h2 className="text-lg">11. SECURITY AND HOW WE RETAIN THE DATA?</h2>
          <br />11.1. We view data as an asset that has to be secured from theft and unwanted access. To prevent members both inside and outside the organization from gaining illegal access to such material, we utilize a variety of security measures.

          <br />11.2. All the information we receive about you is stored on secure servers, and we have implemented technical and organizational measures that are suitable and necessary to protect your personal data. VerySimilarTests continually evaluates the security of its network and adequacy of its internal information security program, which is designed to,
          <br />11.2.1. Help secure your data against accidental or unlawful loss, access, or disclosure,
          <br />11.2.2. Identify reasonably foreseeable risks to the security of the VerySimilarTests network,
          <br />11.2.3. Minimize security risks, including risk assessment and regular testing. In addition, we ensure that all payment data are encrypted using tells technology.
          <br />11.3. Please be aware that data transmission via the internet or other open networks is never totally secure, even with the safeguards we have put in place to protect your information. Your personal information could be accessed by uninvited parties. In order to secure data, we have implemented a number of security measures.
          <br />11.4. Nevertheless, no approach is 100% safe in the event of data theft or loss during restoration, as is the situation with most electronic transactions. Even with encryption technology's effectiveness, no security system is unbreakable. We cannot ensure that your information will not be intercepted while transmission over the internet to our organization, nor can we guarantee the security of our database.

          <br/><br/>
          <hr />

          
          <h2 className="text-lg">12. THIRD-PARTY WEBSITE LINKS</h2>
          <br />12.1. The website and mobile application may include third-party advertisements and hyperlinks to their websites and mobile applications or resources. We have no control over any other website and mobile application or resources or contents available on this other website and mobile applications provided by companies or persons other than us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources and do not endorse any advertising, services/services, or other materials on or available from such websites and mobile applications or resources.
          <br />12.2. You acknowledge and agree that we are not liable for any loss or damage which you may incur as a result of the availability of those external sites or resources or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services, or other materials on, or available from, such website and mobile applications. This external third-party website and mobile applications and resource providers may have their privacy policies governing the collection, storage, retention, and disclosure of your personal information that you may be subject to. We recommend entering the website and mobile application and reviewing its privacy policy.
          <br/><br/>

          <hr />

         
          <h2 className="text-lg">13.	COOKIES</h2>
          <br />13.1. A cookie is a small file of letters and numbers that we store on your browser or your computer's hard drive if you agree. You agree to our use of cookies by continuing to browse the site. Cookies data can be transferred to your computer’s hard drive. You can set your browser to refuse all or some browser cookies or to alert you when Website and Mobile Applications are established or access cookies. If you disable or refuse cookies, please note that some parts of this Website and Mobile Application may become inaccessible or not function properly. A list of the type of cookies we use is as follows;

          <br />13.1.1. Strictly necessary cookies. These are cookies that are required for the operation of our Website and Mobile Application. They include, for example, cookies that enable you to log into secure areas of our Website and Mobile Application, use a shopping cart or make use of e-billing services.

          <br />13.1.2. Cookies for performance and analysis. They enable us to identify and tally the number of visitors as well as see how users navigate our mobile application and website. By ensuring that visitors can simply locate what they're looking for, for example, this helps us improve the functionality of our website and mobile application.

          <br />13.1.3. Cookies for functionality. When you visit our website and mobile application again, these identify you. This helps us remember your preferences (such your preferred language or area) and personalize our content for you. We can also greet you by name thanks to this.

          <br />13.1.4. Targeting cookies. These cookies record your visit to our Website and Mobile Application, the pages you have visited and the links you have followed. We will use this information to make our Website, Mobile Application, and advertising more relevant to your interests. We may also share this information with third parties for this purpose.

          <br />13.2. Cookies are used by us to control user sessions, remember your selected language, and show you relevant ads. Tiny text files called "cookies" are downloaded to your device's hard drive from a web server. Cookies may gather information about your browsing history, preferences, username, and date and time of visit. Your browser can be configured to notify you when a website sets or accesses cookies, or to reject all or some cookies. Please be aware that certain features of our Services/Platform may stop working or become unavailable if you disable or reject cookies. Please refer to our Policy on Cookies and Similar Technologies for further details on the cookies we use.
                
          <br/><br/>

          <hr />

         
          <h2 className="text-lg">14.	GOOGLE ANALYTICS</h2>
          <br />14.1. We use google analytics to help us understand how you use our content and work out how we can make things better. These cookies follow your progress through us, collecting anonymous data on where you have come from, which pages your visit, and how long you spend on the site. Google then stores this data to create reports. These cookies do not store your personal data.

          <br />14.2. The information generated by the cookie about your use of the website and mobile application, including your IP address, may be transmitted to and stored by google on servers in the United States. Google may use this information to evaluate your use of the website and mobile application, compile reports on website and mobile application activity for us and provide other services relating to website and mobile application activity and internet usage. Google may also transfer this information to third parties where required by law or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by google. Using this website and mobile application, you consent to process data about your google in the manner and for the above-mentioned purposes.
          <br/><br/>

          <hr />

         
          <h2 className="text-lg">15.	GOOGLE ADSENSE</h2>
          <br />15.1. Google AdSense is a tool that allows Website and Mobile Application publishers to deliver advertisements to site visitors in exchange for revenue calculated on a per-click or per-impression basis. To do this, Google uses cookies and tracking technology to deliver ads personalized to a Website and Mobile Application user/visitor. In this regard, the following terms are specified to the Users:

          <br />15.1.1. Third-party, including Google, use cookies to serve ads based on your prior visits to our Website and Mobile Application or other Website and Mobile Applications.

          <br />15.1.2. Google's use of advertising cookies enables our partners and us to serve advertisements to you based on their visit to our Platform and/or other Website and Mobile Applications on the Internet.

          <br />15.1.3. Go to Ads Settings to choose not to get personalized advertisements.

          <br />15.1.4. All third-party advertisements on our website and mobile application are solely meant to provide information. The legitimacy of the ads is not guaranteed or subject to liability by the Company, the Website, or the Mobile Application.

          <br />15.1.5. The Company will never allow its rivals to place advertisements on the Website and Mobile Application.

          <br />15.1.6. You may visit the links in the advertisements at your own risk or choose not to accept the cookies permitting third parties to display their advertisements.
          <br/><br/>

          <hr />

          
          <h2 className="text-lg">16.	YOUR RIGHTS</h2>
          <br />16.1. Unless subject to an exemption, you have the following rights concerning your data:

          <br />16.1.1. The right to request a copy of the data which we hold about you;

          <br />16.1.2. The right to request any correction to any personal data if it is found to be inaccurate or out of date.

          <br />16.1.3. The right to withdraw Your consent to the processing at any time.

          <br />16.1.4. The right to object to the processing of personal data.

          <br />16.1.5. The right to complain about a supervisory authority.

          <br />16.1.6. The right to obtain information on whether personal data are transferred to a third country or an international organization.

          <br />16.2 You can exercise your privacy rights and choices by emailing VerySimilarTests support with a Data Deletion Request if you do not want us to process your data. In some cases, for instance, if you ask us to remove your transaction data and VerySimilarTests is required by law to retain a record of that transaction in order to comply with the law, we might not be able to fulfill your request.

          <br />16.3. If you have an account with one of our services, you have the right to get a copy of all the personal information we have on file. When you log in, you also have the right to request that we limit how we use the information in your account.
          <br/><br/>
          <hr />

          
          <h2 className="text-lg">17.	CONFIDENTIALITY</h2>
          <br />17.1. You further acknowledge that the Website and Mobile Application may contain information that is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and, therefore, will not be divulged to any third party unless legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point in time.

          <br />17.2. We will communicate with you by email, SMS or app notification in connection with our Services/Platform to confirm your registration, to send important notification regarding your enrolled courses, class schedules, to inform you in case your ad listing has become live/expired and to other transactional messages in relation to our services. As we must provide you with such transactional messages, you may be unable to opt -out of such messages.
          <br/><br/>

          <hr />

          
          <h2 className="text-lg">18.	OUR DISCLOSURE OF YOUR INFORMATION</h2>
          <br />18.1. As the owners and consumers of your survey results, we might offer surveys for survey producers on our website and mobile application. We don't sell or own your answers. The survey designers will receive access to anything you specifically provide in your answers. To find out how the survey developer might share your survey replies, please get in touch with them directly.

          <br />18.2 Information that is obtained in accordance with the Right to Information Act, 2005, any rules enacted thereunder, or any other currently in effect law will not be deemed sensitive if it is publicly available and accessible.
          <br/><br/>

          <hr />

          
          <h2 className="text-lg">19.	ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</h2>
          <br />19.1. You can review and change your submitted information except for the Email ID. An option for facilitating such change shall be present on the Website, and the User shall facilitate Mobile Application and such change.

          <br />19.2 If you make any changes to your information, we might or might not maintain a record of it. Information that you have asked us to delete from our records won't be kept in our records unless necessary to settle disputes, solve issues, or uphold our terms and conditions.

          <br />19.3. We will completely delete all of the previous data from our databases, including any "backup" systems that may have been kept. If you think that any information we may have on you is inaccurate or lacking, you can delete your profile to prevent others from seeing it. If that is the case, the user has to update any inaccurate information right away.
          <br/><br/>

          <hr />

          
          <h2 className="text-lg">20.	CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</h2>
          <br />20.1. We reserve the right to remove, suspend, or cancel your account from our platform if we find any actions or behaviors that go against this policy or any other policies that we believe relate to you. Furthermore, should you breach this Policy or in any of the following other circumstances, we reserve the right to cancel or limit your access to our platform:

          <br />20.1.1. if, during the creation or registration of your Account, you give any inaccurate, erroneous, or significantly altered information or Data,

          <br />20.1.2. uploading information that is inconsistent, vulgar, obscene, ambiguous, or that is only connected to you or a third party;

          <br />20.1.3. should you provide the customers with services in a manner that is inconsistent, ambiguous, impolite, significantly altered, or flawed;

          <br />20.1.4. such other acts or actions strictly against this policy of the firm or any different policy or agreement which the firm may enter with you to render the services.

          <br />20.2. To withdraw your consent or to request the download or delete your data with us for any or all our services at any time, please email support@verysimilartests.com.
          <br/><br/>

          <hr />

         
          <h2 className="text-lg">21.	SEVERABILITY</h2>
          <br />21.1. Unless specifically stated differently or as indicated by the context of the agreement, each paragraph in this privacy policy will be and remain independent, separate, and severable from all other paragraphs hereof. The remaining paragraphs of this privacy policy will not be impacted by the decision or declaration that one or more of the paragraphs are void.
          <br/><br/>
          <hr />

          
          <h2 className="text-lg">22.	AMENDMENT </h2>
          <br />22.1. You acknowledge and agree that we shall have the sole authority, in its discretion, to revise and update this policy in the event that laws or the company's policies, procedures, or standards change. For a better understanding and to stay up to date on any revisions, you must periodically review the policy. Periodically, the updated Policy will be posted on the platform. Your continued use of the platform will be considered as your agreement to the updated policy published by the company.

          <br />22.2. If you disagree with how we process your personal data and the choices we provide you, you may close your account anytime by emailing VerySimilarTests support.

          <br/><br/>

          <hr />

          
          <h2 className="text-lg">23.	GOVERNING LAW AND DISPUTE RESOLUTION</h2>
          <br />23.1. Any disputes, disagreements, or conflicts between the Parties regarding the interpretation, legality, application, or claimed violation of any term or clause of this Policy will be resolved per Indian law. The competent Pune-based courts will hear it.

          <br />23.2. If there is a disagreement about anything related to this privacy policy, including whether it is valid, the parties will first try to settle it through mediation, which will be facilitated by the Centre for Online Resolution of Disputes ("CORD") (www.resolveoncord.com) and conducted by a single mediator chosen by CORD, in accordance with the CORD Rules of Mediation. Subject to a contrary agreement between the Parties, if the Parties are unable to settle any such dispute through mediation within 45 days of the dispute's inception, the matter will be finally settled by arbitration administered by CORD and conducted by a single arbitrator to be appointed by CORD in accordance with the Rules of Arbitration of CORD. English will be used as the arbitration's language. The arbitration will be held in Pune, India.

          <br />23.3. You acknowledge and agree that the laws, rules, and regulations of India shall apply to the Privacy Policy, the Terms of Use, and any other agreements between the Parties.
          <br/><br/>

          <hr />

          
          <h2 className="text-lg">24.	GRIEVANCES AND COMMUNICATION</h2>
          <br />24.1. If you have any questions or concerns regarding this Privacy Policy, you should contact us by sending an e-mail to support@verysimilartests.com or by writing to us at:
          <br/><br/>
          <hr />

          <h2 className="text-lg">STARDATAINSIGHTS</h2>
          <br />B 404, Ganga Ocean Square (G.O Square),
          <br />Wakad Hinjewadi Road, Near Mankar Chowk,
          <br />Wakad Pune, Maharastra 411057

          {" "}
        </p>
      </div>
      {/* 2 */}
      <div className="tracking-wider space-y-2.5 sm:py-5 py-4 md:py-7">
        <h4 className="md:text-2xl text-xl font-medium">
          {/* */}
        </h4>
        <p className="text-gray-700 text-justify md:text-base text-sm">
          {/* {" "} */}
        </p>
      </div>{" "}
      {/* 3 */}
      <div className="tracking-wider space-y-2.5 sm:py-5 py-4 md:py-7">
        <h4 className="md:text-2xl text-xl font-medium">
          {/*  */}
        </h4>
        <p className="text-gray-700 text-justify md:text-base text-sm">
          {/* place holder*/}
        </p>
      </div>{" "}
    </section>
  );
};

export default PrivacyPolicy;
