import React from "react";

const Terms = () => {
  return (
    <section className="container mx-auto sm:px-0 py-5 px-4">
      <h1 className="text-2xl md:text-3xl xl:text-4xl py-4 tracking-wider font-semibold">
        JOB NOTICE
      </h1>
      {/* 1 */}
      <h1>

      A position is opened for a Staff Technical Support Engineer.        
      <br></br>
<hr/>
<br/>
No. of position: 1
<br/>
<br/>
Job Description

<hr/>
We are looking for technically skilled person with good communication skills and interpersonal skills.
<hr/>
<hr/>
<br/>
Responsibilities:
<hr/>
<hr/>
(1) Identifying the reported problem from customer.
<hr/>
(2) Speaking to customers to gather all information of the issue.
<hr/>
(3) Providing timely and accurate customer feedback.
<hr/>
(4) Managing multiple cases at one time.
<hr/>
<hr/>
<br/>
ELIGIBILITY
<hr/>
<hr/>
(1) B.Sc or BCA Degree in computer science or information technology.
<hr/>
(2) Knowledge of Linux OS is a plus.
<hr/>
(3) Candidate should have dood knowledge on react.
<hr/>
(4) Attention to detail and good problem-solving skills.
<hr/>
(5) Good written and verbal communication.
<hr/>
<hr/>
<br/>
Interested candidate can send their resume to hr@verysimilartests.com
<hr/>
</h1>
<div className="mt-72">
</div>
</section>
);
};

export default Terms;
