import {
   GET_LANGUAGE, SET_LANGUAGE
  } from "../Actions/ActionTypes";
  
  const initialState = {
    
    languages: [],
    loading: false,
   
  };
  
  const LanguageReducer = (state = initialState, action) => {
    switch (action.type) {
      // GET_COURSE
      case GET_LANGUAGE:
        return { ...state, loading: true };
      case SET_LANGUAGE:
        return { ...state, languages: action.payload, loading: false };
      
    
      default:
        return state;
    }
  };

  export default LanguageReducer;
  