import {
  GET_CATEGORY,
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_SUCCESS,
  GET_LANGUAGE,
  GET_LANGUAGE_FAILURE,
  GET_LANGUAGE_SUCCESS
} from "../Actions/ActionTypes";

const initialState = {
  loading: false,
  categories: [],
  languages: []
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    // Categories
    case GET_CATEGORY:
      return { ...state, loading: true };

    case GET_CATEGORY_SUCCESS:
      return { ...state, categories: action.payload, loading: false };

    case GET_CATEGORY_FAILURE:
      return { ...state, loading: false };

    // Languages
    case GET_LANGUAGE:
      return { ...state, loading: true };

    case GET_LANGUAGE_SUCCESS:
      return { ...state, languages: action.payload, loading: false };

    case GET_LANGUAGE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default CategoryReducer;
