// import Logo from "../Components/Logo";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TbDeviceMobile } from "react-icons/tb";
import { updatePass } from "../Redux/Actions/AuthAction";
import { toast } from "react-hot-toast";
import ApiLoader from "../Components/ApiLoader";
import Logo from "../Components/Logo";

const Resetpass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.AuthReducer);
  const [formValues, setFormValues] = useState({});

  // handleChange
  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  // handelSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      token: "",
      password: formValues.password,
    };
    if (formValues.password && formValues.conpassword) {
      if (formValues.password === formValues.conpassword) {
        dispatch(updatePass(payload, () => navigate("/login")));
      } else {
        toast.error("Enter valid confirm password");
      }
    } else {
      toast.error("Please fill fields");
    }
  };

  return (
    <>
      <div className="flex lg:h-screen lg:flex-row flex-col  bg-white w-full lg:bg-gray-50 lg:gap-5 items-center">
        <figure className=" lg:h-full h-80 md:h-96 w-full  lg:w-1/3 flex ">
          <img
            src="Assets/login-bg-2.jpg"
            className="h-full object-cover w-full"
            alt="register"
          />
        </figure>
        <section className="lg:w-2/3 my-4  w-full lg:p-4 sm:w-4/5 md:w-3/4 mx-auto lg:px-20">
          <div className="lg:p-10 p-5 bg-white rounded lg:shadow">
            <Logo />
            <h3 className="text-lg my-5 uppercase font-bold">
              Update password
            </h3>
            <form onSubmit={handleSubmit}>
              {/* Password */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="password">
                  <TbDeviceMobile className="text-gray-500 text-xl" />
                </label>
                <input
                  type="password"
                  name="password"
                  value={formValues?.password}
                  onChange={handleChange}
                  className="outline-none px-3 w-full text-sm"
                  placeholder="Password"
                />
              </div>
              {/* Confirm Password */}
              <div className="p-2 mb-4 border rounded flex items-center gap-3">
                <label htmlFor="conpassword">
                  <TbDeviceMobile className="text-gray-500 text-xl" />
                </label>
                <input
                  type="password"
                  name="conpassword"
                  value={formValues?.conpassword}
                  onChange={handleChange}
                  className="outline-none px-3 w-full text-sm"
                  placeholder="Confirm Password"
                />
              </div>

              {/* Submit */}
              <div
                className={` mb-5 border hover:bg-transparent hover:text-color ${
                  data.loading ? "bg-transparent" : "bg-color"
                }  text-white cursor-pointer border-color text-center hover:text-black rounded flex items-center gap-3`}
              >
                <button
                  type="submit"
                  disabled={data.loading}
                  className="w-full p-2 uppercase cursor-pointer text-center"
                >
                  {data.loading ? <ApiLoader /> : "Update password"}
                </button>
              </div>
              {/* Login Text */}
              {/* <div className=" flex items-center gap-1.5">
                <span className="text-gray-500">I remember password, </span>
                <Link to="/login" className="text-color uppercase font-bold">
                  Login
                </Link>
              </div> */}
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default Resetpass;
