import React from "react";

const ApiLoader = () => {
  return (
    <div className="flex w-full justify-center">
      <div className="w-5 h-5 border-2 border-dashed rounded-full  animate-spin border-[rgb(41,174,251)]"></div>
    </div>
  );
};

export default ApiLoader;
