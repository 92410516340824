import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
const FAQ = () => {

  const qaDataStud = [
    {
      id: "How do I find a course?",
      question: "How do I find a course?",
      answer: `There are four filters used to search a course in our portal.
      Using Pincode: Students can search teachers who are located in a specific area and have enrolled their courses in the VerySimilarTests Portal. 
      Use case 1: A student can use this function to seek for a teacher in their area who can help them improve their understanding of Marathi literature.
      Use case 2: This tool can be used to look for KOTA-based physics professors who have registered with our platform and a student from a non-urban area of Assam is trying to attain a good NEET rank and is looking for a teacher.
      Using Category: Students can locate instructors who have enrolled their courses in a specific category by using this filter. To improve search results, utilize this filter in conjunction with the Pincode filter.
      Use case 1: A student seeking tutoring for Bank PO preparation can see whether any of the associated categories are offered by us or not. If a category exists, the user has the option to search for tutors worldwide or within a specific location by applying a pincode filter.
      Using Language: Students can locate instructors who have enrolled their courses in a specific language by using this filter.or improved search results, this filter can be combined with the Category and Pincode features.
      Using the search bar: Students can locate teachers by entering the course name in the search field and using this filter. The course will appear if it is accessible through our portal.`
    },
    {
      id: "Can I preview a course before purchasing?",
      question: "Can I preview a course before purchasing?",
      answer: "Before registering for a subcourse, student can see the courses, their related subcourses, and the syllabus.",
    },
    {
      id: "How do I enroll my course?",
      question: "How do I enroll my course?",
      answer: "Look up a course, review the syllabus and schedule, add it to your cart, and go to the checkout. "
    },
    {
      id: "Do I receive anything after I complete a course?",
      question: "Do I receive anything after I complete a course?",
      answer: "Once the final session has been marked as finished, the instructor will declare the course completed"
    },
    {
      id: "What kind of material you have providing?",
      question: "What kind of material you have providing?",
      answer: "Study materials downloadable from the teacher and on-demand class recordings are offered."
    },
    {
      id: "What is available on VST for certification exam preparation? ",
      question: "What is available on VST for certification exam preparation? ",
      answer: "We don't offer any accreditation. Any certification that the registered establishment chooses to offer will be at its exclusive discretion"
    },
    {
      id: "If you due to some circumference in classes if I’m not able attend classes what will be the next procedure?",
      question: "If you due to some circumference in classes if I’m not able attend classes what will be the next procedure?",
      answer: "Before registering for the course, we advise prospective students to attend a demo session and review the syllabus and timetable. We will not be able to return the money if the student misses a few classes due to unforeseen circumstances"
    }
  ];
  const qaDataInstructor = [
    {
      id: "How do I create a course?",
      question: "How do I create a course?",
      answer: `Instructors can add courses by clicking the "ADD COURSE" button on the dashboard page after logging in. Every field on this page needs to be filled out. Below is a description of each field.
      a.	Category: The dropdown menu will display every category that the VerySimilarTests web application supports. Give the course a name that will appear on the portal after choosing the necessary category.
      
      b.	For instance, to add a physics course for JEE preparation, choose the "JEE PREPARATION PHYSICS" category and give it the name "Patil JEE Physics classes."
      
      c.	For instance, choose the category "JEE PREPARATION" and give the course the name "Alpha JEE classes" if you wish to add a JEE-Preparation course covering physics, chemistry, and mathematics.
      
      1.	Language: Select which language will be used for the classes.
      2.	Image: Describe the course in a picture. An instructor can create a picture to add to the course. The portal's course is emphasized by a strong picture. 
      3.	Demo session time: Give the course demo session a date and time. Students are encouraged to sign up for the course after attending the demo session. Students can learn about the course's specifics, such as the number of sub-courses, the number of sessions for each sub-course, the teaching strategies, the class schedule, etc., during the demo session.
      4.	Description: Give a brief overview of the course, including its goal and the benefits students will experience from attending.`
    },
    {
      id: "How do I create a sub-course?",
      question: "How do I create a sub-course?",
      answer: `Instructors can add sub-courses by clicking the "ADD SUBCOURSE" after selecting a course added by him on the dashboard page. Below is a description of each field.
      a.	Sub-course name: Sub-course is like a module if a course is added for particular subjects. If course is added for multiple subjects, sub-course is subject name.
      
      For example, if the course is called "Alpha JEE classes" and the category is "JEE PREPARATION" for physics, chemistry, and mathematics, the first sub-course name will be physics, the second will be chemistry, and the third will be mathematics.
      
      For example, if the course is called "Patil JEE Physics classed" and the category is "JEE PREPARATION PHSICS," the first sub-course name could be kinematics, the second force and friction, the third viscosity, and so on.
      
      b.	Price: Provide the price for the course. It should be more than the amount supported by VerySimilarTests web application.
      
      1.	Instructor name: Give the name of the instructor who will be teaching the classes. The name of the instructor who added the course for a particular class will match the name of the instructor who logged in. If a course is added to teach more than one subject or sub-course, the name of the instructor who will give the lecture in class must be provided.
      2.	Image: Describe the sub-course in a picture. An instructor can create a picture to add to the course. For example, if sub-course is “kinematics”, a related picture on kinematics works.
      3.	Class Begin Date: Provide the start date and time of the sub-course. Enrollment after the start of classes is prohibited.
      4.	Material: The student's study materials and syllabus can be uploaded by the instructor.`

    },
    {
      id:"How many minimum amount should sub-course have?",
      question:"How many minimum amount should sub-course have?",
      answer:"Please check the price field placeholder message of the subcourse page."
    },
    {
      id:"How many maximum sessions should sub-course have? session/sub-course",
      question:"How many maximum sessions should sub-course have? session/sub-course",
      answer:"Currently there is no limit but in future we might limit it looking into the resource consumption."
    },
    {
      id:"When can I mark as a complete the sub-course and session?",
      question:"When can I mark as a complete the sub-course and session?",
      answer:`There is a button to designate a session or sub-course as "complete" in the My Courses section.`
    },
    {
      id:"When can I delete a sub-course?",
      question:"When can I delete a sub-course?",
      answer:`Under two condition a sub-course is allowed to delete.
      1)	Once a sub-course is marked as complete.
      2)	If no student has enrolled into that course`
    },
    {
      id:"When can I delete a session?",
      question:"When can I delete a session?",
      answer:`Under the below condition a sub-course is allowed to delete.
      1)	Once a session is marked as complete.`
    }
    
  ];
  const qaDataMeet = [
    {
      id: "I’m having issues with my audio or video.",
      question: "I’m having issues with my audio or video.",
      answer: "Please see if others are experiencing the same audio or video problems as you are. Re-join the class or reschedule the meeting if everyone is having trouble with the online meeting. If the problem is on your end, please make sure you can access the internet.",
    },
    {
      id: "As an instructor I joined late but I do not have meeting control.",
      question: "As an instructor I joined late but I do not have meeting control.",
      answer: `Because JITSI has an integrated feature that allows for seamless meetings with auto-owner functionality, we use it for our online classes. With this feature, the first person to join the meeting is designated as the moderator, giving them control over the proceedings. If the first person leaves, the second person is promoted to the role automatically.
In this scenario, the instructor has the option to ask the second participant to take charge of the meeting or to adjourn it and invite everyone back.`,
    }

  ];
  const qaOther = [
    {
      id: "How to enroll the course?",
      question: "How to enroll the course?",
      answer: "You need to click on the course and pay the fees",
    },
    {
      id: "How can i pay my fees?",
      question: "How can i pay my fees?",
      answer: "We are providing lot of otions like UPI,Internet banking,card.",
    },
    {
      id: "How can user can cancel enrollment?",
      question: "How can user can cancel enrollment?",
      answer: "The Company shall entertain cancellation requests, and a refund shall be processed only for the students/User if there is any issue with the payment gateway. If a student wants to cancelthe enrollment, the refund amount after deducting the infra charge will be credited to their account."
    },
    {
      id: "How can I give feedback ?",
      question: "How can I give feedback ?",
      answer: "We have feedback section for that"
    }
  ];
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleQuestionClick = (questionId) => {
    setSelectedQuestion(questionId);
  };
  return (
    <div className="container mx-auto p-4">
      <h3 className="text-2xl md:text-3xl xl:text-4xl py-3 tracking-wider font-semibold">Frequently Asked Questions and Known Issues</h3>
      <hr />
      <p className="text-2xl container font-semibold mx-auto p-1"> STUDENTS</p>
      <hr />
      <div>
        {qaDataStud.map((q, index) => (
          <div key={index}  >
            <a href={`#${q.id}`} className=" text-blue-500 hover:text-red-500 underline" onClick={() => handleQuestionClick(q.id)}  >{q.question}</a>
          </div>
        ))}
      </div>
      <p className="text-2xl container font-semibold mx-auto p-1"> INSTRUCTOR</p>
      <div>
      {qaDataInstructor.map((qains, index) => (
        <div key={index}>
          <a href={`#${qains.id}`} className=" text-blue-500 hover:text-red-500 underline" onClick={() => handleQuestionClick(qains.id)}>{qains.question}</a>
        </div>
      ))}
      </div>
      <div>
      <p className="text-2xl container font-semibold mx-auto p-1"> MEETING</p>
      {qaDataMeet.map((qameet, index) => (
        <div key={index}>
          <a href={`#${qameet.id}`}  className=" text-blue-500 hover:text-red-500 underline" onClick={() => handleQuestionClick(qameet.id)}>{qameet.question}</a>
        </div>
      ))}
      </div>
      <div>
      <p className="text-2xl container font-semibold mx-auto p-1"> OTHER</p>
      {qaOther.map((qaother, index) => (
        <div key={index}>
          <a href={`#${qaother.id}`}  className=" text-blue-500 hover:text-red-500 underline" onClick={() => handleQuestionClick(qaother.id)}>{qaother.question}</a>
        </div>
      ))}

      </div>

      {/* <div className="mt-20"></div> */}
      
      <div className="mt-12">
      <p className="text-2xl underline">STUDENT</p>
        {qaDataStud.map((qastud, index) => (
          <div key={index} id={qastud.id} style={{ margin: '20px 0', whiteSpace: 'pre-line' }} >
            <h2 className="text-xl font-semibold mt-8">{qastud.question}</h2>
            <p>{qastud.answer}</p>
            <hr />
          </div>
        ))}
      </div>
     
      
      <div>
      <p className="text-2xl underline">INSTRUCTOR</p>
      {qaDataInstructor.map((qainst, index) => (
        <div key={index} id={qainst.id} style={{ margin: '20px 0', whiteSpace: 'pre-line' }}>
          <h2 className="text-xl font-semibold mb-2">{qainst.question}</h2>
          <p>{qainst.answer}</p>
          <hr />
        </div>
      ))}
      </div>
      
      <div>
      <p className="text-2xl underline">MEETING</p>
      {qaDataMeet.map((qameet, index) => (
        <div key={index} id={qameet.id} style={{margin: '20px 0',whiteSpace:'pre-line'}}>
          <h2 className="text-xl font-semibold mb-2">{qameet.question}</h2>
          <p>{qameet.answer}</p>
          <hr />
        </div>
      ))}
      </div>


    
      <div>
        <p className="text-2xl underline">OTHER</p>
      {qaOther.map((qaother, index) => (
        <div key={index} id={qaother.id} style={{margin: '20px 0',whiteSpace:'pre-line'}}>
          <h3 className="text-xl font-semibold mb-2">{qaother.question}</h3>
          <p>{qaother.answer}</p>
          <hr />
        </div>
      ))}
      </div>
      {/* <section className="container mx-auto sm:px-0 py-1 px-1">
      </section> */}
      <hr /><br /> For any other query, please contact us with support@verysimilartests.com or +918275694545.
    </div>
  );
};

export default FAQ;
