import { useNavigate } from "react-router-dom";
import Heading from "../../Components/Heading";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiTrash2, FiEdit, FiEye, FiDownload } from "react-icons/fi";
import { getCourseById } from "../../Redux/Actions/CourseAction";
import moment from 'moment';
import {
  addSubCourse,
  deleteSubCourse,
  getSubCourse,
  updateSubCourse,
  downloadMaterials,
} from "../../Redux/Actions/SubCourseAction";
import TopBar from "../../Components/TopBar";
import Content from "../../Components/Content";
import Button from "../../Components/Button";
import NoData from "../../Components/NoData";
import toast from "react-hot-toast";

const BASEURL = process.env.REACT_APP_BASE_URL;

const Course = () => {
  const course_id = new URLSearchParams(document.location.search).get("id");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    price: "",
    classbegindate: "",
    teachername: "",
    course: course_id,
    preview: "",
    material: "",
  });
  const [edit, setEdit] = useState();
  const [showRow, setShowRow] = useState(false);
  const [multipleValue, setMultipleValue] = useState();
  const [showAddCourseModal, setShowAddCourseModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const course = useSelector((state) => state.CourseReducer.courseById);
  const subCourses = useSelector((state) => state.SubCourseReducer.subCourse);
  const instructorID = localStorage.getItem("instructorID|VST");

  const input =
    "bg-transparent border-color tracking-widest  text-xs rounded-sm shadow-md outline-none py-1.5 px-2 border ";

  const multipleData = (data) => {
    let file = Object.values(data);
    setMultipleValue(file);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files
        ? e.target.files.length > 1
          ? multipleData(e.target.files)
          : e.target.files[0]
        : e.target.value,
    });
  };

  const handleSubmit = () => {
    const fd = new FormData();
    fd.append("name", formData.name);
    fd.append("description", formData.description);
    fd.append("teachername", formData.teachername);
    fd.append("classbegindate", formData.classbegindate);
    fd.append("image", formData.image);
    fd.append("price", formData.price);
    fd.append("instructor", instructorID);
    fd.append("course", formData.course);
    fd.append("preview", formData.preview);
    fd.append("material", formData.material);
    if (formData.name === "" || formData.description === "" ||
        formData.price === "" ||formData.course === "" || formData.teachername === "" ||
        formData.classbegindate === "" ) 
        {
          toast.error("All fields except image and material are mandatory."); 
          return; 
        }

    let dprice = formData.price;
    if (dprice <= 0 || dprice !== undefined && dprice !== null && String(dprice).includes(".")) 
    {
      toast.error("Price value should be a whole number.");
      return;  
    }

    let img = formData.image;
    if (img !== undefined && img !== null && img.size > 2048000) 
    { 
      toast.error("Image size should not be more than 2 MB.");
      return; 
    }

    let mat = formData.material;
    if (mat !== undefined && mat !== null && mat.size > 5120000)
    {      
      toast.error("Study material zip file size should not be more than 5 MB.");
      return; 
    }

    dispatch(
      edit
        ? updateSubCourse(fd, edit, () => {
            dispatch(getCourseById(course_id));
            setShowRow(false);
          })
        : addSubCourse(fd, () => {
            dispatch(getCourseById(course_id));
            setShowRow(false);
            window.location.reload();
          })
    );
    setEdit();
    setShowAddCourseModal(false);
  };

  const handleRow = () => {
    setShowAddCourseModal(!showAddCourseModal);
    setEdit();
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const addNewCourse = () => {
    return (
      <tr className="border-b">
        <td className=" border-gray-200 px-4 py-3">
          <input
            name="name"
            type="text"
            defaultValue={edit ? formData.name : ""}
            onChange={handleChange}
            placeholder="Sub-Course"
            maxLength={30}
            className={input}
          />
        </td>
        <td className=" border-gray-200 px-4 py-3">
          <input
            name="price"
            type="number"
            defaultValue={edit ? formData.price : ""}
            onChange={handleChange}
            placeholder="Price >= 500"
            className={`${input} w-28`}
          />
        </td>
        <td className=" border-gray-200 px-4 py-3">
          <input
            name="teachername"
            className={`${input} w-28`}
            defaultValue={edit ? formData.teachername : ""}
            onChange={handleChange}
            maxLength={25}
            placeholder="Teacher Name"
          />
        </td>        
        <td className=" border-gray-200 px-2 py-3">
          <textarea
            name="description"
            className={`${input} w-28`}
            maxLength={400}
            defaultValue={edit ? formData.description : ""}
            onChange={handleChange}
            placeholder="Description"
          />
        </td>
        <td className="border-gray-200 px-4 py-3 text-lg text-gray-900 ">
          <input
            name="image"
            type="file"
            accept="image/*"
            onChange={handleChange}
            className={`${input} w-28`}
            //style={{height:'150px', width:'100%',margin:'0px'}}
          />
        </td>
        <td className="border-gray-200 px-4 py-3 text-lg text-gray-900">
          <input
            name="classbegindate"
            type="datetime-local"
            defaultValue={edit ? formData.classbegindate : ""}
            onChange={handleChange}
            placeholder="Class Start Date"
            className={`${input} w-40`}
          />
        </td>        
        { /*<td className="border-gray-200 px-4 py-3 text-lg text-gray-900">
          <input
            name="preview"
            type="file"
            accept="preview/*"
            onChange={handleChange}
            className={`${input} w-40`}
            style={{height:'150px', width:'100%',margin:'0px'}}
          />
        </td>*/}
        <td className=" border-gray-200 px-4 py-3 text-lg text-gray-900">
          <input
            name="material"
            type="file"
            accept=".zip"
            onChange={handleChange}
            className={`${input} w-28`}
          />
        </td>        
        <td className="px-4 py-3 border-gray-200">
          <Button event={handleSubmit} title="Save" />
        </td>
      </tr>
    );
  };

  // Sub Course List
  const showSubCourses = () => {
    return subCourses?.map((item, index) => {
      return (
        <>
          {item._id !== edit ? (
            <tr key={item._id} className="border-b text-sm">
              <td className="px-4 py-3">{item.name}</td>
              <td className="px-4 py-3">Rs. {item.price}/-</td>
              <td className="px-4 py-3">{item.teachername}</td>
              <td className="px-4 py-3">
                {item.description?.slice(0, 50)}
                {item.description.length > 50 && "..."}
              </td>
              <td className="px-4 py-3">
                <img
                  src={`${BASEURL}${item.image}`}
                  alt={item.name}
                  className="w-32 h-20"
                />
              </td>
              {/* <td className="px-4 py-3 ">
                <img
                  src={`${BASEURL}${item.preview}`}
                  alt={item.name}
                  className="w-32 h-20"
                />
              </td> */}
              {/* <td className="px-4 py-3">{item.classbegindate}</td> */}
              <td className="px-4 py-3">{item.classbegindate ? moment(new Date(item.classbegindate)).subtract(5.5, 'hours').format("DD/MMM/YYYY hh:mm A") : ""} IST.</td>
              <td className="px-4 py-3 ">
                  <p>Upload Materials</p> 
                  {/* <FiDownload
                    onClick={() => dispatch(downloadMaterials(item._id, __dirname, course_id))}
                    className="cursor-pointer text-red-500"
                  />           */}
              </td>              
              <td className="text-center py-12 text-color text-xl flex gap-3 px-4  ">
                <FiTrash2
                  onClick={() => dispatch(deleteSubCourse(item._id, course_id))}
                  className="cursor-pointer text-red-500"
                />
                <FiEdit
                  onClick={() => {
                    setEdit(item._id);
                    setFormData(item);
                    setShowRow(true);
                  }}
                  className="cursor-pointer"
                />
                <FiEye
                  onClick={() =>
                    navigate(`/instructor/subcourse?id=${item._id}`, {
                      state: item,
                    })
                  }
                  className="cursor-pointer text-black"
                />
              </td>
            </tr>
          ) : (
            addNewCourse()
          )}
        </>
      );
    });
  };

  useEffect(() => {
    dispatch(getCourseById(course_id));
    dispatch(getSubCourse(course_id));
  }, [course_id, dispatch]);

  return (
    <div className="container mx-auto px-4 break-words w-full">
      <TopBar title={course.name} path={"/instructor"} />
      <Content data={course} />
      {/* Heading */}
      <div className="border-b py-3 justify-between">
        <Heading title={"All Sub-Course"} />
      </div>

      {/* Table */}
      <div className="overflow-x-auto py-3">
        <table className="table-auto min-w-full text-left whitespace-no-wrap ">
          <thead>
            <tr>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Price
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Instructor
              </th>              
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Description
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Image
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Class Begin Date
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Material
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {showSubCourses()}
            {showAddCourseModal && !showRow && addNewCourse()}
          </tbody>
        </table>
      </div>

      {/* Nodata */}
      {subCourses?.length === 0 && <NoData />}

      {/* Add button */}
      <div className="flex py-3 pb-10 w-full justify-end">
        <Button
          title={showAddCourseModal ? "Cancel" : "Add New"}
          event={handleRow}
        />
      </div>
    </div>
  );
};

export default Course;