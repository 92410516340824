import React, { useState, useEffect } from "react";
import TopBar from "../../Components/TopBar";
import Button from "../../Components/Button";
import NoData from "../../Components/NoData";
import Content from "../../Components/Content";
import Heading from "../../Components/Heading";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import moment  from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  addSessions,
  deleteSession,
  getSessions,
  updateSessions,
  getMeetingSession
} from "../../Redux/Actions/SessionAction";
import GotoVSTMeet from "./GotoVSTMeet";

const BASEURL = process.env.REACT_APP_BASE_URL;

const SubCourse = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState();
  const subcourse = useLocation().state;
  const [showRow, setShowRow] = useState(false);
  const [showSession, setShowSession] = useState(false);

  const sessions = useSelector((state) => state.SessionReducer.sessions);
  const subcourse_id = new URLSearchParams(document.location.search).get("id");
  const instructorId = localStorage.getItem("instructorID|VST");

  const input =
    "bg-transparent border-indigo-400 tracking-widest text-xs rounded-sm shadow-md outline-none py-1.5 px-2 border";

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    // thumbnail: "",
    batchname:"",
    time: "",
    subcourse: subcourse_id,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files ? e.target.files[0] : e.target.value,
    });
  };

  const handleRow = () => {
    setShowSession(!showSession);
  };

  const handleSubmit = () => {
    const fd = new FormData();
    fd.append("name", formData.name);
    fd.append("description", formData.description);
    // fd.append("thumbnail", formData.thumbnail);
    fd.append("batchname", formData.batchname);
    fd.append("time", formData.time);
    fd.append("instructor", instructorId);
    fd.append("subcourse", formData.subcourse);
    if (formData.name === "" || 
        formData.description === "" ||
        formData.time === "")
    {
      return;
    }
    dispatch(
      edit
        ? updateSessions(fd, edit, () => {
          setShowRow(false);
        })
        : addSessions(fd, () => {
          setShowRow(false);
          window.location.reload();
        })
    );
    setEdit(0);
    setShowSession(false);
  };

  const addNewSession = () => {
    return (
      <tr className="border-b">
        <td className=" border-gray-200 px-4 py-3">
          <input
            name="name"
            type="text"
            defaultValue={edit ? formData.name : ""}
            onChange={handleChange}
            placeholder="Session"
            maxLength={20}
            className={input}
          />
        </td>

        <td className=" border-gray-200 px-4 py-3">
          <input
            name="time"
            type="datetime-local"
            defaultValue={edit ? formData.time : ""}
            onChange={handleChange}
            className={input}
          />
        </td>
        <td></td>
        <td className=" border-gray-200 px-4 py-3">
          <textarea
            name="description"
            className={input}
            defaultValue={edit ? formData.description : ""}
            onChange={handleChange}
            maxLength={400}
            placeholder="Description"
          />
        </td>
        {/* <td className=" border-gray-200 px-4 py-3 text-lg text-gray-900">
          <input
            name="thumbnail"
            type="file"
            accept="image/*"
            onChange={handleChange}
            className={`${input} w-40`}
            style={{ height: '150px', width: '100%', margin: '0px' }}
          />
        </td> */}
        <td className=" border-gray-200 px-4 py-3">
          <input
            name="batchname"
            className={input}
            defaultValue={edit ? formData.batchname : ""}
            onChange={handleChange}
            placeholder="Batchname"
          />
        </td>

        <td className="px-4 py-3 border-gray-200">
          <Button title="Save" event={handleSubmit} />
        </td>
      </tr>
    );
  };

  const showAllSession = () => {
    return sessions?.map((item, index) => {
      return (
        <>
          {item._id !== edit ? (
            <tr key={index} className="border-b text-sm">
              <td className="px-4 py-3">{item.name}</td>
              {/* <td className="px-4 py-3">{item.time}</td> */}
              <td className="px-4 py-3">{item.time ? moment(new Date(item.time)).subtract(5.5, 'hours').format("DD/MMM/YYYY hh:mm A") : ""} IST.</td>
              <td className="px-4 py-3 text-red-500 underline">
                <button
                  onClick={() => dispatch(getMeetingSession(item._id))}
                  className="cursor-pointer"
                >
                  Start the session.
                </button>
              </td>

              <td className="px-4 py-3">
                {item.description.slice(0, 30)}
                {item.description.length > 30 && "..."}
              </td>
              <td className="px-4 py-3 ">
                {item.batchname}
                {/* <figure className="w-32 h-20">
                  <img
                    src={`${BASEURL}${item.thumbnail}`}
                    alt={item.name}
                    className=" w-full h-full object-cover"
                  />
                </figure> */}
              </td>
              <td className="text-center text-color text-xl flex gap-3 px-4 py-10 ">
                <FiTrash2
                  onClick={() => dispatch(deleteSession(item._id))}
                  className="cursor-pointer text-red-500"
                />

                <FiEdit
                  onClick={() => {
                    setEdit(item._id);
                    setShowRow(true);
                    setFormData(item);
                  }}
                  className="cursor-pointer"
                />
              </td>
            </tr>
          ) : (
            addNewSession()
          )}
        </>
      );
    });
  };

  useEffect(() => {
    dispatch(getSessions(subcourse_id));
  }, []);

  return (
    <div className="container mx-auto px-4 break-words w-full">
      <TopBar path={-1} title={subcourse.name} />
      <Content data={subcourse} />
      {/* Heading */}
      <div className="border-b py-3 justify-between">
        <Heading title={"All Sessions"} />
      </div>

      {/* Table */}
      <div className="overflow-x-auto py-5">
        <table className="table-auto min-w-full text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Time
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Link
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Description
              </th>
              {/* <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Thumbnail
              </th> */}
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Batch Name
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {showAllSession()} {showSession && !showRow && addNewSession()}
          </tbody>
        </table>
      </div>

      {/* No Data */}
      {sessions?.length === 0 && showRow === false && <NoData />}

      {/* Add button */}
      <div className="flex py-3 pb-10 w-full justify-end">
        <Button event={handleRow} title={showSession ? "Cancel" : "Add New"} />
      </div>
    </div>
  );
};

export default SubCourse;
