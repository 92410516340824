import About from "../Pages/About";
import Cart from "../Pages/Cart";
import CourseById from "../Pages/CourseById";
import Courses from "../Pages/Courses";
import Enrolled from "../Pages/Enrolled";
import Forgot from "../Pages/Forgot";
import Home from "../Pages/Home";
import Course from "../Pages/Instructor/Course";
import Dashboard from "../Pages/Instructor/Dashboard";
import Meeting from "../Pages/Instructor/Meeting";
import Profile from "../Pages/Instructor/Profile";
import ForgotInsPass from "../Pages/Instructor/ForgotInsPass";
import SubCourse from "../Pages/Instructor/SubCourse";
import Login from "../Pages/Login";
import PageNotFound from "../Pages/PageNotFound";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import CookiePolicy from "../Pages/CookiePolicy";
import ContactUs from "../Pages/ContactUs";
import RefundPolicy from "../Pages/RefundPolicy";
import Resetpass from "../Pages/ResetPass";
import Signup from "../Pages/Signup";
import Terms from "../Pages/Terms";
import UserProfile from "../Pages/UserProfile";
import Wishlist from "../Pages/Wishlist";
import Payment from "../Pages/Payment";
import Partners from "../Pages/Partners";
import Investors from "../Pages/Investors";
import Transaction from "../Pages/transaction";
import Failed from "../Pages/Failed";
import Order from "..//Pages/Order";
import OrderById from "../Pages/OrderById";
import EnrolledInstructor from "../Pages/Instructor/EnrolledInstructor";
import EnrolledDetail from "../Pages/Instructor/EnrolledDetail";
import InstructorLogin from "../Pages/Instructor/InstructorLogin";
import FAQ from "../Pages/FAQ";
import Career from "../Pages/Career";
const AllRoutes = [
  // User
  {
    name: "Home",
    path: "/",
    component: <Home />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Signup",
    path: "/register",
    component: <Signup />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Login",
    path: "/login",
    component: <Login />,
    private: false,
    isInstructor: false,
  },

  {
    name: "Forgot",
    path: "/forgot",
    component: <Forgot />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Resetpass",
    path: "/reset-password",
    component: <Resetpass />,
    private: false,
    isInstructor: false,
  },

  {
    name: "Courses",
    path: "/courses",
    component: <Courses />,
    private: false,
    isInstructor: false,
  },

  {
    name: "Courses",
    path: "/:courseType/courses",
    component: <Courses />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Course",
    path: "/course/:id",
    component: <CourseById />,
    private: false,
    isInstructor: false,
  },
  {
    name: "User Profile",
    path: "/me",
    component: <UserProfile />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Wishlist",
    path: "/me/wishlist",
    component: <Wishlist />,
    private: false,
    isInstructor: false,
  },

  {
    name: "Order",
    path: "/me/order",
    component: <Order />,
    private: true,
    isInstructor: false,
  },

  {
    name: "Order",
    path: "/me/order/:id",
    component: <OrderById />,
    private: true,
    isInstructor: false,
  },


  {
    name: "Enrolled",
    path: "/me/enrolled-course",
    component: <Enrolled />,
    private: true,
    isInstructor: false,
  },
  {
    name: "Cart",
    path: "/cart",
    component: <Cart />,
    private: true,
    isInstructor: false,
  },
  {
    name: "Payment",
    path: "/payments/:transactionId",
    component: <Transaction />,
    private: true,
    isInstructor: false,
  },
  {
    name: "failed",
    path: "/failed",
    component: <Failed />,
    private: true,
    isInstructor: false,
  },


  // Instructor
  {
    name: "Instructor Login",
    path: "/InstructorLogin",
    component: <InstructorLogin />,
    private: false,
    isInstructor: true,
  },
  {
    name: "ForgotInsPass",
    path: "/forgotInsPass",
    component: <ForgotInsPass />,
    private: false,
    isInstructor: true,
  },
  {
    name: "Intructor Dashboard",
    path: "/instructor",
    component: <Dashboard />,
    private: true,
    isInstructor: true,
  },
  {
    name: "Intructor Dashboard",
    path: "/instructor",
    component: <Dashboard />,
    private: true,
    isInstructor: true,
  },

  {
    name: "Intructor Profile",
    path: "/instructor/me",
    component: <Profile />,
    private: true,
    isInstructor: true,
  },

  {
    name: "Enrolled",
    path: "/instructor/me/enrolled-course",
    component: <EnrolledInstructor />,
    private: true,
    isInstructor: true,
  },
  {
    name: "Intructor Session Preview",
    path: "/instructor/enrolled-course/:id",
    component: <EnrolledDetail />,
    private: true,
    isInstructor: true,
  },

  {
    name: "Payment Profile",
    path: "/payment",
    component: <Payment />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Intructor Course Preview",
    path: "/instructor/course",
    component: <Course />,
    private: true,
    isInstructor: true,
  },
  {
    name: "Intructor Sub-Course Preview",
    path: "/instructor/subcourse",
    component: <SubCourse />,
    private: true,
    isInstructor: true,
  },
  {
    name: "Intructor Meeting",
    path: "/instructor/meeting/:id",
    component: <Meeting />,
    private: true,
    isInstructor: true,
  },
  {
    name: "Page Not Found",
    path: "*",
    component: <PageNotFound />,
    private: false,
    isInstructor: false,
  },

  // Others
  {
    name: "About Us",
    path: "/about-us",
    component: <About />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Cookie Policy",
    path: "/cookie-policy",
    component: <CookiePolicy />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Contact Us",
    path: "/contact-us",
    component: <ContactUs />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Refund Policy",
    path: "/refund-policy",
    component: <RefundPolicy />,
    private: false,
    isInstructor: false,
  },
  {
    name: "FAQ",
    path: "/faq",
    component: <FAQ />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Career",
    path: "/career",
    component: <Career />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Terms & Conditions",
    path: "/terms",
    component: <Terms />,
    private: false,
    isInstructor: false,
  },
  
  {
    name: "Terms & Conditions",
    path: "/partners",
    component: <Partners />,
    private: false,
    isInstructor: false,
  },
  {
    name: "Terms & Conditions",
    path: "/investors",
    component: <Investors />,
    private: false,
    isInstructor: false,
  },
  
];

export default AllRoutes;
