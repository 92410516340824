import React from "react";

const NoData = () => {
  return (
    <div className="text-center w-full py-5 text-sm ">
      There are no data to display
    </div>
  );
};

export default NoData;
